import React, { useMemo } from "react";
import {
    BrowserRouter,
    Navigate,
    Routes as Switch,
    Route,
} from "react-router-dom";

//Components
import ErrorBoundaryPage from "./components/ErrorBoundaryPage";
import ErrorBoundary from "./components/ErrorBoundary";

//Pages
import Home from "../pages/home/Home";
import Compliance from "pages/compliance/Compliance";

const Routes = () => {
    const ErrorBoundaryNode: React.ReactNode = useMemo(()=>(ErrorBoundaryPage({})), [])
    return(
        <BrowserRouter>
            <ErrorBoundary fallback={ErrorBoundaryNode}>
                <Switch>
                    <Route path="/">
                        <Route path="/" element={<Home/>} />
                        <Route path="/compliance" element={<Compliance/>} />
                    </Route>
                </Switch>
            </ErrorBoundary>
        </BrowserRouter>
    )
}

export default Routes