//Types
import { Breakpoints, FontSizesByBreakpoints } from "./types/theme.slice";
//Utils
//输入字体大小，输出满足mui Typography 要求的字体定义
export const genCustomizedTypographyVariants = (fontSizes: FontSizesByBreakpoints, breakpoints: Breakpoints) => {
    const result:any = {};
    for(let idx = 0; idx < fontSizes.lg.length; ++idx){
        const fontSizeProps = {
            fontSize: fontSizes.xl[idx],
            [`@media (max-width:${breakpoints.xl}px)`]: {
                fontSize: fontSizes.lg[idx],
            },
            [`@media (max-width:${breakpoints.lg}px)`]: {
                fontSize: fontSizes.md[idx],
            },
            [`@media (max-width:${breakpoints.md}px)`]: {
                fontSize: fontSizes.sm[idx],
            },
            [`@media (max-width:${breakpoints.sm}px)`]: {
                fontSize: fontSizes.xs[idx],
            },
        }
        result[`f${idx+1}`] = {
            ...fontSizeProps
        }
        result[`f${idx+1}b`] = {
            ...fontSizeProps,
            fontWeight: "bold"
        }
    }

    return result
}