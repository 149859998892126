import { Dayjs } from "dayjs"
import { newColumnDefinition } from "./utils"
import { ColumnDefinition } from "types"

export const ClientOriginalDataID  = newColumnDefinition("ClientOriginalDataID", "Client Original Data ID", "string")
export const PlotID  = newColumnDefinition("PlotID", "Plot_ID", "string")
export const DateTime  = newColumnDefinition("DateTime", "DateTime", "datetime")
export const Type  = newColumnDefinition("Type", "Type", "string")
export const ManualWLmbTOC  = newColumnDefinition("ManualWLmbTOC", "Manual_WL_mbTOC", "number")
export const LoggerWLmbTOC  = newColumnDefinition("LoggerWLmbTOC", "Logger_WL_mbTOC", "number")
export const LoggerWLmAHD  = newColumnDefinition("LoggerWLmAHD", "Logger_WL_mAHD", "number")
export const TotalDissolvedSolids_field  = newColumnDefinition("TotalDissolvedSolids_field", "Total Dissolved Solids (field)", "number")
export const DissolvedOxygen_field  = newColumnDefinition("DissolvedOxygen_field", "Dissolved Oxygen (field)", "number")
export const ElectricalConductivity_field  = newColumnDefinition("ElectricalConductivity_field", "Electrical Conductivity (field)", "number")
export const pH_field  = newColumnDefinition("pH_field", "pH (field)", "number")
export const Redox_field  = newColumnDefinition("Redox_field", "Redox (field)", "number")
export const Temperature_field  = newColumnDefinition("Temperature_field", "Temperature (field)", "number")
export const Calcium_Dissolved  = newColumnDefinition("Calcium_Dissolved", "Calcium - Dissolved", "number")
export const Potassium_Dissolved  = newColumnDefinition("Potassium_Dissolved", "Potassium - Dissolved", "number")
export const Sodium_Dissolved  = newColumnDefinition("Sodium_Dissolved", "Sodium - Dissolved", "number")
export const Magnesium_Dissolved  = newColumnDefinition("Magnesium_Dissolved", "Magnesium - Dissolved", "number")
export const Hardness  = newColumnDefinition("Hardness", "Hardness", "number")
export const HydroxideAlkalinityasCaCO3  = newColumnDefinition("HydroxideAlkalinityasCaCO3", "Hydroxide Alkalinity (OH-) as CaCO3", "number")
export const BicarbonateAlkalinityasCaCO3  = newColumnDefinition("BicarbonateAlkalinityasCaCO3", "Bicarbonate Alkalinity as CaCO3", "number")
export const CarbonateAlkalinityasCaCO3  = newColumnDefinition("CarbonateAlkalinityasCaCO3", "Carbonate Alkalinity as CaCO3", "number")
export const TotalAlkalinityasCaCO3  = newColumnDefinition("TotalAlkalinityasCaCO3", "Total Alkalinity as CaCO3", "number")
export const Sulphate_SO4  = newColumnDefinition("Sulphate_SO4", "Sulphate, SO4", "number")
export const Chloride_Cl  = newColumnDefinition("Chloride_Cl", "Chloride, Cl", "number")
export const IonicBalance  = newColumnDefinition("IonicBalance", "Ionic Balance", "number")
export const TotalNitrogeninwater  = newColumnDefinition("TotalNitrogeninwater", "Total Nitrogen in water", "number")
export const Fluoride_F  = newColumnDefinition("Fluoride_F", "Fluoride, F", "number")
export const DissolvedOrganicCarbon  = newColumnDefinition("DissolvedOrganicCarbon", "Dissolved Organic Carbon", "number")
export const Phosphorus_Total  = newColumnDefinition("Phosphorus_Total", "Phosphorus - Total", "number")
export const AluminiumTotal  = newColumnDefinition("AluminiumTotal", "Aluminium-Total", "number")
export const ArsenicTotal  = newColumnDefinition("ArsenicTotal", "Arsenic-Total", "number")
export const BariumTotal  = newColumnDefinition("BariumTotal", "Barium-Total", "number")
export const CobaltTotal  = newColumnDefinition("CobaltTotal", "Cobalt-Total", "number")
export const CopperTotal  = newColumnDefinition("CopperTotal", "Copper-Total", "number")
export const LithiumTotal  = newColumnDefinition("LithiumTotal", "Lithium-Total", "number")
export const ManganeseTotal  = newColumnDefinition("ManganeseTotal", "Manganese-Total", "number")
export const NickelTotal  = newColumnDefinition("NickelTotal", "Nickel-Total", "number")
export const LeadTotal  = newColumnDefinition("LeadTotal", "Lead-Total", "number")
export const SeleniumTotal  = newColumnDefinition("SeleniumTotal", "Selenium-Total", "number")
export const StrontiumTotal  = newColumnDefinition("StrontiumTotal", "Strontium-Total", "number")
export const ZincTotal  = newColumnDefinition("ZincTotal", "Zinc-Total", "number")
export const IronTotal  = newColumnDefinition("IronTotal", "Iron-Total", "number")
export const AluminiumDissolved  = newColumnDefinition("AluminiumDissolved", "Aluminium-Dissolved", "number")
export const ArsenicDissolved  = newColumnDefinition("ArsenicDissolved", "Arsenic-Dissolved", "number")
export const BariumDissolved  = newColumnDefinition("BariumDissolved", "Barium-Dissolved", "number")
export const CobaltDissolved  = newColumnDefinition("CobaltDissolved", "Cobalt-Dissolved", "number")
export const CopperDissolved  = newColumnDefinition("CopperDissolved", "Copper-Dissolved", "number")
export const LithiumDissolved  = newColumnDefinition("LithiumDissolved", "Lithium-Dissolved", "number")
export const ManganeseDissolved  = newColumnDefinition("ManganeseDissolved", "Manganese-Dissolved", "number")
export const NickelDissolved  = newColumnDefinition("NickelDissolved", "Nickel-Dissolved", "number")
export const LeadDissolved  = newColumnDefinition("LeadDissolved", "Lead-Dissolved", "number")
export const SeleniumDissolved  = newColumnDefinition("SeleniumDissolved", "Selenium-Dissolved", "number")
export const StrontiumDissolved  = newColumnDefinition("StrontiumDissolved", "Strontium-Dissolved", "number")
export const ZincDissolved  = newColumnDefinition("ZincDissolved", "Zinc-Dissolved", "number")
export const IronDissolved  = newColumnDefinition("IronDissolved", "Iron-Dissolved", "number")

export type RowType = {
    ClientOriginalDataID: string;
    PlotID: string;
    DateTime: number;
    Type: string;
    ManualWLmbTOC: number;
    LoggerWLmbTOC: number;
    LoggerWLmAHD: number;
    TotalDissolvedSolids_field: number;
    DissolvedOxygen_field: number;
    ElectricalConductivity_field: number;
    pH_field: number;
    Redox_field: number;
    Temperature_field: number;
    Calcium_Dissolved: number;
    Potassium_Dissolved: number;
    Sodium_Dissolved: number;
    Magnesium_Dissolved: number;
    Hardness: number;
    HydroxideAlkalinityasCaCO3: number;
    BicarbonateAlkalinityasCaCO3: number;
    CarbonateAlkalinityasCaCO3: number;
    TotalAlkalinityasCaCO3: number;
    Sulphate_SO4: number;
    Chloride_Cl: number;
    IonicBalance: number;
    TotalNitrogeninwater: number;
    Fluoride_F: number;
    DissolvedOrganicCarbon: number;
    Phosphorus_Total: number;
    AluminiumTotal: number;
    ArsenicTotal: number;
    BariumTotal: number;
    CobaltTotal: number;
    CopperTotal: number;
    LithiumTotal: number;
    ManganeseTotal: number;
    NickelTotal: number;
    LeadTotal: number;
    SeleniumTotal: number;
    StrontiumTotal: number;
    ZincTotal: number;
    IronTotal: number;
    AluminiumDissolved: number;
    ArsenicDissolved: number;
    BariumDissolved: number;
    CobaltDissolved: number;
    CopperDissolved: number;
    LithiumDissolved: number;
    ManganeseDissolved: number;
    NickelDissolved: number;
    LeadDissolved: number;
    SeleniumDissolved: number;
    StrontiumDissolved: number;
    ZincDissolved: number;
    IronDissolved: number;
}

export const columns: Array<ColumnDefinition> = [ClientOriginalDataID, PlotID, DateTime, Type, ManualWLmbTOC, LoggerWLmbTOC, LoggerWLmAHD, TotalDissolvedSolids_field, DissolvedOxygen_field, ElectricalConductivity_field, pH_field, Redox_field, Temperature_field, Calcium_Dissolved, Potassium_Dissolved, Sodium_Dissolved, Magnesium_Dissolved, Hardness, HydroxideAlkalinityasCaCO3, BicarbonateAlkalinityasCaCO3, CarbonateAlkalinityasCaCO3, TotalAlkalinityasCaCO3, Sulphate_SO4, Chloride_Cl, IonicBalance, TotalNitrogeninwater, Fluoride_F, DissolvedOrganicCarbon, Phosphorus_Total, AluminiumTotal, ArsenicTotal, BariumTotal, CobaltTotal, CopperTotal, LithiumTotal, ManganeseTotal, NickelTotal, LeadTotal, SeleniumTotal, StrontiumTotal, ZincTotal, IronTotal, AluminiumDissolved, ArsenicDissolved, BariumDissolved, CobaltDissolved, CopperDissolved, LithiumDissolved, ManganeseDissolved, NickelDissolved, LeadDissolved, SeleniumDissolved, StrontiumDissolved, ZincDissolved, IronDissolved]