import React from 'react';
import logo from './logo.svg';

import Routes from './routes/Routes';
import MultiThemeProvider from 'features/theme/MultiThemeProvider';
import { LocalizationProvider } from '@mui/x-date-pickers-pro/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';


function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MultiThemeProvider>
        <Routes/>
      </MultiThemeProvider>
    </LocalizationProvider>

  );
}

export default App;
