import { ExcelData, ExcelSheetData, ExcelDataRow, ColumnDefinition } from "types";
import { getSheetIndexBySheetID } from "./sheet";

export type ParseSheetToListReturnType<T = unknown> = {
    columns: ColumnDefinition[];
    rows: Array<ExcelDataRow<T>>;
}

export const defaultReturn: ParseSheetToListReturnType<any> = { columns: [], rows: [] };

export default function parseSheetToList(excelData: ExcelData, sheetId: string):ParseSheetToListReturnType {
    const sheetIndex = getSheetIndexBySheetID(excelData.sheetDefines, sheetId);
    if(sheetIndex === -1) return defaultReturn
    const sheet = excelData.sheets[sheetIndex];
    if(sheet === undefined) return defaultReturn
    const { columns, data } = sheet;
    const result: ExcelDataRow[] = [];

    data.forEach((row, rowIndex) => {
        const newRow: ExcelDataRow = {};
        for(let i = 0; i < row.length; i++) {
            const id = columns[i].id;
            const value = row[i];
            newRow[id] = value;
        }
        result.push(newRow);
    })
    return{
        columns,
        rows: result
    }

}