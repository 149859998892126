import { CSSProperties } from "react";
import { ThemeOptions } from "@mui/material/styles";
//Utils
import { genCustomizedTypographyVariants } from "../theme.utils"
//Types
import { FontSizesByBreakpoints } from "../types/theme.slice"
type CSSLengthShort = {
    xs: CSSProperties["paddingLeft"];
    sm: CSSProperties["paddingLeft"];
    md: CSSProperties["paddingLeft"];
    lg: CSSProperties["paddingLeft"];
    xl: CSSProperties["paddingLeft"];
};

interface CustomizedTheme {
    layout: {
        panel: {
            sidebar:{
                width: CSSProperties["width"];
            },
            header:{
                height: CSSProperties["height"];
            }
            content: {
                paddingHorizontal: CSSLengthShort;
            };
        };
    };
}

interface CustomizedTypographyVariants{
    f1  : React.CSSProperties;
    f1b : React.CSSProperties;
    f2  : React.CSSProperties;
    f2b : React.CSSProperties;
    f3  : React.CSSProperties;
    f3b : React.CSSProperties;
    f4  : React.CSSProperties;
    f4b : React.CSSProperties;
    f5  : React.CSSProperties;
    f5b : React.CSSProperties;
    f6  : React.CSSProperties;
    f6b : React.CSSProperties;
    f7  : React.CSSProperties;
    f7b : React.CSSProperties;
    f8  : React.CSSProperties;
    f8b : React.CSSProperties;
    f9  : React.CSSProperties;
    f9b : React.CSSProperties;
    f10 : React.CSSProperties;
    f10b: React.CSSProperties;
    f11 : React.CSSProperties;
    f11b: React.CSSProperties;
    f12 : React.CSSProperties;
    f12b: React.CSSProperties;
    f13 : React.CSSProperties;
    f13b: React.CSSProperties;
    f14 : React.CSSProperties;
    f14b: React.CSSProperties;
}

declare module "@mui/material/styles" {
    interface Theme extends CustomizedTheme {}
    interface ThemeOptions extends Partial<CustomizedTheme> {}
    interface TypographyVariants extends CustomizedTypographyVariants {}
    interface TypographyVariantsOptions extends Partial<CustomizedTypographyVariants> {}
}

declare module "@mui/material/Typography" {
    interface TypographyPropsVariantOverrides {
        f1  : true;
        f1b : true;
        f2  : true;
        f2b : true;
        f3  : true;
        f3b : true;
        f4  : true;
        f4b : true;
        f5  : true;
        f5b : true;
        f6  : true;
        f6b : true;
        f7  : true;
        f7b : true;
        f8  : true;
        f8b : true;
        f9  : true;
        f9b : true;
        f10 : true;
        f10b: true;
        f11 : true;
        f11b: true;
        f12 : true;
        f12b: true;
        f13 : true;
        f13b: true;
        f14 : true;
        f14b: true;
    }
}

const breakpoints = {
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1536,
}

const fontSizes: FontSizesByBreakpoints = {
    xl: [44, 30, 28, 26, 24, 22, 20, 18, 16, 15, 14, 13, 12, 11],
    lg: [30, 30, 28, 26, 24, 22, 20, 18, 16, 15, 14, 13, 12, 11],
    md: [28, 28, 26, 24, 22, 22, 20, 18, 16, 15, 14, 13, 12, 11],
    sm: [26, 24, 23, 22, 21, 20, 19, 18, 16, 15, 14, 13, 12, 11],
    xs: [20, 19, 19, 18, 18, 17, 17, 16, 16, 15, 14, 13, 12, 11],
};

const defaultCustomeFontSizes = genCustomizedTypographyVariants(fontSizes, breakpoints);
const base: ThemeOptions = {
    typography: {
        fontFamily: [
            "Roboto-regular",
            "Roboto-medium",
            "-apple-system",
            "BlinkMacSystemFont",
            '"Segoe UI"',
            "Roboto",
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(","),
        ...defaultCustomeFontSizes,
    },
    layout: {
        panel: {
            sidebar:{
                width: "200px"
            },
            header:{
                height: "55px"
            },
            content: {
                paddingHorizontal: {
                    xs: "24px",
                    sm: "24px",
                    md: "24px",
                    lg: "24px",
                    xl: "24px",
                },
            },
        },
    },
};

export default base;
