import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import React, { useState, useEffect, useCallback, useMemo} from "react"

import RefreshIcon from '@mui/icons-material/Refresh';
import HomeIcon from '@mui/icons-material/Home';
import IconButton from "@mui/material/IconButton";
import AssignmentIcon from '@mui/icons-material/Assignment';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import { DatePicker, DateRangePicker } from "@mui/x-date-pickers-pro";
import Paper from "@mui/material/Paper";
import {Map, Marker, APIProvider, MapCameraChangedEvent } from '@vis.gl/react-google-maps';
import Autocomplete from "@mui/material/Autocomplete";
import { useSelector } from "react-redux";
import { selectExcelData } from "features/excelData/selectors/selectExcelData.selector";
import parseSheetToList, { ParseSheetToListReturnType, defaultReturn } from "features/excelData/utils/parseSheetToList";
import { AllData, BoreConstruction } from "assets/sheetName";
import proj4 from "proj4";
import { RowType as BoreConstructionRowType} from "assets/columns/BoreConstruction";
import { RowType as AllDataRowType } from "assets/columns/AllData";

import { DataGridPro, GridRowsProp, GridColDef, GridToolbar } from '@mui/x-data-grid-pro';
import { createId } from "utils/id";
import { excelDataToString, excelDateToJSDate } from "utils/excel"
//Chartjs
import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController,
    ChartOptions,
    ChartData,
    TimeScale
  } from 'chart.js';
import { Chart } from 'react-chartjs-2';
import { enUS } from 'date-fns/locale'; // Optionally import a locale
import 'chartjs-adapter-date-fns';

ChartJS.register(
    LineController,
    LinearScale,
    PointElement,
    LineElement,
    Tooltip,
    Legend,
    CategoryScale,
    TimeScale
  );

//Utils


// const sourceProj = proj4.Proj('EPSG:28355');
// const destProj = proj4.Proj('EPSG:4326');

const GDA94_Zone55 = '+proj=utm +zone=55 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs';
const WGS84 = '+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs';

proj4.defs('GDA94_Zone55', GDA94_Zone55);
proj4.defs('WGS84', WGS84);


const getCenterOfPoints = (points:Array<{lat: number, lng: number}>) => {
    const total = points.reduce((acc, point) => {
        return {
            lat: acc.lat + point.lat,
            lng: acc.lng + point.lng
        };
    }, { lat: 0, lng: 0 });

    return {
        lat: total.lat / points.length,
        lng: total.lng / points.length
    };
};

const chartOptions:ChartOptions<"line"> = {
    scales: {
        x: {
          type: 'time',
          time: {
            unit: 'day', // Setting the unit to 'hour' for finer granularity
            tooltipFormat: 'yyyy-MM-dd HH:mm', // Adjust tooltip format to show hours and minutes
            displayFormats: {
              day: 'yyyy-MM-dd' // Format hours to show the day and hour
            }
          },
          ticks: {
            source: 'auto',
            autoSkip: true,
            maxTicksLimit: 20 // Adjust based on your preference
          }
        },
        y: {
        //   beginAtZero: true
        }
    },
    responsive: true,
    plugins: {
        legend: {
            position: 'bottom' as any,
        },
    },
    maintainAspectRatio: false,

  };



const Home = () => {
    const data = useSelector(selectExcelData);
    // const mapRef = useRef();
    // const [map, setMap] = useState<any>(null)
    const [isShowMapLabel, setIsShowMapLabel] = useState<boolean>(false)
    const [selectedBoreId, setSelectedBoreId] = useState<string | null>(null)
    const [selectedBoreParameter, setSelectedBoreParameter] = useState<BoreConstructionRowType | null>(null)
    const [defaultCenter, setDefaultCenter] = useState<{lat: number, lng: number} | null>(null)
    const [bores, setBores] = useState<ParseSheetToListReturnType<BoreConstructionRowType & {lat: number, lng: number}>>(defaultReturn);
    const [allData, setAllData] = useState<ParseSheetToListReturnType<AllDataRowType>>(defaultReturn);
    const boreParameterChartData = useMemo<ChartData<"line", { x: string; y: number; }[], unknown>>(()=>{
        // console.log("selectedBoreParameter", selectedBoreParameter)
        const boreToCmAHD = typeof(selectedBoreParameter?.ToCmAHD) === "number" ? selectedBoreParameter.ToCmAHD : null;
        let mannualDipmAHDData:Array<{x: string; y: number}> = [];
        if(boreToCmAHD){
            // console.log("selectedBoreId", selectedBoreId)
            // console.log("mannualDipmAHDData", allData.rows.filter((row)=>row.PlotID === selectedBoreId))
            mannualDipmAHDData = allData.rows.filter((row)=>row.PlotID === selectedBoreId).map((row)=>({
                x: excelDataToString(row.DateTime),
                y: typeof(row?.ManualWLmbTOC) === "number"?boreToCmAHD - row?.ManualWLmbTOC:NaN
            }))
        }
        

        return{
            datasets: [
                {
                  label: 'Logger Data',
                  data: allData.rows.filter((row)=>row.PlotID === selectedBoreId).map((row)=>({
                        x: excelDataToString(row.DateTime),
                        y: row?.LoggerWLmAHD
                  })),
                  borderColor: 'rgb(75, 192, 192)',
                  backgroundColor: 'rgba(75, 192, 192, 0.5)',
                  type: 'line',
                  fill: true,
                //   showLine: false,
                  pointRadius: 0,
                },
                {
                  label: 'Mannual Dip',
                  data: mannualDipmAHDData,
                  borderColor: 'rgb(255, 99, 132)',
                  backgroundColor: 'rgba(255, 99, 132, 0.5)',
                  showLine: false, // no line is drawn for this dataset
                  pointRadius: 5,
                }
              ],
        }
    }, [allData, selectedBoreId, selectedBoreParameter])

    useEffect(()=>{
        if(!selectedBoreId){
            setSelectedBoreParameter(null)
            return;
        }
        const result = bores.rows.find((row)=>row.ID === selectedBoreId)
        if(!result){
            setSelectedBoreParameter(null)
            return;
        }
        setSelectedBoreParameter(result)
    }, [selectedBoreId, bores])

    useEffect(() => {
        if(data?.data) {
            //parse bore construction data
            const paredList = parseSheetToList(data.data, BoreConstruction.id) as ParseSheetToListReturnType<BoreConstructionRowType>;
            const rows = paredList.rows.filter((row)=>row.Easting && row.Northing).map((row)=>{
                let [longitude, latitude] = proj4('GDA94_Zone55', 'WGS84', [row.Easting, row.Northing]);
                return({
                    ...row,
                    lat: latitude,
                    lng: longitude
                })
            })
            setBores({
                ...paredList,
                rows
            })
            //parse all data
            const allDataList = parseSheetToList(data.data, AllData.id) as ParseSheetToListReturnType<AllDataRowType>;
            const allDataRows = allDataList.rows.map((row)=>({
                ...row,
                id: createId(row.PlotID)
            }))
            setAllData({
                ...allDataList,
                rows: allDataRows.sort((a, b)=> a.DateTime - b.DateTime),
            })

        }
    }, [data?.data, setBores])

    useEffect(()=>{
        if(bores.rows.length > 0) {
            setDefaultCenter(getCenterOfPoints(bores.rows))
        }
    }, [bores])



    const allDataColumns = useMemo<Array<GridColDef<AllDataRowType>>>(()=>{
        return [
            { field: 'PlotID', headerName: 'Bore ID'},
            { field: 'DateTime', headerName: 'Date Time', type: 'dateTime', width: 180,
                valueGetter: (value) => value && excelDateToJSDate(value),
            },
            { field: 'ManualWLmbTOC', headerName: 'ManualWLmbTOC', type: 'number'},
            { field: 'LoggerWLmbTOC', headerName: 'LoggerWLmbTOC', type: 'number'},
            { field: 'LoggerWLmAHD', headerName: 'LoggerWLmAHD', type: 'number'},
            { field: 'ElectricalConductivity_field', headerName: 'ElectricalConductivity_field', type: 'number'},
            { field: 'pH_field', headerName: 'pH_field', type: 'number'},
            { field: 'Sulphate_SO4', headerName: 'Sulphate_SO4', type: 'number'},
        ]
    }, [])


    const handleZoomChanged = useCallback<(event: MapCameraChangedEvent) => void>((event)=>{
        setIsShowMapLabel(event.detail.zoom > 12)
    }, [])

    // const handleMapLoad = useCallback((map: any)=>{
    //     setMap(map)
    // }, [])

    return(
        <Box 
            sx={{
                width: "100%",
                height: "100vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "stretch",
                backgroundColor: "rgb(248, 248, 248)",
                padding: "12px",
                gap: "12px",
                overflow: "hidden"
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <Box>
                    <Typography variant="f3b">Report</Typography>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        gap: "12px"
                    }} 
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            alignItems: "stretch",
                            gap: "8px"
                        }}
                    >
                        <Typography variant="f9b">Date Range</Typography>
                        <DateRangePicker
                            localeText={{ start: 'Start Date', end: 'End Date' }}
                            slotProps={{ textField: { size: 'small' } }}
                        />
                    </Box>
                    <IconButton>
                        <RefreshIcon fontSize="large"/>
                    </IconButton>
                    <IconButton>
                        <HomeIcon fontSize="large"/>
                    </IconButton>
                </Box>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "stretch",
                    flex: 1,
                    gap: "24px",
                    overflow: "hidden"
                }}
            >
                <Box sx={{display: "flex", flex: "1 1 50%", flexDirection: "column", alignItems: "stretch", gap: "12px", minWidth: 0}}>
                    <Paper sx={{flex: "1 1 50%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "stretch", overflow: "hidden"}}>
                        <Box
                            sx={{
                                backgroundColor: "primary.main",
                                height: "30px",
                                display: "flex",
                                position: "relative",
                                justifyContent: "center",
                                alignItems: "center"
                            }}
                        >
                            <Typography variant="f8b" color="white">Bore Location</Typography>
                            <Box sx={{position: "absolute", left: "8px", top: "50%", transform: "translateY(-50%)"}}>
                                <LocationOnIcon sx={{color: "white"}}/>
                            </Box>
                        </Box>
                        <Box sx={{flex: 1}}>
                            <APIProvider apiKey = {process.env.REACT_APP_GOOGLE_MAP_API_KEY as string}>
                                {defaultCenter && 
                                <Map
                                    // onLoad={handleMapLoad}
                                    style={{width: '100%', height: '100%'}}
                                    defaultCenter={defaultCenter}
                                    onZoomChanged={handleZoomChanged}
                                    defaultZoom={12}
                                    gestureHandling={'greedy'}
                                    // disableDefaultUI={true}
                                    mapTypeId="hybrid"
                                    
                                    // center={center}
                                    // onCenterChanged={handleCenterChanged}
                                >
                                    {bores.rows.filter((row)=>row.Easting && row.Northing).map((row, index) => {
                                        let [longitude, latitude] = proj4('GDA94_Zone55', 'WGS84', [row.Easting, row.Northing]);
                                        return(
                                                <Marker
                                                    key={index}
                                                    position={{lat: latitude, lng: longitude}}
                                                    clickable={true}
                                                    onClick={() => setSelectedBoreId(row.ID)}
                                                    title={'clickable google.maps.Marker'}
                                                    label={{
                                                        text: row.ID,
                                                        className: `map-marker-label${isShowMapLabel ? "":" map-marker-label-hide"}`,                                 
                                                    }}
                                                />
                                
                                        )
                                    })}
                                </Map>}
                                
                            </APIProvider>
                        </Box>
                    </Paper>
                    <Paper sx={{flex: "1 1 50%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "stretch", minHeight: 0, overflow: "hidden"}}>
                    {/* <Paper sx={{flex: "1 1 50%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "stretch", minHeight: 0}}> */}
                        <Box
                            sx={{
                                backgroundColor: "primary.main",
                                height: "30px",
                                display: "flex",
                                position: "relative",
                                justifyContent: "center",
                                alignItems: "center"
                            }}
                        >
                            <Typography variant="f8b" color="white">Groundwater Monitoring Results</Typography>
                            <Box sx={{position: "absolute", left: "8px", top: "50%", transform: "translateY(-50%)"}}>
                                <AssignmentIcon sx={{color: "white"}}/>
                            </Box>
                        </Box>
                        <Box sx={{width: '100%', minWidth: "50px", flex: 1, minHeight: 0, maxHeight: "100%", overflow: "hidden"}}>
                            <DataGridPro 
                                rows={allData.rows} 
                                columns={allDataColumns} 
                                slots={{ toolbar: GridToolbar }}
                                initialState= {{ density: 'compact'}}
                            />
                        </Box>
                    </Paper>
                </Box>
                
                <Box sx={{display: "flex", flex: "1 1 50%", flexDirection: "column", alignItems: "stretch", gap: "12px", minWidth: 0}}>
                    <Paper sx={{flex: "1 1 50%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "stretch", minWidth: 0, minHeight: 0, overflow: "hidden"}}>
                        <Box
                            sx={{
                                backgroundColor: "primary.main",
                                height: "30px",
                                display: "flex",
                                position: "relative",
                                justifyContent: "center",
                                alignItems: "center"
                            }}
                        >
                            <Typography variant="f8b" color="white">Bore ID - Parameter</Typography>
                            <Box sx={{position: "absolute", left: "8px", top: "50%", transform: "translateY(-50%)"}}>
                                <AutoGraphIcon sx={{color: "white"}}/>
                            </Box>
                        </Box>
                        <Box sx={{width: "100%", flex: "1 0", padding: "12px", minHeight: 0, minWidth: 0}}>
                            <Chart type='line' options={chartOptions} data={boreParameterChartData} />
                        </Box>
                    </Paper>
                    <Paper sx={{flex: "1 1 50%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "stretch", overflow: "hidden"}}>
                        <Box
                            sx={{
                                backgroundColor: "primary.main",
                                height: "30px",
                                display: "flex",
                                position: "relative",
                                justifyContent: "center",
                                alignItems: "center"
                            }}
                        >
                            <Typography variant="f8b" color="white">TO BE CONTINUED</Typography>
                            <Box sx={{position: "absolute", left: "8px", top: "50%", transform: "translateY(-50%)"}}>
                                <AssignmentIcon sx={{color: "white"}}/>
                            </Box>
                        </Box>
                    </Paper>
                </Box>
            </Box>
        </Box>
    )
}

export default Home

{/* <OverlayView
                                                        position={{ lat: -34.397, lng: 150.644 }}
                                                        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                                                        getPixelPositionOffset={() => ({ x: 0, y: 0 })}
                                                    >
                                                        <CustomLabel text="Right Top Corner Label" />
                                                    </OverlayView> */}