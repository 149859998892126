import XLSX,{ read } from "xlsx";
import { sheetNames as sheetNamesDefined } from "assets/sheetName";
import { ExcelSheetData, ExcelData, DataDefinition, CellDataType } from "types"
import { sheetColumnsMap } from "assets/sheetName";
import dayjs, { Dayjs } from "dayjs";


export const parseExcelCell = (raw_data: any, type: DataDefinition = "string", position?: {row: number, column: number, sheetname: string}): CellDataType => {
    const printError = (message: string) => {
        if(position){
            console.log("Parse Cell Error at position", position, " ", message)
        } else {
            console.log("Parse Cell Error at unknown position", message)
        }
    }
    let result: CellDataType = null;
    if(type === "string"){
        if(typeof raw_data === "string"){
            result = raw_data;
        } else if(typeof raw_data === "number"){
            printError("Expecting string but got number")
            result = raw_data.toString();
        }
    } else if(type === "number"){
        if(typeof raw_data === "number"){
            result = raw_data;
        } else if(typeof raw_data === "string"){
            if(raw_data.length > 0 && raw_data[0] === "<"){
                const parsed = parseFloat(raw_data.substring(1));
                if(isNaN(parsed)){
                    printError("Expecting number but got string")
                } else {
                    result = parsed / 2;
                }
            } else {
                const parsed = parseFloat(raw_data);
                if(isNaN(parsed)){
                    printError("Expecting number but got string")
                } else {
                    result = parsed;
                }
            }
        }
    } 
    // else if(type === "datetime"){
    //     try{
    //         result = dayjs(raw_data).format('YYYY-MM-DD HH:mm:ss')
    //     }
    //     catch(e){
    //         result = null;
    //     }
        
    // }
     else {
        result = raw_data;
    }
    return result;
}

export const readExcelCellByPosition = (sheet: XLSX.WorkSheet, c: number, r: number, type: DataDefinition, sheetName: string) => {
    var cellref = XLSX.utils.encode_cell({c:c, r:r}); // construct A1 reference for cell
    if(!sheet[cellref]) return null; // if cell doesn't exist, move on
    var cell = sheet[cellref];
    return parseExcelCell(cell.v, type, {row: r, column: c, sheetname: sheetName});
}


export const parseExcel = async(file: File) => {
    const data = await file.arrayBuffer();
    var workbook = read(data);
    const sheetNames = workbook.SheetNames;
    const result: ExcelData = {
        sheetDefines: [],
        sheets: []
    }
    for (let sheetIndex = 0; sheetIndex < sheetNamesDefined.length; sheetIndex++) {
        const sheetDefinition = sheetNamesDefined[sheetIndex];
        const sheetName = sheetDefinition.name;
        const sheetId = sheetDefinition.id;
        const fileSheetIndex = sheetNames.indexOf(sheetName);
        if(fileSheetIndex > -1){
            const sheet = workbook.Sheets[sheetName];
            const columnDefinitions = sheetColumnsMap[sheetId];
            if(columnDefinitions){
                const excelSheetData: ExcelSheetData = {
                    columns: columnDefinitions,
                    data: []
                }
                var range = XLSX.utils.decode_range(sheet['!ref'] as string);
                if(range.e.r > 0){
                    const columns: Array<any> = [];
                    for(var C = range.s.c; C <= range.e.c; ++C) {
                        const cellValue = readExcelCellByPosition(sheet, C, range.s.r, "string", sheetName);
                        columns.push(cellValue)
                    }
                    // for(var R = range.s.r + 1; R <= (range.e.r>1000?1000:range.e.r); ++R) {
                    for(var R = range.s.r + 1; R <= range.e.r; ++R) {
                        const row: any[] = [];
                        columnDefinitions.forEach((columnDefinition) => {
                            const columnIndex = columns.findIndex((item)=>item === columnDefinition.name)
                            let value = null;
                            if(columnIndex > -1){
                                value = readExcelCellByPosition(sheet, columnIndex, R, columnDefinition.type, sheetName)
                            }
                            row.push(value)
                        })
                        excelSheetData.data.push(row);
                    }
                }
                result.sheetDefines.push(sheetDefinition);
                result.sheets.push(excelSheetData);
            }
        } else {
            console.log(`Sheet ${sheetName} not found in the file`);
            result.sheetDefines.push(sheetDefinition);
            result.sheets.push({
                columns: [],
                data: []
            });
        }
    }
    // console.log(result)
    return result;
}

export function excelDateToJSDate(serial: number) {
    var utc_days  = Math.floor(serial - 25569);
    var utc_value = utc_days * 86400;                                        
    var date_info = new Date(utc_value * 1000);
  
    // Optional: Adjust for timezone
    var offset = date_info.getTimezoneOffset() * 60000;
    var date_with_offset = new Date(date_info.getTime() + offset);
  
    return date_with_offset;
}

export function excelDataToString(serial: number){
    const date = excelDateToJSDate(serial);
    return dayjs(date).format('YYYY-MM-DD HH:mm:ss');
}