import React from "react"
// import appLocalStorage from 'utils/localstorage'
//TODO: ERROR page for failed loading of modules
class ErrorBoundary extends React.Component<{children?: React.ReactNode, fallback?: React.ReactNode}> {
    state = { hasError: false, error: null };
    static getDerivedStateFromError(error:any) {
      return {
        hasError: true,
        error
      };
    }

    componentDidCatch(error: any, errorInfo: any) {
      // Catch errors in any components below and re-render with error message
      // this.setState({
      //   error: error,
      //   errorInfo: errorInfo
      // })
      // You can also log error messages to an error reporting service here
    //   axios.post(process.env.REACT_APP_LOGGER_URL, {
    //     id: process.env.REACT_APP_NAME,
    //     cat: appLocalStorage.getItem("token")?`${appLocalStorage.getItem("userTenantId")}_${appLocalStorage.getItem("userLoginId")}`:"_visitor",
    //     name: "error",
    //     message: `${error && error.toString && error.toString()}\n${errorInfo && errorInfo.componentStack}`,
    //     level: "CRIT",
    //   });
      console.error(error, errorInfo)
    }

    render() {
      if (this.state.hasError) {
        return this.props.fallback;
      }
      return this.props.children;
    }
}

export default ErrorBoundary