import { Theme } from "@mui/material/styles";
import { PaletteMode } from "@mui/material";

import lightTheme from "./modes/light";
import darkTheme from "./modes/dark";
// lookup theme
interface ThemeMap {
    light: Theme;
    dark: Theme;
}

const themeMap: ThemeMap = {
    light: lightTheme,
    dark: darkTheme,
};

export const themes:Array<string> = Object.keys(themeMap)

export const getThemeByMode = (mode: PaletteMode): Theme => {
    return themeMap[mode];
};