import { newColumnDefinition } from "./utils"
import { ColumnDefinition } from "types"

export const AlternativeID  = newColumnDefinition("AlternativeID", "Alternative ID", "string")
export const ID =  newColumnDefinition("ID", "ID", "string")
export const Type = newColumnDefinition("Type", "Type", "string")
export const Easting = newColumnDefinition("Easting", "Easting", "number")
export const Northing = newColumnDefinition("Northing", "Northing", "number")
export const GroundLevelmAHD =  newColumnDefinition("GroundLevelmAHD","Ground Level (mAHD)", "number")
export const ToCmAHD =  newColumnDefinition("ToCmAHD","ToC (mAHD)", "number")
export const VWPDepthmAHD =  newColumnDefinition("VWPDepthmAHD","VWP Depth (mAHD)", "number")
export const StickUp =  newColumnDefinition("StickUp", "Stick Up (m)", "number")
export const Comments =  newColumnDefinition("Comments", "Comments", "string")

export type RowType = {
    AlternativeID: string;
    ID: string;
    Type: string;
    Easting: number;
    Northing: number;
    GroundLevelmAHD: number;
    ToCmAHD: number;
    VWPDepthmAHD: number;
    StickUp: number;
    Comments: string;
}

export const columns: Array<ColumnDefinition> = [AlternativeID, ID, Type, Easting, Northing, GroundLevelmAHD, ToCmAHD, VWPDepthmAHD, StickUp, Comments]