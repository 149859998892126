import {
    createTheme,
    responsiveFontSizes,
    ThemeOptions,
} from "@mui/material/styles";
import merge from "lodash/merge";
import base from "./base";

const theme: ThemeOptions = {
    palette: {
        mode: "dark" as const,
    },
};

export default responsiveFontSizes(createTheme(merge({}, base, theme)));
