import Box from "@mui/material/Box";
import React, { FC } from "react"
import { keyframes } from '@mui/system';
import { styled } from '@mui/material/styles';

export interface SpinnerProps {
    show?: boolean;
    message?: string;
}

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;


const RotatedBox = styled('div')({
    backgroundColor: 'pink',
    width: 40,
    height: 40,
    animation: `${spin} 1s infinite ease`,
    // width: 0,
    // height: 0,
    // border: '30px solid transparent',
    // borderTop: 0,
    // borderBottom: '60px solid pink',
  });

export default RotatedBox