import { ColumnDefinition, DataDefinition, SheetDefinition } from "types";

export const newSheetDefinition = (id: string, name: string): SheetDefinition => {
    return {
        id,
        name,
    }
}

export const newColumnDefinition = (id: string, name: string, type: DataDefinition): ColumnDefinition => {
    return {
        id,
        name,
        type
    }
}