//redux
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

//Types
import { RootState } from 'store/store'
import { ThemeState, ThemeMode } from "./types/theme.slice"


export const initialState: ThemeState = {
    mode: "light"
}

export const themeSlice = createSlice({
    name: "theme",
    initialState,
    reducers: {
        changeThemeMode: (state, action:PayloadAction<ThemeMode>) => {
            state.mode = action.payload
        }
    },
})

const { actions, reducer } = themeSlice;



//selectors
export const selectThemeMode = (state: RootState) => state.theme.mode

export default reducer

//actions
export const { changeThemeMode } = actions;

