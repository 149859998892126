import React, {FC} from "react"
import { ThemeProvider } from "@mui/material/styles"
import { getThemeByMode } from "./themes";
//hook
// import { useAppSelector } from "hooks/redux.hook";
//Selector
import {selectThemeMode} from "./theme.slice"

const MultiThemeProvider: FC<{children: React.ReactNode}> = (props) => {
    const themeMode = "light"
    return (
        <ThemeProvider theme={getThemeByMode(themeMode)}>
            {props.children}
        </ThemeProvider>
    )
}

export default MultiThemeProvider