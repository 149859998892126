import { SheetColumnsMap, SheetDefinition } from "types"
import { newSheetDefinition } from "./columns/utils"
import { columns as AllDataColumns } from "./columns/AllData"
import { columns as BoreConstructionColumns } from "./columns/BoreConstruction"

export const AllData  = newSheetDefinition("AllData", "All Data")
export const BoreConstruction =  newSheetDefinition("BoreConstruction","Bore Construction")
export const TARPLevel = newSheetDefinition("TARPLevel", "TARP - Level")
export const TARPQuality  = newSheetDefinition("TARPQuality", "TARP - Quality")
export const Longwalls =  newSheetDefinition("Longwalls", "Longwalls")
export const ModelledDdn = newSheetDefinition("ModelledDdn","Modelled Ddn")
export const Lists = newSheetDefinition("Lists","Lists")

export const sheetNames: Array<SheetDefinition> = [AllData, BoreConstruction, TARPLevel, TARPQuality, Longwalls, ModelledDdn, Lists]

export const sheetColumnsMap: SheetColumnsMap = {
    AllData: AllDataColumns,
    BoreConstruction: BoreConstructionColumns,
}
