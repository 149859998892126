//redux
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
//Mock Data
import { initialState } from './defaultState'
//Types
import { RootState } from 'store/store'
import { ExcelReducerData } from './types'

// export const initialState: ExcelReducerData = {
//     data: {
//         sheetDefines: [],
//         sheets: [],
//     }
// }

export const excelDataSlice = createSlice({
    name: "excelData",
    initialState,
    reducers: {
        updateData: (state, action:PayloadAction<ExcelReducerData>) => {
            state.data = action.payload.data
        }
    },
})

const { actions, reducer } = excelDataSlice;

export const { updateData } = actions;

export default reducer

