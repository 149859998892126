import React, { useRef, useState } from "react"
import { Typography } from "@mui/material"
import Box from "@mui/material/Box"
import AddIcon from '@mui/icons-material/Add';
import { read } from "xlsx";
import CircularProgress from "@mui/material/CircularProgress";
import Spinner from "components/spinner/Spinner";
import { parseExcel } from "utils/excel";
import { useDispatch } from "react-redux";
import { updateData } from "features/excelData/excelData.slice";
import { useNavigate } from "react-router-dom";

const Home = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const inputFile = useRef<HTMLInputElement | null>(null);
    const handleSelectFile = () => {
        if(inputFile.current){
            inputFile.current.click();
        }
    }
    const handleInputChange = async(e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if(file){
            setLoading(true);
            const data = await parseExcel(file);
            dispatch(updateData({data}));
            navigate("/compliance")
            // var workbook = read(data);
            // const wsnames = workbook.SheetNames;
            // console.log(wsnames);
            setLoading(false);
        }
    }
    return(
        <Box sx={{
            width: "100%",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        }}>
            <Box sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: "24px"
            
            }}>
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "stretch",
                    gap: "0px"
                }}
                >
                    <Typography variant="f6b">Open File</Typography>
                    <Typography variant="f9" color="rgba(0,0,0,0.5)">Now supporting .xlsx extension files</Typography>
                </Box>
                
                {loading ? <Spinner/> :
                    <Box 
                        sx={{
                            width: "30px",
                            height: "30px",
                            border: "1px solid rgba(0,0,0,0.5)",
                            borderStyle: "dashed",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            "&:hover": {
                                cursor: "pointer"
                            }
                        }}
                        onClick={handleSelectFile}
                    >
                        <AddIcon sx={{color: "rgba(0,0,0,0.5)}}"}}/>
                    </Box>
                }

            </Box>

            <input type='file' id='file' accept=".xlsx" ref={inputFile} style={{display: 'none'}} onChange={handleInputChange}/>
        </Box>
    )
}

export default Home