import { ExcelReducerData } from "./types";

export const initialState: ExcelReducerData = 
    {
      data: {
        sheetDefines: [
          {
            id: 'AllData',
            name: 'All Data'
          },
          {
            id: 'BoreConstruction',
            name: 'Bore Construction'
          }
        ],
        sheets: [
          {
            columns: [
              {
                id: 'ClientOriginalDataID',
                name: 'Client Original Data ID',
                type: 'string'
              },
              {
                id: 'PlotID',
                name: 'Plot_ID',
                type: 'string'
              },
              {
                id: 'DateTime',
                name: 'DateTime',
                type: 'datetime'
              },
              {
                id: 'Type',
                name: 'Type',
                type: 'string'
              },
              {
                id: 'ManualWLmbTOC',
                name: 'Manual_WL_mbTOC',
                type: 'number'
              },
              {
                id: 'LoggerWLmbTOC',
                name: 'Logger_WL_mbTOC',
                type: 'number'
              },
              {
                id: 'LoggerWLmAHD',
                name: 'Logger_WL_mAHD',
                type: 'number'
              },
              {
                id: 'TotalDissolvedSolids_field',
                name: 'Total Dissolved Solids (field)',
                type: 'number'
              },
              {
                id: 'DissolvedOxygen_field',
                name: 'Dissolved Oxygen (field)',
                type: 'number'
              },
              {
                id: 'ElectricalConductivity_field',
                name: 'Electrical Conductivity (field)',
                type: 'number'
              },
              {
                id: 'pH_field',
                name: 'pH (field)',
                type: 'number'
              },
              {
                id: 'Redox_field',
                name: 'Redox (field)',
                type: 'number'
              },
              {
                id: 'Temperature_field',
                name: 'Temperature (field)',
                type: 'number'
              },
              {
                id: 'Calcium_Dissolved',
                name: 'Calcium - Dissolved',
                type: 'number'
              },
              {
                id: 'Potassium_Dissolved',
                name: 'Potassium - Dissolved',
                type: 'number'
              },
              {
                id: 'Sodium_Dissolved',
                name: 'Sodium - Dissolved',
                type: 'number'
              },
              {
                id: 'Magnesium_Dissolved',
                name: 'Magnesium - Dissolved',
                type: 'number'
              },
              {
                id: 'Hardness',
                name: 'Hardness',
                type: 'number'
              },
              {
                id: 'HydroxideAlkalinityasCaCO3',
                name: 'Hydroxide Alkalinity (OH-) as CaCO3',
                type: 'number'
              },
              {
                id: 'BicarbonateAlkalinityasCaCO3',
                name: 'Bicarbonate Alkalinity as CaCO3',
                type: 'number'
              },
              {
                id: 'CarbonateAlkalinityasCaCO3',
                name: 'Carbonate Alkalinity as CaCO3',
                type: 'number'
              },
              {
                id: 'TotalAlkalinityasCaCO3',
                name: 'Total Alkalinity as CaCO3',
                type: 'number'
              },
              {
                id: 'Sulphate_SO4',
                name: 'Sulphate, SO4',
                type: 'number'
              },
              {
                id: 'Chloride_Cl',
                name: 'Chloride, Cl',
                type: 'number'
              },
              {
                id: 'IonicBalance',
                name: 'Ionic Balance',
                type: 'number'
              },
              {
                id: 'TotalNitrogeninwater',
                name: 'Total Nitrogen in water',
                type: 'number'
              },
              {
                id: 'Fluoride_F',
                name: 'Fluoride, F',
                type: 'number'
              },
              {
                id: 'DissolvedOrganicCarbon',
                name: 'Dissolved Organic Carbon',
                type: 'number'
              },
              {
                id: 'Phosphorus_Total',
                name: 'Phosphorus - Total',
                type: 'number'
              },
              {
                id: 'AluminiumTotal',
                name: 'Aluminium-Total',
                type: 'number'
              },
              {
                id: 'ArsenicTotal',
                name: 'Arsenic-Total',
                type: 'number'
              },
              {
                id: 'BariumTotal',
                name: 'Barium-Total',
                type: 'number'
              },
              {
                id: 'CobaltTotal',
                name: 'Cobalt-Total',
                type: 'number'
              },
              {
                id: 'CopperTotal',
                name: 'Copper-Total',
                type: 'number'
              },
              {
                id: 'LithiumTotal',
                name: 'Lithium-Total',
                type: 'number'
              },
              {
                id: 'ManganeseTotal',
                name: 'Manganese-Total',
                type: 'number'
              },
              {
                id: 'NickelTotal',
                name: 'Nickel-Total',
                type: 'number'
              },
              {
                id: 'LeadTotal',
                name: 'Lead-Total',
                type: 'number'
              },
              {
                id: 'SeleniumTotal',
                name: 'Selenium-Total',
                type: 'number'
              },
              {
                id: 'StrontiumTotal',
                name: 'Strontium-Total',
                type: 'number'
              },
              {
                id: 'ZincTotal',
                name: 'Zinc-Total',
                type: 'number'
              },
              {
                id: 'IronTotal',
                name: 'Iron-Total',
                type: 'number'
              },
              {
                id: 'AluminiumDissolved',
                name: 'Aluminium-Dissolved',
                type: 'number'
              },
              {
                id: 'ArsenicDissolved',
                name: 'Arsenic-Dissolved',
                type: 'number'
              },
              {
                id: 'BariumDissolved',
                name: 'Barium-Dissolved',
                type: 'number'
              },
              {
                id: 'CobaltDissolved',
                name: 'Cobalt-Dissolved',
                type: 'number'
              },
              {
                id: 'CopperDissolved',
                name: 'Copper-Dissolved',
                type: 'number'
              },
              {
                id: 'LithiumDissolved',
                name: 'Lithium-Dissolved',
                type: 'number'
              },
              {
                id: 'ManganeseDissolved',
                name: 'Manganese-Dissolved',
                type: 'number'
              },
              {
                id: 'NickelDissolved',
                name: 'Nickel-Dissolved',
                type: 'number'
              },
              {
                id: 'LeadDissolved',
                name: 'Lead-Dissolved',
                type: 'number'
              },
              {
                id: 'SeleniumDissolved',
                name: 'Selenium-Dissolved',
                type: 'number'
              },
              {
                id: 'StrontiumDissolved',
                name: 'Strontium-Dissolved',
                type: 'number'
              },
              {
                id: 'ZincDissolved',
                name: 'Zinc-Dissolved',
                type: 'number'
              },
              {
                id: 'IronDissolved',
                name: 'Iron-Dissolved',
                type: 'number'
              }
            ],
            data: [
              [
                'GW062068',
                'GW062068',
                44846,
                'Private Bore',
                19.21,
                null,
                null,
                2.71,
                1.62,
                2844,
                7.85,
                -75.9,
                17.2,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'GW062068',
                'GW062068',
                44872,
                'Private Bore',
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                0.6,
                2,
                220,
                35,
                150,
                2.5,
                2.5,
                2.5,
                2.5,
                150,
                410,
                -8,
                0.4,
                0.2,
                2,
                0.08,
                6.4,
                0.001,
                0.2,
                0.038,
                0.047,
                1.4,
                0.01,
                2.7,
                0.025,
                0.018,
                0.0005,
                0.017,
                0.18,
                5.9,
                0.0005,
                0.21,
                0.035,
                0.042,
                0.01,
                2.5,
                0.021,
                0.014,
                0.0005,
                0.015,
                0.15,
                0.06
              ],
              [
                'GW062068',
                'GW062068',
                44904,
                'Private Bore',
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                0.5,
                2,
                200,
                32,
                130,
                2.5,
                2.5,
                2.5,
                2.5,
                130,
                460,
                -15,
                0.3,
                0.2,
                4,
                0.4,
                7.9,
                0.001,
                0.24,
                0.036,
                0.023,
                5.9,
                0.012,
                2.9,
                0.031,
                0.02,
                0.0005,
                0.017,
                0.12,
                6.4,
                0.001,
                0.24,
                0.044,
                0.014,
                0.01,
                2.8,
                0.31,
                0.009,
                0.0005,
                0.016,
                0.13,
                3.1
              ],
              [
                'GW062068',
                'GW062068',
                44904,
                'Private Bore',
                18.89,
                null,
                null,
                988,
                6.2,
                1521,
                3.32,
                366.2,
                17.2,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'GW062068',
                'GW062068',
                44939,
                'Private Bore',
                19.72,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'GW062068',
                'GW062068',
                44939,
                'Private Bore',
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                0.6,
                2,
                280,
                46,
                190,
                2.5,
                2.5,
                2.5,
                2.5,
                35,
                560,
                -1,
                0.4,
                0.2,
                2,
                0.06,
                8.6,
                0.001,
                0.24,
                0.04,
                0.035,
                1.3,
                0.011,
                3.4,
                0.028,
                0.018,
                0.0005,
                0.019,
                0.14,
                9.3,
                0.003,
                0.25,
                0.043,
                0.03,
                0.013,
                3.4,
                0.028,
                0.016,
                0.0005,
                0.02,
                0.17,
                0.05
              ],
              [
                'GW062068',
                'GW062068',
                44939,
                'Private Bore',
                19.72,
                null,
                null,
                390,
                6.54,
                602,
                4.24,
                205.4,
                17.8,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'GW062068',
                'GW062068',
                44964,
                'Private Bore',
                18.9,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'GW062068',
                'GW062068',
                44964,
                'Private Bore',
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                0.6,
                2,
                270,
                44,
                180,
                2.5,
                2.5,
                2.5,
                2.5,
                59,
                650,
                -12,
                0.4,
                0.2,
                2,
                0.06,
                11,
                0.001,
                0.23,
                0.04,
                0.031,
                0.013,
                3.8,
                0.025,
                0.019,
                0.0005,
                0.019,
                0.15,
                1.9,
                10,
                0.0005,
                0.23,
                0.039,
                0.027,
                0.012,
                3.6,
                0.025,
                0.017,
                0.0005,
                0.019,
                0.14,
                0.2
              ],
              [
                'GW062068',
                'GW062068',
                44964,
                'Private Bore',
                18.9,
                null,
                null,
                1300,
                6.33,
                2002,
                3.61,
                234.8,
                17.8,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'GW062068',
                'GW062068',
                45054,
                'Private Bore',
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'GW062068',
                'GW062068',
                45086,
                'Private Bore',
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'GW062068',
                'GW062068',
                45110,
                'Private Bore',
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'GW062068',
                'GW062068',
                45112,
                'Private Bore',
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'GW104008',
                'GW104008',
                44872,
                'Private Bore',
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                11,
                5.8,
                220,
                45,
                210,
                2.5,
                87,
                2.5,
                87,
                37,
                390,
                2,
                0.4,
                0.1,
                0.5,
                0.4,
                0.19,
                0.001,
                0.18,
                0.023,
                0.0005,
                44,
                0.066,
                2.1,
                0.019,
                0.007,
                0.0005,
                0.1,
                0.022,
                0.01,
                0.0005,
                0.18,
                0.021,
                0.0005,
                0.065,
                2,
                0.019,
                0.0005,
                0.0005,
                0.096,
                0.014,
                32
              ],
              [
                'GW104008',
                'GW104008',
                44904,
                'Private Bore',
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                10,
                5.7,
                210,
                51,
                240,
                2.5,
                44,
                2.5,
                44,
                43,
                440,
                -2,
                0.05,
                0.2,
                9,
                0.3,
                0.33,
                0.002,
                0.18,
                0.021,
                0.023,
                43,
                0.076,
                2,
                0.056,
                0.031,
                0.0005,
                0.11,
                0.36,
                0.005,
                0.0005,
                0.19,
                0.021,
                0.001,
                0.075,
                2,
                0.019,
                0.0005,
                0.0005,
                0.096,
                0.014,
                35
              ],
              [
                'GW104008',
                'GW104008',
                44904,
                'Private Bore',
                46.56,
                null,
                null,
                1244.5,
                2.4,
                1913,
                5.5,
                11.6,
                18,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'GW104008',
                'GW104008',
                44939,
                'Private Bore',
                46.01,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'GW104008',
                'GW104008',
                44939,
                'Private Bore',
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                12,
                6.1,
                230,
                55,
                250,
                2.5,
                34,
                2.5,
                34,
                34,
                440,
                5,
                0.05,
                0.1,
                0.5,
                0.2,
                0.03,
                0.0005,
                0.16,
                0.02,
                0.0005,
                38,
                0.059,
                2.2,
                0.02,
                0.004,
                0.0005,
                0.09,
                0.015,
                0.005,
                0.0005,
                0.16,
                0.021,
                0.0005,
                0.069,
                2.1,
                0.019,
                0.0005,
                0.0005,
                0.098,
                0.014,
                40
              ],
              [
                'GW104008',
                'GW104008',
                44939,
                'Private Bore',
                46.01,
                null,
                null,
                377,
                2.15,
                591,
                6.15,
                -10.2,
                18.2,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'GW104008',
                'GW104008',
                44964,
                'Private Bore',
                46.68,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'GW104088',
                'GW104008',
                44964,
                'Private Bore',
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                11,
                6,
                240,
                52,
                240,
                2.5,
                84,
                2.5,
                84,
                37,
                470,
                -1,
                0.05,
                0.1,
                0.5,
                0.1,
                0.07,
                0.0005,
                0.16,
                0.02,
                0.0005,
                0.064,
                2.4,
                0.019,
                0.006,
                0.0005,
                0.089,
                0.014,
                41,
                0.005,
                0.0005,
                0.16,
                0.019,
                0.0005,
                0.066,
                2.3,
                0.017,
                0.0005,
                0.0005,
                0.086,
                0.008,
                36
              ],
              [
                'GW104008',
                'GW104008',
                44964,
                'Private Bore',
                46.68,
                null,
                null,
                1105,
                1.71,
                1635,
                5.92,
                28.7,
                18.3,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'GW104008',
                'GW104008',
                44991,
                'Private Bore',
                46.3,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'GW104008',
                'GW104008',
                44991,
                'Private Bore',
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                10,
                6,
                240,
                47,
                220,
                2.5,
                62,
                2.5,
                62,
                40,
                490,
                -4,
                0.05,
                0.1,
                0.5,
                0.2,
                0.59,
                0.001,
                0.18,
                0.021,
                0.0005,
                0.067,
                2,
                0.019,
                0.01,
                0.0005,
                0.096,
                0.021,
                35,
                0.005,
                0.0005,
                0.17,
                0.021,
                0.0005,
                0.071,
                2.1,
                0.019,
                0.0005,
                0.0005,
                0.098,
                0.014,
                33
              ],
              [
                'GW104008',
                'GW104008',
                44991,
                'Private Bore',
                46.3,
                null,
                null,
                1196,
                2.02,
                1841,
                5.94,
                -6.9,
                18.3,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'GW104008',
                'GW104008',
                45028,
                'Private Bore',
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                10,
                5.5,
                200,
                50,
                230,
                2.5,
                65,
                2.5,
                65,
                45,
                490,
                -8,
                0.05,
                0.1,
                1,
                0.09,
                0.02,
                0,
                0.16,
                0.02,
                0.0005,
                0.058,
                2,
                0.018,
                0.001,
                0.0005,
                0.09,
                0.018,
                34,
                0.005,
                0.0005,
                0.17,
                0.02,
                0.003,
                0.062,
                2.1,
                0.018,
                0.0005,
                0.0005,
                0.098,
                0.014,
                30
              ],
              [
                'GW104008',
                'GW104008',
                45030,
                'Private Bore',
                46.93,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'GW104008',
                'GW104008',
                45030,
                'Private Bore',
                46.93,
                null,
                null,
                1033.5,
                2.4,
                1594,
                5.87,
                72.8,
                17.7,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'GW104008',
                'GW104008',
                45054,
                'Private Bore',
                49.17,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'GW104008',
                'GW104008',
                45086,
                'Private Bore',
                46.32,
                null,
                null,
                1027,
                2.63,
                1584,
                5.88,
                13.4,
                17.8,
                10,
                6,
                240,
                48,
                220,
                2.5,
                75,
                2.5,
                75,
                40,
                470,
                -1,
                0.05,
                0.2,
                0.5,
                0.07,
                0.05,
                0.0005,
                0.18,
                0.022,
                0.0005,
                0.065,
                2.1,
                0.021,
                0.002,
                0.0005,
                0.093,
                0.015,
                37,
                0.02,
                0.0005,
                0.17,
                0.022,
                0.0005,
                0.067,
                2.1,
                0.02,
                0.002,
                0.0005,
                0.096,
                0.014,
                33
              ],
              [
                'GW104008',
                'GW104008',
                45110,
                'Private Bore',
                42.42,
                null,
                null,
                1163.5,
                1.77,
                1792,
                5.84,
                45,
                17.5,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'GW104008',
                'GW104008',
                45112,
                'Private Bore',
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                8.8,
                5.1,
                180,
                41,
                190,
                2.5,
                60,
                2.5,
                60,
                41,
                470,
                -14,
                0.05,
                0.1,
                2,
                0.025,
                0.005,
                0.0005,
                0.17,
                0.02,
                0.0005,
                0.062,
                2.4,
                0.018,
                0.0005,
                0.0005,
                0.091,
                0.014,
                33,
                0.04,
                0.0005,
                0.17,
                0.02,
                0.0005,
                0.061,
                2.3,
                0.019,
                0.004,
                0.0005,
                0.093,
                0.017,
                33
              ],
              [
                'GW104008',
                'GW104008',
                45139,
                'Private Bore',
                47.19,
                null,
                null,
                559,
                2.63,
                866,
                5.88,
                -65.9,
                18.2,
                8.4,
                5,
                200,
                39,
                180,
                2.5,
                81,
                2.5,
                81,
                36,
                430,
                -7,
                0.05,
                0.1,
                0.5,
                0.06,
                0.03,
                0.0005,
                0.15,
                0.019,
                0.0005,
                0.058,
                2,
                0.018,
                0.0005,
                0.0005,
                0.086,
                0.01,
                31,
                0.005,
                0.0005,
                0.16,
                0.019,
                0.002,
                0.063,
                2.1,
                0.018,
                0.0005,
                0.0005,
                0.089,
                0.007,
                30
              ],
              [
                'GW104008',
                'GW104008',
                45173,
                'Private Bore',
                47.42,
                null,
                null,
                1046.5,
                2.91,
                1612,
                5.69,
                34.9,
                17.9,
                9.4,
                5.3,
                250,
                45,
                210,
                2.5,
                84,
                2.5,
                84,
                31,
                370,
                9,
                0.05,
                0.1,
                0.5,
                0.2,
                0.07,
                0.0005,
                0.18,
                0.023,
                0.0005,
                0.071,
                2.5,
                0.022,
                0.003,
                0.0005,
                0.1,
                0.018,
                46,
                0.005,
                0.0005,
                0.18,
                0.022,
                0.0005,
                0.066,
                2.1,
                0.019,
                0.0005,
                0.0005,
                0.099,
                0.016,
                27
              ],
              [
                'GW104008',
                'GW104008',
                45202,
                null,
                47.43,
                null,
                null,
                1079,
                2.48,
                1605,
                6.09,
                32.1,
                18,
                9.6,
                5.3,
                210,
                45,
                210,
                2.5,
                56,
                2.5,
                56,
                37,
                480,
                -7,
                0.05,
                0.2,
                0.5,
                0.3,
                0.04,
                0.001,
                0.17,
                0.021,
                0.0005,
                0.044,
                2.2,
                0.019,
                0.003,
                0.0005,
                0.085,
                0.019,
                49,
                0.005,
                0.0005,
                0.16,
                0.021,
                0.0005,
                0.049,
                2.2,
                0.018,
                0.0005,
                0.0005,
                0.089,
                0.015,
                31
              ],
              [
                'GW104008',
                'GW104008',
                45237,
                null,
                47.62,
                null,
                null,
                975,
                2.1,
                1258,
                5.87,
                -211.9,
                16.9,
                10,
                5.6,
                220,
                49,
                230,
                2.5,
                52,
                2.5,
                52,
                37,
                460,
                -2,
                0.05,
                0.2,
                2,
                0.1,
                0.02,
                0.0005,
                0.16,
                0.019,
                0.0005,
                11,
                0.058,
                2.1,
                0.02,
                0.0005,
                0.0005,
                0.091,
                0.012,
                0.005,
                0.0005,
                0.15,
                0.019,
                0.0005,
                0.059,
                2.2,
                0.019,
                0.0005,
                0.0005,
                0.094,
                0.01,
                34
              ],
              [
                'GW104008',
                'GW104008',
                45264,
                null,
                47.75,
                null,
                null,
                1150,
                2.3,
                1772,
                5.83,
                -20.6,
                18.1,
                11,
                5.8,
                250,
                51,
                240,
                2.5,
                72,
                2.5,
                72,
                39,
                490,
                -1,
                0.05,
                0.1,
                0.5,
                0.56,
                0.02,
                0.0005,
                0.16,
                0.022,
                0.0005,
                43,
                0.063,
                2.5,
                0.022,
                0.002,
                0.0005,
                0.1,
                0.021,
                0.005,
                0.0005,
                0.18,
                0.022,
                0.0005,
                0.067,
                2.2,
                0.021,
                0.0005,
                0.0005,
                0.11,
                0.016,
                34
              ],
              [
                'GW104008',
                'GW104008',
                45300,
                null,
                47.72,
                null,
                null,
                1124.5,
                2.37,
                1733,
                5.81,
                -74.1,
                18.8,
                11,
                5.8,
                240,
                51,
                240,
                2.5,
                48,
                2.5,
                48,
                34,
                420,
                5,
                0.05,
                0.2,
                0.5,
                0.5,
                0.02,
                0.0005,
                0.16,
                0.019,
                0.0005,
                40,
                0.072,
                2.1,
                0.019,
                0.001,
                0.0005,
                0.1,
                0.014,
                0.01,
                0.0005,
                0.17,
                0.02,
                0.001,
                0.072,
                2,
                0.019,
                0.0005,
                0.0005,
                0.094,
                0.011,
                31
              ],
              [
                'GW104323',
                'GW104323',
                44846,
                'Private Bore',
                68.18,
                null,
                null,
                1001,
                2.04,
                1313,
                4.95,
                6.9,
                17.1,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'GW104323',
                'GW104323',
                44872,
                'Private Bore',
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                0.25,
                2,
                190,
                27,
                110,
                2.5,
                2.5,
                2.5,
                2.5,
                91,
                340,
                -5,
                0.2,
                0.4,
                3,
                0.025,
                3.5,
                0.0005,
                0.27,
                0.042,
                0.21,
                0.57,
                0.01,
                2.1,
                0.029,
                0.075,
                0.0005,
                0.012,
                0.35,
                3.2,
                0.0005,
                0.27,
                0.041,
                0.2,
                0.01,
                2.1,
                0.029,
                0.065,
                0.0005,
                0.012,
                0.34,
                0.07
              ],
              [
                'GW104323',
                'GW104323',
                44904,
                'Private Bore',
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                0.8,
                1,
                130,
                18,
                75,
                2.5,
                2.5,
                2.5,
                2.5,
                140,
                270,
                -19,
                0.05,
                0.3,
                2,
                0.025,
                1.9,
                0.0005,
                0.19,
                0.03,
                0.064,
                3.4,
                0.01,
                1.8,
                0.022,
                0.015,
                0.0005,
                0.011,
                0.14,
                1.8,
                0.0005,
                0.22,
                0.032,
                0.048,
                0.011,
                1.7,
                0.022,
                0.014,
                0.0005,
                0.011,
                0.14,
                3.2
              ],
              [
                'GW104323',
                'GW104323',
                44904,
                'Private Bore',
                null,
                null,
                null,
                669.5,
                1.2,
                1034,
                3.63,
                306.1,
                17.9,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'GW104323',
                'GW104323',
                44939,
                'Private Bore',
                70.24,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'GW104323',
                'GW104323',
                44939,
                'Private Bore',
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                4,
                2,
                48,
                12,
                60,
                2.5,
                15,
                2.5,
                15,
                10,
                120,
                -6,
                0.05,
                0.05,
                0.5,
                0.025,
                0.11,
                0.0005,
                0.089,
                0.012,
                0.009,
                17,
                0.011,
                1.5,
                0.011,
                0.001,
                0.0005,
                0.02,
                0.049,
                0.11,
                0.0005,
                0.094,
                0.012,
                0.005,
                0.014,
                1.4,
                0.011,
                0.0005,
                0.0005,
                0.021,
                0.057,
                18
              ],
              [
                'GW104323',
                'GW104323',
                44939,
                'Private Bore',
                70.24,
                null,
                null,
                220.3,
                2.74,
                343.7,
                5.75,
                63.8,
                21.8,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'GW104323',
                'GW104323',
                44964,
                'Private Bore',
                69.82,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'GW104323',
                'GW104323',
                44964,
                'Private Bore',
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                2,
                2,
                180,
                27,
                110,
                2.5,
                2.5,
                2.5,
                2.5,
                12,
                400,
                -6,
                0.05,
                0.4,
                2,
                0.025,
                2.6,
                0.0005,
                0.26,
                0.036,
                0.026,
                0.011,
                2.8,
                0.026,
                0.018,
                0.0005,
                0.016,
                0.16,
                7.9,
                2.5,
                0.0005,
                0.29,
                0.036,
                0.017,
                0.012,
                2.7,
                0.026,
                0.017,
                0.0005,
                0.015,
                0.15,
                6.2
              ],
              [
                'GW104323',
                'GW104323',
                44964,
                'Private Bore',
                69.82,
                null,
                null,
                845,
                0.69,
                1295,
                4.23,
                92.6,
                19.3,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'GW104323',
                'GW104323',
                44991,
                'Private Bore',
                69.85,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'GW104323',
                'GW104323',
                44991,
                'Private Bore',
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                4,
                1,
                44,
                9.9,
                50,
                2.5,
                21,
                2.5,
                21,
                15,
                110,
                -15,
                0.05,
                0.05,
                0.5,
                0.025,
                0.09,
                0.0005,
                0.077,
                0.01,
                0.004,
                0.013,
                1.2,
                0.008,
                0.0005,
                0.0005,
                0.018,
                0.036,
                15,
                0.1,
                0.0005,
                0.095,
                0.011,
                0.003,
                0.014,
                1.3,
                0.009,
                0.0005,
                0.0005,
                0.02,
                0.038,
                16
              ],
              [
                'GW104323',
                'GW104323',
                44991,
                'Private Bore',
                69.85,
                null,
                null,
                455,
                2.26,
                697,
                5.47,
                42.3,
                20.9,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'GW104323',
                'GW104323',
                45028,
                'Private Bore',
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                1,
                2,
                180,
                28,
                120,
                2.5,
                2.5,
                2.5,
                2.5,
                26,
                430,
                -10,
                0.05,
                0.4,
                2,
                0.06,
                2.8,
                0.0005,
                0.28,
                0.04,
                0.074,
                0.012,
                2.4,
                0.028,
                0.02,
                0.0005,
                0.015,
                0.22,
                3.2,
                2.9,
                0.0005,
                0.3,
                0.04,
                0.049,
                0.011,
                2.4,
                0.029,
                0.019,
                0.0005,
                0.016,
                0.21,
                2.6
              ],
              [
                'GW104323',
                'GW104323',
                45030,
                'Private Bore',
                69.92,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'GW104323',
                'GW104323',
                45030,
                'Private Bore',
                69.92,
                null,
                null,
                812.5,
                2.08,
                1251,
                4.23,
                193.3,
                17.5,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'GW104323',
                'GW104323',
                45054,
                'Private Bore',
                70.17,
                null,
                null,
                null,
                2.69,
                1281,
                4.08,
                257.7,
                15.6,
                0.25,
                1,
                180,
                25,
                100,
                2.5,
                2.5,
                2.5,
                2.5,
                83,
                410,
                -14,
                0.2,
                0.4,
                1,
                0.025,
                3.8,
                0.0005,
                0.29,
                0.039,
                0.24,
                0.011,
                2.2,
                0.028,
                0.05,
                0.0005,
                0.01,
                0.42,
                0.1,
                3.6,
                0.001,
                0.28,
                0.044,
                0.27,
                0.011,
                2.4,
                0.032,
                0.073,
                0.0005,
                0.012,
                0.54,
                0.07
              ],
              [
                'GW104323',
                'GW104323',
                45086,
                'Private Bore',
                69.54,
                null,
                null,
                832,
                2.89,
                1279,
                4.01,
                86.1,
                10.4,
                0.25,
                2,
                200,
                26,
                110,
                2.5,
                2.5,
                2.5,
                2.5,
                27,
                410,
                -5,
                0.05,
                0.5,
                1,
                0.025,
                3.6,
                0.002,
                0.3,
                0.044,
                0.083,
                0.012,
                2.3,
                0.032,
                0.039,
                0.0005,
                0.012,
                0.25,
                1.4,
                3.3,
                0.001,
                0.28,
                0.042,
                0.049,
                0.01,
                2.2,
                0.03,
                0.036,
                0.0005,
                0.01,
                0.2,
                0.11
              ],
              [
                'GW104328',
                'GW104323',
                45110,
                'Private Bore',
                70.38,
                null,
                null,
                903.5,
                1.97,
                1392,
                3.57,
                412.2,
                14.2,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'GW104328',
                'GW104323',
                45112,
                'Private Bore',
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                0.25,
                2,
                180,
                25,
                100,
                2.5,
                2.5,
                2.5,
                2.5,
                27,
                450,
                -15,
                0.05,
                0.4,
                3,
                0.025,
                3.7,
                0.002,
                0.29,
                0.043,
                0.14,
                0.012,
                2.7,
                0.032,
                0.044,
                0.0005,
                0.013,
                0.39,
                0.16,
                3.5,
                0.002,
                0.29,
                0.042,
                0.11,
                0.012,
                2.6,
                0.03,
                0.045,
                0.0005,
                0.011,
                0.41,
                0.08
              ],
              [
                'GW104323',
                'GW104323',
                45139,
                'Private Bore',
                70.48,
                null,
                null,
                237.25,
                1.72,
                365.3,
                5.58,
                -76.4,
                16.4,
                3,
                1,
                46,
                8.5,
                43,
                2.5,
                36,
                2.5,
                36,
                6,
                88,
                -7,
                0.05,
                0.05,
                1,
                0.025,
                0.05,
                0.0005,
                0.075,
                0.009,
                0.002,
                0.013,
                1.2,
                0.008,
                0.0005,
                0.0005,
                0.018,
                0.04,
                14,
                0.05,
                0.0005,
                0.076,
                0.009,
                0.002,
                0.013,
                1.3,
                0.008,
                0.0005,
                0.0005,
                0.018,
                0.042,
                13
              ],
              [
                'GW104323',
                'GW104323',
                45173,
                'Private Bore',
                70.5,
                null,
                null,
                1336,
                1.89,
                1236,
                4.45,
                153.8,
                16.8,
                0.25,
                2,
                250,
                26,
                110,
                2.5,
                8,
                2.5,
                8,
                1,
                380,
                9,
                0.05,
                0.8,
                1,
                0.025,
                3.2,
                0.003,
                0.33,
                0.049,
                0.052,
                0.012,
                2.9,
                0.034,
                0.008,
                0.0005,
                0.015,
                0.23,
                1.1,
                3.4,
                0.0005,
                0.33,
                0.047,
                0.036,
                0.012,
                2.4,
                0.032,
                0.007,
                0.0005,
                0.014,
                0.22,
                0.15
              ],
              [
                'GW104323',
                'GW104323',
                45202,
                null,
                71.23,
                null,
                null,
                871,
                1.98,
                1340,
                4.44,
                84.1,
                28.4,
                0.6,
                2,
                200,
                25,
                100,
                2.5,
                7,
                2.5,
                7,
                2,
                450,
                -9,
                0.05,
                0.8,
                1,
                0.025,
                3.1,
                0.002,
                0.29,
                0.045,
                0.033,
                0.008,
                2.6,
                0.032,
                0.007,
                0.0005,
                0.013,
                0.21,
                0.14,
                2.8,
                0.002,
                0.31,
                0.044,
                0.067,
                0.009,
                2.6,
                0.031,
                0.006,
                0.0005,
                0.014,
                0.25,
                0.07
              ],
              [
                'GW104323',
                'GW104323',
                45237,
                null,
                73.1,
                null,
                null,
                230.79,
                1.76,
                303.9,
                5.61,
                -241.4,
                16.8,
                5,
                2,
                47,
                12,
                61,
                2.5,
                45,
                2.5,
                45,
                5,
                100,
                -7,
                0.05,
                0.1,
                2,
                0.025,
                0.11,
                0.0005,
                0.093,
                0.01,
                0.004,
                20,
                0.013,
                1.5,
                0.008,
                0.0005,
                0.0005,
                0.025,
                0.039,
                0.08,
                0.0005,
                0.091,
                0.009,
                0.002,
                0.014,
                1.4,
                0.008,
                0.0005,
                0.0005,
                0.023,
                0.032,
                20
              ],
              [
                'GW104323',
                'GW104323',
                45264,
                null,
                70.24,
                null,
                null,
                409,
                1.91,
                634,
                4.89,
                129.2,
                18.1,
                3,
                2,
                84,
                15,
                71,
                2.5,
                14,
                2.5,
                14,
                4,
                180,
                -2,
                0.05,
                0.2,
                3,
                0.025,
                0.55,
                0.0005,
                0.15,
                0.02,
                0.081,
                3.5,
                0.011,
                1.7,
                0.015,
                0.008,
                0.0005,
                0.02,
                0.24,
                0.49,
                0.0005,
                0.17,
                0.02,
                0.051,
                0.012,
                1.7,
                0.015,
                0.003,
                0.0005,
                0.021,
                0.24,
                0.74
              ],
              [
                'GW104323',
                'GW104323',
                45300,
                null,
                71.9,
                null,
                null,
                8.25,
                2.59,
                1273,
                4.33,
                106,
                21.4,
                0.6,
                2,
                190,
                25,
                100,
                2.5,
                2.5,
                2.5,
                2.5,
                10,
                340,
                4,
                1.2,
                0.5,
                0.5,
                0.025,
                3.7,
                0.002,
                0.27,
                0.036,
                0.19,
                0.95,
                0.012,
                2.2,
                0.028,
                0.029,
                0.0005,
                0.015,
                0.48,
                3.4,
                0.001,
                0.27,
                0.039,
                0.19,
                0.011,
                2.1,
                0.031,
                0.025,
                0.0005,
                0.014,
                0.47,
                0.03
              ],
              [
                'GW104659',
                'GW104659',
                44846,
                'Private Bore',
                42.82,
                null,
                null,
                338.65,
                2.85,
                446.5,
                13.15,
                33.7,
                17.5,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'GW104659',
                'GW104659',
                44872,
                'Private Bore',
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                3,
                2,
                71,
                12,
                59,
                2.5,
                11,
                2.5,
                11,
                8,
                130,
                3,
                0.1,
                0.05,
                2,
                0.7,
                2.7,
                0.001,
                0.19,
                0.011,
                0.015,
                110,
                0.016,
                1.7,
                0.011,
                0.022,
                0.0005,
                0.031,
                0.032,
                0.01,
                0.0005,
                0.16,
                0.01,
                0.003,
                0.015,
                1.6,
                0.009,
                0.0005,
                0.0005,
                0.029,
                0.016,
                20
              ],
              [
                'GW104659',
                'GW104659',
                44904,
                'Private Bore',
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                3,
                1,
                72,
                11,
                51,
                2.5,
                9,
                2.5,
                9,
                13,
                130,
                0,
                0.05,
                0.05,
                5,
                0.4,
                0.8,
                0.0005,
                0.18,
                0.01,
                0.005,
                60,
                0.017,
                1.7,
                0.009,
                0.006,
                0.0005,
                0.03,
                0.007,
                0.02,
                0.0005,
                0.19,
                0.01,
                0.003,
                0.016,
                1.7,
                0.01,
                0.0005,
                0.0005,
                0.029,
                0.016,
                26
              ],
              [
                'GW104659',
                'GW104659',
                44904,
                'Private Bore',
                46.1,
                null,
                null,
                409.5,
                1.3,
                628,
                5.12,
                30.8,
                18.1,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'GW104659',
                'GW104659',
                44939,
                'Private Bore',
                86.06,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'GW104659',
                'GW104659',
                44939,
                'Private Bore',
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                4,
                2,
                70,
                12,
                57,
                2.5,
                23,
                2.5,
                23,
                9,
                140,
                -4,
                0.05,
                0.05,
                0.5,
                0.025,
                0.005,
                0.0005,
                0.15,
                0.008,
                0.0005,
                26,
                0.014,
                1.9,
                0.009,
                0.0005,
                0.0005,
                0.028,
                0.006,
                0.005,
                0.0005,
                0.16,
                0.009,
                0.0005,
                0.016,
                1.8,
                0.009,
                0.0005,
                0.0005,
                0.028,
                0.009,
                29
              ],
              [
                'GW104659',
                'GW104659',
                44939,
                'Private Bore',
                86.06,
                null,
                null,
                241.5,
                1.5,
                366.8,
                5.94,
                60.4,
                18.7,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'GW104659',
                'GW104659',
                44964,
                'Private Bore',
                45.03,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'GW104659',
                'GW104659',
                44964,
                'Private Bore',
                45.03,
                null,
                null,
                396.5,
                1.14,
                608,
                5.65,
                85.5,
                18,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'GW104659',
                'GW104659',
                44991,
                'Private Bore',
                44.25,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'GW104659',
                'GW104659',
                44991,
                'Private Bore',
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                3,
                2,
                68,
                10,
                50,
                2.5,
                17,
                2.5,
                17,
                10,
                150,
                -9,
                0.05,
                0.05,
                0.5,
                2.6,
                1.9,
                0.002,
                0.19,
                0.009,
                0.011,
                0.016,
                1.6,
                0.01,
                0.009,
                0.0005,
                0.027,
                0.02,
                130,
                0.005,
                0.0005,
                0.16,
                0.01,
                0.0005,
                0.017,
                1.7,
                0.009,
                0.0005,
                0.0005,
                0.03,
                0.015,
                23
              ],
              [
                'GW104659',
                'GW104659',
                44991,
                'Private Bore',
                44.25,
                null,
                null,
                526.5,
                2.11,
                819,
                5.77,
                36.2,
                18.1,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'GW104659',
                'GW104659',
                45028,
                'Private Bore',
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                3,
                2,
                64,
                12,
                58,
                2.5,
                13,
                2.5,
                13,
                13,
                150,
                -10,
                0.05,
                0.05,
                0.5,
                1.4,
                1.6,
                0.004,
                0.17,
                0.009,
                0.008,
                0.019,
                1.7,
                0.01,
                0.011,
                0.0005,
                0.03,
                0.025,
                100,
                0.005,
                0.0005,
                0.15,
                0.008,
                0.0005,
                0.015,
                1.6,
                0.008,
                0.0005,
                0.0005,
                0.028,
                0.01,
                7.5
              ],
              [
                'GW104659',
                'GW104659',
                45030,
                'Private Bore',
                45.91,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'GW104659',
                'GW104659',
                45030,
                'Private Bore',
                45.91,
                null,
                null,
                374.4,
                1.54,
                576.2,
                5.69,
                27.9,
                17.7,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'GW104659',
                'GW104659',
                45054,
                'Private Bore',
                50.35,
                null,
                null,
                null,
                1.34,
                501,
                5.41,
                68.1,
                17.3,
                3,
                1,
                65,
                11,
                52,
                2.5,
                24,
                2.5,
                24,
                12,
                150,
                -11,
                0.05,
                0.05,
                0.5,
                4.5,
                3.2,
                0.003,
                0.2,
                0.008,
                0.011,
                0.016,
                1.6,
                0.01,
                0.025,
                0.0005,
                0.03,
                0.022,
                140,
                0.02,
                0.0005,
                0.15,
                0.009,
                0.0005,
                0.017,
                1.8,
                0.009,
                0.0005,
                0.0005,
                0.029,
                0.016,
                24
              ],
              [
                'GW104659',
                'GW104659',
                45086,
                'Private Bore',
                46.12,
                null,
                null,
                372.45,
                1.53,
                573.2,
                5.79,
                101.9,
                18,
                3,
                2,
                67,
                10,
                49,
                2.5,
                43,
                2.5,
                43,
                9,
                140,
                -13,
                0.05,
                0.05,
                0.5,
                0.82,
                0.63,
                0.001,
                0.16,
                0.008,
                0.004,
                0.017,
                1.5,
                0.009,
                0.005,
                0.0005,
                0.025,
                0.015,
                55,
                0.005,
                0.0005,
                0.14,
                0.008,
                0.0005,
                0.014,
                1.4,
                0.008,
                0.0005,
                0.0005,
                0.025,
                0.009,
                4
              ],
              [
                'GW104659',
                'GW104659',
                45110,
                'Private Bore',
                52.46,
                null,
                null,
                359.45,
                1.25,
                553.1,
                5.03,
                101.4,
                17.7,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'GW104659',
                'GW104659',
                45112,
                'Private Bore',
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                3,
                2,
                47,
                9.7,
                47,
                2.5,
                13,
                2.5,
                13,
                10,
                150,
                -23,
                0.05,
                0.05,
                2,
                0.96,
                0.5,
                0.0005,
                0.16,
                0.008,
                0.002,
                0.017,
                1.7,
                0.009,
                0.003,
                0.0005,
                0.029,
                0.014,
                51,
                0.005,
                0.0005,
                0.14,
                0.008,
                0.0005,
                0.018,
                1.7,
                0.008,
                0.0005,
                0.0005,
                0.025,
                0.009,
                23
              ],
              [
                'GW104659',
                'GW104659',
                45139,
                'Private Bore',
                52.358,
                null,
                null,
                323.7,
                1.96,
                498,
                5.71,
                -77.8,
                17.5,
                3,
                1,
                61,
                8.7,
                42,
                2.5,
                9,
                2.5,
                9,
                8,
                130,
                -6,
                0.05,
                0.05,
                1,
                1.1,
                0.7,
                0.001,
                0.16,
                0.008,
                0.003,
                0.015,
                1.6,
                0.009,
                0.005,
                0.0005,
                0.026,
                0.014,
                59,
                0.005,
                0.0005,
                0.14,
                0.008,
                0.0005,
                0.015,
                1.6,
                0.008,
                0.0005,
                0.0005,
                0.027,
                0.01,
                23
              ],
              [
                'GW104659',
                'GW104659',
                45173,
                'Private Bore',
                52.32,
                null,
                null,
                368.55,
                1.26,
                567.2,
                5.41,
                36.6,
                17.7,
                3,
                2,
                77,
                10,
                48,
                2.5,
                17,
                2.5,
                17,
                7,
                120,
                6,
                0.05,
                0.05,
                1,
                2.8,
                1.3,
                0.005,
                0.2,
                0.01,
                0.005,
                0.016,
                1.9,
                0.011,
                0.01,
                0.0005,
                0.031,
                0.022,
                150,
                0.02,
                0.0005,
                0.17,
                0.009,
                0.0005,
                0.016,
                1.7,
                0.008,
                0.0005,
                0.0005,
                0.029,
                0.012,
                0.07
              ],
              [
                'GW104659',
                'GW104659',
                45202,
                null,
                54.12,
                null,
                null,
                310.7,
                1.28,
                478.9,
                5.09,
                50.7,
                17.9,
                3,
                1,
                48,
                9.1,
                46,
                2.5,
                13,
                2.5,
                13,
                9,
                140,
                -18,
                0.05,
                0.05,
                0.5,
                1.8,
                0.6,
                0.002,
                0.17,
                0.008,
                0.003,
                0.013,
                1.7,
                0.01,
                0.005,
                0.0005,
                0.026,
                0.016,
                96,
                0.005,
                0.0005,
                0.11,
                0.007,
                0.0005,
                0.014,
                1.8,
                0.007,
                0.0005,
                0.0005,
                0.022,
                0.007,
                26
              ],
              [
                'GW104659',
                'GW104659',
                45237,
                null,
                52.2,
                null,
                null,
                265.89,
                0.92,
                343.4,
                5.73,
                -350.1,
                16.5,
                3,
                2,
                65,
                11,
                54,
                2.5,
                13,
                2.5,
                13,
                10,
                140,
                -4,
                0.1,
                0.05,
                2,
                0.025,
                0.005,
                0.0005,
                0.11,
                0.007,
                0.002,
                28,
                0.015,
                1.7,
                0.007,
                0.0005,
                0.0005,
                0.023,
                0.005,
                0.01,
                0.0005,
                0.13,
                0.008,
                0.0005,
                0.015,
                1.7,
                0.008,
                0.0005,
                0.0005,
                0.025,
                0.017,
                25
              ],
              [
                'GW104659',
                'GW104659',
                45264,
                null,
                50.83,
                null,
                null,
                370.5,
                17,
                569,
                5.75,
                -35.3,
                17.7,
                3,
                2,
                68,
                11,
                52,
                2.5,
                14,
                2.5,
                14,
                8,
                130,
                -2,
                0.05,
                0.05,
                2,
                0.96,
                0.49,
                0.001,
                0.15,
                0.009,
                0.004,
                55,
                0.014,
                1.7,
                0.01,
                0.005,
                0.0005,
                0.029,
                0.014,
                0.005,
                0.0005,
                0.16,
                0.009,
                0.0005,
                0.015,
                1.7,
                0.009,
                0.0005,
                0.0005,
                0.031,
                0.011,
                25
              ],
              [
                'GW104659',
                'GW104659',
                45300,
                null,
                52.91,
                null,
                null,
                367,
                3.48,
                564.1,
                5.73,
                -51.8,
                18.1,
                3,
                2,
                65,
                10,
                49,
                2.5,
                14,
                2.5,
                14,
                8,
                110,
                3,
                0.05,
                0.05,
                5,
                3.2,
                1.6,
                0.003,
                0.12,
                0.007,
                0.005,
                100,
                0.006,
                1.7,
                0.009,
                0.007,
                0.0005,
                0.027,
                0.018,
                0.005,
                0.0005,
                0.15,
                0.008,
                0.002,
                0.015,
                1.6,
                0.009,
                0.0005,
                0.0005,
                0.029,
                0.012,
                23
              ],
              [
                'GW105395',
                'GW105395',
                44846,
                'Private Bore',
                9,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'GW105395',
                'GW105395',
                44872,
                'Private Bore',
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                13,
                11,
                540,
                140,
                630,
                2.5,
                120,
                2.5,
                120,
                59,
                1000,
                7,
                0.05,
                0.2,
                2,
                0.025,
                0.04,
                0.0005,
                0.1,
                0.057,
                0.0005,
                37,
                0.081,
                1.9,
                0.062,
                0.0005,
                0.0005,
                0.18,
                0.11,
                0.005,
                0.0005,
                0.085,
                0.044,
                0.0005,
                0.078,
                1.7,
                0.032,
                0.0005,
                0.0005,
                0.17,
                0.038,
                32
              ],
              [
                'GW105395',
                'GW105395',
                44904,
                'Private Bore',
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                11,
                9.5,
                430,
                120,
                510,
                2.5,
                70,
                2.5,
                70,
                63,
                1000,
                -3,
                0.05,
                0.2,
                6,
                0.025,
                0.005,
                0.0005,
                0.086,
                0.048,
                0.0005,
                37,
                0.082,
                1.7,
                0.051,
                0.0005,
                0.0005,
                0.16,
                0.061,
                0.18,
                0.0005,
                0.095,
                0.045,
                0.001,
                0.08,
                1.8,
                0.035,
                0.0005,
                0.0005,
                0.15,
                0.042,
                36
              ],
              [
                'GW105395',
                'GW105395',
                44904,
                'Private Bore',
                0.15,
                null,
                null,
                2554.5,
                2.1,
                3928,
                5.5,
                -1.9,
                17.3,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'GW105395',
                'GW105395',
                44939,
                'Private Bore',
                0.25,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'GW105395',
                'GW105395',
                44939,
                'Private Bore',
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                13,
                11,
                480,
                140,
                590,
                2.5,
                68,
                2.5,
                68,
                57,
                1100,
                -1,
                0.05,
                0.2,
                1,
                0.025,
                0.01,
                0.0005,
                0.09,
                0.057,
                0.0005,
                38,
                0.074,
                1.9,
                0.079,
                0.0005,
                0.0005,
                0.16,
                0.086,
                0.005,
                0.0005,
                0.086,
                0.043,
                0.0005,
                0.084,
                1.8,
                0.039,
                0.0005,
                0.0005,
                0.16,
                0.053,
                42
              ],
              [
                'GW105395',
                'GW105395',
                44939,
                'Private Bore',
                0.25,
                null,
                null,
                2333,
                2.79,
                3815,
                6.12,
                -9.6,
                17.5,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'GW105395',
                'GW105395',
                44964,
                'Private Bore',
                0.29,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'GW105395',
                'GW105395',
                44964,
                'Shallow Open Standpipe',
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                12,
                11,
                480,
                130,
                570,
                2.5,
                110,
                2.5,
                110,
                63,
                1200,
                -6,
                0.05,
                0.2,
                1,
                0.025,
                0.005,
                0.0005,
                0.079,
                0.045,
                0.0005,
                0.078,
                2,
                0.045,
                0.0005,
                0.0005,
                0.15,
                0.053,
                42,
                0.005,
                0.0005,
                0.091,
                0.037,
                0.0005,
                0.076,
                2,
                0.028,
                0.0005,
                0.0005,
                0.15,
                0.036,
                38
              ],
              [
                'GW105395',
                'GW105395',
                44964,
                'Private Bore',
                0.29,
                null,
                null,
                1483,
                2.6,
                3819,
                5.91,
                -6.2,
                17.7,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'GW105395',
                'GW105395',
                44991,
                'Private Bore',
                0.2,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'GW105395',
                'GW105395',
                44991,
                'Private Bore',
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                11,
                11,
                470,
                120,
                520,
                2.5,
                95,
                2.5,
                95,
                64,
                1200,
                -7,
                0.05,
                0.2,
                1,
                0.025,
                0.005,
                0.0005,
                0.089,
                0.052,
                0.0005,
                0.077,
                1.8,
                0.053,
                0.0005,
                0.0005,
                0.16,
                0.064,
                39,
                0.01,
                0.0005,
                0.1,
                0.041,
                0.0005,
                0.081,
                1.7,
                0.03,
                0.0005,
                0.0005,
                0.16,
                0.04,
                35
              ],
              [
                'GW105395',
                'GW105395',
                44991,
                'Private Bore',
                0.2,
                null,
                null,
                2359.5,
                3.12,
                3635,
                5.62,
                -3.1,
                17.6,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'GW105395',
                'GW105395',
                45028,
                'Private Bore',
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                12,
                10,
                500,
                130,
                580,
                2.5,
                110,
                2.5,
                110,
                72,
                1200,
                -7,
                0.05,
                0.2,
                1,
                0.025,
                0.005,
                0,
                0.082,
                0.053,
                0.0005,
                0.074,
                1.8,
                0.054,
                0.0005,
                0.0005,
                0.15,
                0.06,
                38,
                0.005,
                0.0005,
                0.081,
                0.042,
                0.0005,
                0.068,
                1.7,
                0.038,
                0.0005,
                0.0005,
                0.15,
                0.035,
                31
              ],
              [
                'GW105395',
                'GW105395',
                45030,
                'Private Bore',
                2.91,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'GW105395',
                'GW105395',
                45030,
                'Private Bore',
                2.91,
                null,
                null,
                2372.5,
                3.28,
                3646,
                5.88,
                26.7,
                17.1,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'GW105395',
                'GW105395',
                45054,
                'Private Bore',
                0.67,
                null,
                null,
                null,
                1.73,
                3886,
                5.58,
                19.7,
                16.7,
                12,
                9.6,
                490,
                130,
                550,
                2.5,
                110,
                2.5,
                110,
                83,
                1200,
                -8,
                0.05,
                0.2,
                0.5,
                0.025,
                0.005,
                0.0005,
                0.093,
                0.053,
                0.0005,
                0.082,
                1.8,
                0.05,
                0.0005,
                0.0005,
                0.17,
                0.06,
                39,
                0.02,
                0.0005,
                0.091,
                0.049,
                0.0005,
                0.084,
                2,
                0.04,
                0.0005,
                0.0005,
                0.18,
                0.037,
                37
              ],
              [
                'GW105395',
                'GW105395',
                45086,
                'Private Bore',
                0.75,
                null,
                null,
                2327,
                2.09,
                3582,
                5.84,
                58,
                17.1,
                12,
                10,
                460,
                120,
                530,
                2.5,
                120,
                2.5,
                120,
                68,
                1200,
                -11,
                0.05,
                0.2,
                0.5,
                0.025,
                0.005,
                0.0005,
                0.091,
                0.055,
                0.0005,
                0.08,
                1.9,
                0.06,
                0.0005,
                0.0005,
                0.18,
                0.07,
                40,
                0.01,
                0.0005,
                0.084,
                0.045,
                0.0005,
                0.078,
                1.9,
                0.036,
                0.0005,
                0.0005,
                0.17,
                0.047,
                38
              ],
              [
                'GW105395',
                'GW105395',
                45110,
                'Private Bore',
                0.86,
                null,
                null,
                2574,
                1.76,
                3964,
                5.76,
                5.4,
                17.1,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'GW105395',
                'GW105395',
                45112,
                'Private Bore',
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                11,
                10,
                480,
                120,
                530,
                2.5,
                120,
                2.5,
                120,
                73,
                1300,
                -13,
                0.05,
                0.2,
                3,
                0.025,
                0.005,
                0.0005,
                0.085,
                0.08,
                0.0005,
                0.084,
                2.1,
                0.088,
                0.0005,
                0.0005,
                0.19,
                0.091,
                49,
                0.005,
                0.0005,
                0.085,
                0.048,
                0.0005,
                0.088,
                2.1,
                0.043,
                0.0005,
                0.0005,
                0.19,
                0.043,
                37
              ],
              [
                'GW105385',
                'GW105395',
                45141,
                'Private Bore',
                1.04,
                null,
                null,
                2379,
                2.03,
                3655,
                5.89,
                89,
                17.5,
                11,
                9.5,
                450,
                120,
                510,
                2.5,
                120,
                2.5,
                120,
                64,
                1100,
                -6,
                0.05,
                0.2,
                1,
                0.025,
                10,
                0.0005,
                0.083,
                0.072,
                0.0005,
                0.082,
                2.2,
                0.073,
                0.0005,
                0.0005,
                0.19,
                0.075,
                43,
                0.005,
                0.0005,
                0.079,
                0.046,
                0.0005,
                0.077,
                1.9,
                0.037,
                0.0005,
                0.0005,
                0.17,
                0.044,
                37
              ],
              [
                'GW105393',
                'GW105395',
                45173,
                'Private Bore',
                1.18,
                null,
                null,
                2541,
                3.77,
                3883,
                5.83,
                -2.7,
                17.2,
                11,
                10,
                460,
                130,
                550,
                2.5,
                120,
                2.5,
                120,
                57,
                1000,
                -1,
                0.05,
                0.2,
                1,
                0.025,
                0.005,
                0.0005,
                0.089,
                0.07,
                0.0005,
                0.094,
                2.4,
                0.07,
                0.0005,
                0.0005,
                0.2,
                0.076,
                50,
                0.005,
                0.0005,
                0.091,
                0.053,
                0.0005,
                0.086,
                1.9,
                0.038,
                0.0005,
                0.0005,
                0.2,
                0.053,
                35
              ],
              [
                'GW105395',
                'GW105395',
                45202,
                null,
                1.27,
                null,
                null,
                2554,
                3.05,
                3938,
                6.09,
                -8.5,
                17.6,
                11,
                9.9,
                480,
                120,
                540,
                2.5,
                100,
                2.5,
                100,
                73,
                1300,
                -12,
                0.05,
                0.2,
                1,
                0.025,
                0.005,
                0.0005,
                0.084,
                0.096,
                0.0005,
                0.06,
                2.2,
                0.13,
                0.0005,
                0.0005,
                0.17,
                0.13,
                44,
                0.005,
                0.0005,
                0.084,
                0.057,
                0.0005,
                0.064,
                2.2,
                0.054,
                0.0005,
                0.0005,
                0.17,
                0.047,
                40
              ],
              [
                'GW105395',
                'GW105395',
                45237,
                null,
                1.45,
                null,
                null,
                2307.5,
                2.85,
                2921,
                5.9,
                -266.8,
                16.1,
                13,
                11,
                530,
                150,
                630,
                2.5,
                120,
                2.5,
                120,
                71,
                1300,
                -4,
                0.05,
                0.2,
                2,
                0.025,
                0.02,
                0.0005,
                0.084,
                0.11,
                0.0005,
                68,
                0.076,
                2.2,
                0.12,
                0.0005,
                0.0005,
                0.19,
                0.13,
                0.01,
                0.0005,
                0.081,
                0.057,
                0.0005,
                0.08,
                2.2,
                0.052,
                0.0005,
                0.0005,
                0.19,
                0.058,
                42
              ],
              [
                'GW105395',
                'GW105395',
                45266,
                null,
                1.45,
                null,
                null,
                2710,
                3.4,
                4185,
                5.85,
                -100,
                17.2,
                13,
                11,
                540,
                150,
                640,
                2.5,
                97,
                2.5,
                97,
                75,
                1300,
                -4,
                0.05,
                0.3,
                1,
                0.025,
                0.005,
                0.0005,
                0.09,
                0.09,
                0.0005,
                46,
                0.077,
                2.5,
                0.11,
                0.0005,
                0.0005,
                0.2,
                0.11,
                0.005,
                0.0005,
                0.088,
                0.057,
                0.0005,
                0.076,
                2.5,
                0.042,
                0.0005,
                0.0005,
                0.19,
                0.053,
                41
              ],
              [
                'GW105395',
                'GW105395',
                45300,
                null,
                1.27,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'GW109257',
                'GW109257',
                44846,
                'Private Bore',
                27.34,
                null,
                null,
                754,
                2.62,
                975,
                10.18,
                18,
                16.8,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'GW109257',
                'GW109257',
                44872,
                'Private Bore',
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                2,
                1,
                110,
                22,
                93,
                2.5,
                2.5,
                2.5,
                2.5,
                32,
                210,
                -1,
                0.4,
                0.1,
                2,
                0.06,
                0.48,
                0.0005,
                0.19,
                0.044,
                0.003,
                31,
                0.008,
                1.4,
                0.024,
                0.008,
                0.0005,
                0.027,
                0.1,
                0.14,
                0.0005,
                0.17,
                0.039,
                0.002,
                0.008,
                1.4,
                0.022,
                0.0005,
                0.0005,
                0.025,
                0.079,
                22
              ],
              [
                'GW105257',
                'GW109257',
                44904,
                'Private Bore',
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                1,
                1,
                100,
                18,
                80,
                2.5,
                2.5,
                2.5,
                2.5,
                5,
                210,
                2,
                0.05,
                0.2,
                7,
                0.025,
                0.45,
                0.0005,
                0.2,
                0.04,
                0.003,
                14,
                0.008,
                1.4,
                0.024,
                0.005,
                0.0005,
                0.025,
                0.092,
                0.38,
                0.0005,
                0.22,
                0.044,
                0.006,
                0.009,
                1.4,
                0.025,
                0.0005,
                0.0005,
                0.025,
                0.1,
                1.2
              ],
              [
                'GW109257',
                'GW109257',
                44904,
                'Private Bore',
                38.75,
                null,
                null,
                533,
                3.3,
                820,
                4.01,
                197.3,
                17.2,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'GW109257',
                'GW109257',
                44939,
                'Private Bore',
                36.68,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'GW109257',
                'GW109257',
                44939,
                'Private Bore',
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                2,
                1,
                110,
                22,
                93,
                2.5,
                2.5,
                2.5,
                2.5,
                2,
                230,
                1,
                0.05,
                0.1,
                0.5,
                0.025,
                0.38,
                0.0005,
                0.19,
                0.044,
                0.002,
                5.9,
                0.008,
                1.6,
                0.026,
                0.002,
                0.0005,
                0.028,
                0.094,
                0.42,
                0.0005,
                0.2,
                0.043,
                0.002,
                0.009,
                1.5,
                0.025,
                0.0005,
                0.0005,
                0.026,
                0.11,
                0.17
              ],
              [
                'GW109257',
                'GW109257',
                44939,
                'Private Bore',
                36.68,
                null,
                null,
                279.5,
                7.25,
                482.1,
                4.59,
                19.7,
                153.2,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'GW109257',
                'GW109257',
                44964,
                'Private Bore',
                38.2,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'GW109257',
                'GW109257',
                44964,
                'Private Bore',
                38.2,
                null,
                null,
                533,
                5.92,
                821,
                4.44,
                153.9,
                22.4,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'GW109257',
                'GW109257',
                44991,
                'Private Bore',
                37.2,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'GW103257',
                'GW109257',
                44991,
                'Private Bore',
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                1,
                1,
                100,
                18,
                79,
                2.5,
                2.5,
                2.5,
                2.5,
                6,
                240,
                -7,
                0.1,
                0.1,
                0.5,
                0.025,
                0.46,
                0.0005,
                0.19,
                0.038,
                0.002,
                0.008,
                1.3,
                0.021,
                0.003,
                0.0005,
                0.023,
                0.095,
                7.4,
                0.31,
                0.0005,
                0.2,
                0.037,
                0.002,
                0.009,
                1.4,
                0.021,
                0.0005,
                0.0005,
                0.025,
                0.09,
                7.3
              ],
              [
                'GW109257',
                'GW109257',
                44991,
                'Private Bore',
                37.2,
                null,
                null,
                689,
                4.35,
                1062,
                4.73,
                134.6,
                19.7,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'GW109257',
                'GW109257',
                45028,
                'Private Bore',
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                2,
                1,
                97,
                21,
                91,
                2.5,
                2.5,
                2.5,
                2.5,
                2,
                250,
                -8,
                0.05,
                0.1,
                0.5,
                0.025,
                0.42,
                0.0005,
                0.19,
                0.04,
                0.003,
                0.008,
                1.5,
                0.025,
                0.005,
                0.0005,
                0.028,
                0.11,
                15,
                0.35,
                0.0005,
                0.19,
                0.039,
                0.002,
                0.007,
                1.4,
                0.023,
                0.0005,
                0.0005,
                0.026,
                0.1,
                0.17
              ],
              [
                'GW109257',
                'GW109257',
                45030,
                'Private Bore',
                38.4,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'GW109257',
                'GW109257',
                45030,
                'Private Bore',
                38.4,
                null,
                null,
                500.5,
                1.93,
                770,
                4.41,
                112.8,
                17.2,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'GW109257',
                'GW109257',
                45054,
                'Private Bore',
                39.9,
                null,
                null,
                null,
                2.37,
                693,
                4.02,
                181.6,
                16.4,
                2,
                1,
                100,
                20,
                87,
                2.5,
                2.5,
                2.5,
                2.5,
                5,
                250,
                -6,
                0.05,
                0.1,
                0.5,
                0.025,
                0.41,
                0.0005,
                0.21,
                0.039,
                0.003,
                0.008,
                1.4,
                0.022,
                0.003,
                0.0005,
                0.026,
                0.11,
                7.6,
                0.37,
                0.0005,
                0.2,
                0.044,
                0.002,
                0.009,
                1.6,
                0.026,
                0.002,
                0.0005,
                0.028,
                0.13,
                1.6
              ],
              [
                'GW109257',
                'GW109257',
                45086,
                'Private Bore',
                37.81,
                null,
                null,
                513.5,
                2.98,
                793,
                4.21,
                162.5,
                16.9,
                2,
                1,
                110,
                19,
                84,
                2.5,
                2.5,
                2.5,
                2.5,
                3,
                250,
                -7,
                0.5,
                0.2,
                0.5,
                0.025,
                0.57,
                0.0005,
                0.22,
                0.043,
                0.004,
                0.01,
                1.5,
                0.026,
                0.01,
                0.0005,
                0.029,
                0.16,
                28,
                0.51,
                0.0005,
                0.2,
                0.04,
                0.002,
                0.008,
                1.4,
                0.024,
                0.0005,
                0.0005,
                0.028,
                0.13,
                0.03
              ],
              [
                'GW109257',
                'GW109257',
                45110,
                'Private Bore',
                32.69,
                null,
                null,
                552.5,
                2.49,
                846,
                4.44,
                264.4,
                18.6,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'GW109257',
                'GW109257',
                45112,
                'Private Bore',
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                2,
                1,
                96,
                20,
                85,
                2.5,
                2.5,
                2.5,
                2.5,
                4,
                270,
                -14,
                0.05,
                0.2,
                2,
                0.06,
                0.48,
                0.0005,
                0.19,
                0.041,
                0.003,
                0.009,
                1.6,
                0.024,
                0.009,
                0.0005,
                0.027,
                0.11,
                36,
                0.34,
                0.0005,
                0.19,
                0.04,
                0.001,
                0.009,
                1.6,
                0.023,
                0.0005,
                0.0005,
                0.025,
                0.11,
                3.1
              ],
              [
                'GW108257',
                'GW109257',
                45139,
                'Private Bore',
                39.45,
                null,
                null,
                500.5,
                3.19,
                772,
                4.27,
                7,
                17.8,
                1,
                1,
                100,
                17,
                73,
                2.5,
                2.5,
                2.5,
                2.5,
                2,
                230,
                -3,
                0.2,
                0.2,
                2,
                0.4,
                1,
                0.001,
                0.17,
                0.039,
                0.013,
                0.008,
                1.5,
                0.025,
                0.039,
                0.0005,
                0.024,
                0.13,
                140,
                0.34,
                0.0005,
                0.17,
                0.04,
                0.004,
                0.008,
                1.5,
                0.024,
                0.0005,
                0.0005,
                0.025,
                0.11,
                1
              ],
              [
                'GW109257',
                'GW109257',
                45173,
                'Private Bore',
                39.52,
                null,
                null,
                520,
                3.92,
                795,
                3.97,
                191.9,
                18.4,
                1,
                1,
                120,
                19,
                79,
                2.5,
                2.5,
                2.5,
                2.5,
                3,
                200,
                11,
                0.05,
                0.2,
                0.5,
                0.025,
                0.57,
                0.0005,
                0.2,
                0.044,
                0.01,
                0.009,
                1.4,
                0.026,
                0.008,
                0.0005,
                0.028,
                0.13,
                17,
                0.41,
                0.0005,
                0.19,
                0.04,
                0.002,
                0.009,
                1.1,
                0.023,
                0.001,
                0.0005,
                0.025,
                0.11,
                0.03
              ],
              [
                'GW109257',
                'GW109257',
                45202,
                null,
                39.99,
                null,
                null,
                539,
                9.75,
                823,
                3.93,
                90.8,
                22,
                1,
                1,
                110,
                19,
                82,
                2.5,
                2.5,
                2.5,
                2.5,
                2,
                260,
                -8,
                0.05,
                0.1,
                0.5,
                0.025,
                0.41,
                0.0005,
                0.18,
                0.041,
                0.002,
                0.007,
                1.4,
                0.024,
                0.002,
                0.0005,
                0.025,
                0.11,
                0.81,
                0.44,
                0.0005,
                0.18,
                0.04,
                0.002,
                0.007,
                1.4,
                0.024,
                0.001,
                0.0005,
                0.026,
                0.11,
                0.23
              ],
              [
                'GW109257',
                'GW109257',
                45237,
                null,
                39.9,
                null,
                null,
                297,
                3.01,
                421.2,
                4.53,
                -282,
                17.5,
                2,
                1,
                110,
                22,
                96,
                2.5,
                2.5,
                2.5,
                2.5,
                2,
                250,
                -2,
                0.05,
                0.2,
                2,
                0.025,
                0.43,
                0.0005,
                0.19,
                0.039,
                0.002,
                2.3,
                0.008,
                1.3,
                0.025,
                0.004,
                0.0005,
                0.027,
                0.11,
                0.37,
                0.0005,
                0.17,
                0.037,
                0.0005,
                0.008,
                1.2,
                0.023,
                0.001,
                0.0005,
                0.027,
                0.11,
                0.29
              ],
              [
                'GW109257',
                'GW109257',
                45264,
                null,
                39.53,
                null,
                null,
                539,
                7.82,
                827,
                4.55,
                82.4,
                18.8,
                3,
                1,
                110,
                21,
                95,
                2.5,
                6,
                2.5,
                6,
                4,
                240,
                0,
                0.05,
                0.2,
                2,
                0.025,
                0.42,
                0.0005,
                0.19,
                0.038,
                0.003,
                3.1,
                0.008,
                1.1,
                0.024,
                0.002,
                0.0005,
                0.036,
                0.11,
                0.43,
                0.0005,
                0.22,
                0.041,
                0.002,
                0.008,
                1.3,
                0.024,
                0.0005,
                0.0005,
                0.04,
                0.11,
                0.02
              ],
              [
                'GW109257',
                'GW109257',
                45300,
                null,
                39.78,
                null,
                null,
                533,
                3.25,
                825,
                4.2,
                20.8,
                19.2,
                1,
                1,
                110,
                20,
                85,
                2.5,
                2.5,
                2.5,
                2.5,
                3,
                220,
                2,
                0.05,
                0.2,
                0.5,
                0.025,
                0.49,
                0.0005,
                0.16,
                0.037,
                0.003,
                8,
                0.01,
                1.2,
                0.023,
                0.006,
                0.0005,
                0.028,
                0.12,
                0.39,
                0.0005,
                0.19,
                0.039,
                0.003,
                0.008,
                1.2,
                0.024,
                0.001,
                0.0005,
                0.026,
                0.11,
                0.06
              ],
              [
                'GW112473',
                'GW112473',
                44846,
                'Private Bore',
                47.45,
                null,
                null,
                448.5,
                1.81,
                600,
                6.08,
                33.1,
                18.1,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'GW112473',
                'GW112473',
                44872,
                'Private Bore',
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                0.9,
                0.8,
                88,
                13,
                57,
                2.5,
                2.5,
                2.5,
                2.5,
                13,
                160,
                2,
                0.9,
                0.05,
                1,
                0.025,
                0.89,
                0.002,
                0.14,
                0.017,
                0.001,
                4.7,
                0.005,
                0.82,
                0.015,
                0.008,
                0.0005,
                0.014,
                0.071,
                0.63,
                0.0005,
                0.14,
                0.016,
                0.003,
                0.005,
                0.74,
                0.014,
                0.005,
                0.0005,
                0.011,
                0.063,
                2.2
              ],
              [
                'GW112473',
                'GW112473',
                44904,
                'Private Bore',
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                1,
                0.8,
                77,
                11,
                49,
                2.5,
                9,
                2.5,
                9,
                8,
                150,
                -4,
                0.6,
                0.05,
                6,
                0.025,
                0.58,
                0.001,
                0.15,
                0.018,
                0.0005,
                20,
                0.006,
                1.3,
                0.016,
                0.009,
                0.0005,
                0.016,
                0.051,
                0.27,
                0.0005,
                0.17,
                0.018,
                0.002,
                0.006,
                1.3,
                0.016,
                0.003,
                0.0005,
                0.017,
                0.058,
                18
              ],
              [
                'GW112473',
                'GW112473',
                44904,
                'Private Bore',
                29.85,
                null,
                null,
                429,
                4.5,
                659,
                4.5,
                163.8,
                17.8,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'GW112473',
                'GW112473',
                44939,
                'Private Bore',
                26.28,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'GW112473',
                'GW112473',
                44939,
                'Private Bore',
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                2,
                0.9,
                44,
                9.6,
                44,
                2.5,
                12,
                2.5,
                12,
                4,
                110,
                -11,
                0.2,
                0.05,
                1,
                0.025,
                0.1,
                0.0005,
                0.1,
                0.013,
                0.0005,
                24,
                0.006,
                1.7,
                0.015,
                0.0005,
                0.0005,
                0.015,
                0.036,
                0.07,
                0.0005,
                0.1,
                0.014,
                0.0005,
                0.007,
                1.6,
                0.015,
                0.0005,
                0.0005,
                0.016,
                0.044,
                27
              ],
              [
                'GW112473',
                'GW112473',
                44939,
                'Private Bore',
                26.28,
                null,
                null,
                228.8,
                2.24,
                372.2,
                5.59,
                89.5,
                19,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'GW112473',
                'GW112473',
                44964,
                'Private Bore',
                67.96,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'GW112473',
                'GW112473',
                44964,
                'Private Bore',
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                2,
                0.9,
                64,
                10,
                46,
                2.5,
                30,
                2.5,
                30,
                4,
                140,
                -10,
                0.3,
                0.05,
                0.5,
                0.025,
                0.12,
                0.0005,
                0.1,
                0.013,
                0.0005,
                0.006,
                1.8,
                0.015,
                0.001,
                0.0005,
                0.014,
                0.038,
                24,
                0.1,
                0.0005,
                0.11,
                0.014,
                0.0005,
                0.006,
                1.8,
                0.014,
                0.0005,
                0.0005,
                0.015,
                0.041,
                22
              ],
              [
                'GW112473',
                'GW112473',
                44964,
                'Private Bore',
                67.96,
                null,
                null,
                331.5,
                2.56,
                510,
                5.37,
                72,
                18.6,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'GW112473',
                'GW112473',
                44991,
                'Private Bore',
                21.26,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'GW112473',
                'GW112473',
                44991,
                'Private Bore',
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                2,
                0.9,
                62,
                10,
                45,
                2.5,
                2.5,
                2.5,
                2.5,
                7,
                170,
                -14,
                0.6,
                0.05,
                0.5,
                0.025,
                0.25,
                0.0005,
                0.14,
                0.015,
                0.0005,
                0.007,
                1.4,
                0.015,
                0.002,
                0.0005,
                0.017,
                0.052,
                14,
                0.19,
                0.0005,
                0.14,
                0.016,
                0.0005,
                0.006,
                1.5,
                0.016,
                0.0005,
                0.0005,
                0.016,
                0.055,
                14
              ],
              [
                'GW112473',
                'GW112473',
                44991,
                'Private Bore',
                21.26,
                null,
                null,
                507,
                2.26,
                782,
                5.1,
                47.9,
                19.3,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'GW112473',
                'GW112473',
                45028,
                'Private Bore',
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                1,
                0.8,
                71,
                13,
                56,
                2.5,
                6,
                2.5,
                6,
                6,
                180,
                -11,
                1.2,
                0.05,
                1,
                0.025,
                0.54,
                0.0005,
                0.13,
                0.015,
                0.0005,
                0.005,
                1,
                0.014,
                0.004,
                0.0005,
                0.015,
                0.061,
                14,
                0.52,
                0.0005,
                0.14,
                0.016,
                0.0005,
                0.005,
                1.1,
                0.014,
                0.003,
                0.0005,
                0.016,
                0.062,
                9.8
              ],
              [
                'GW112473',
                'GW112473',
                45030,
                'Private Bore',
                49.92,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'GW112473',
                'GW112473',
                45030,
                'Private Bore',
                49.92,
                null,
                null,
                373.75,
                3,
                574.6,
                4.87,
                119.9,
                17.5,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'GW112473',
                'GW112473',
                45054,
                'Private Bore',
                49.15,
                null,
                null,
                null,
                1.02,
                627,
                4.32,
                211.1,
                17.8,
                1,
                0.7,
                73,
                12,
                52,
                2.5,
                2.5,
                2.5,
                2.5,
                8,
                180,
                -10,
                1.1,
                0.05,
                0.5,
                0.025,
                0.58,
                0.0005,
                0.15,
                0.015,
                0.0005,
                0.006,
                1,
                0.013,
                0.005,
                0.0005,
                0.012,
                0.055,
                9.8,
                0.56,
                0.0005,
                0.14,
                0.017,
                0.001,
                0.006,
                1.1,
                0.016,
                0.004,
                0.0005,
                0.015,
                0.058,
                9
              ],
              [
                'GW112473',
                'GW112473',
                45086,
                'Private Bore',
                49.54,
                null,
                null,
                358.5,
                1.69,
                551.7,
                4.36,
                106.9,
                17.3,
                0.9,
                0.8,
                67,
                9.4,
                41,
                2.5,
                2.5,
                2.5,
                2.5,
                5,
                150,
                -7,
                1.2,
                0.05,
                0.5,
                0.025,
                0.61,
                0.0005,
                0.11,
                0.013,
                0.001,
                0.005,
                0.69,
                0.012,
                0.006,
                0.0005,
                0.011,
                0.052,
                6.3,
                0.42,
                0.0005,
                0.11,
                0.012,
                0.0005,
                0.004,
                0.69,
                0.011,
                0.004,
                0.0005,
                0.01,
                0.052,
                4.5
              ],
              [
                'GW112473',
                'GW112473',
                45110,
                'Private Bore',
                54.75,
                null,
                null,
                416,
                1.57,
                639,
                3.92,
                320.3,
                17.8,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'GW112473',
                'GW112473',
                45112,
                'Private Bore',
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                0.9,
                0.8,
                71,
                11,
                49,
                2.5,
                2.5,
                2.5,
                2.5,
                7,
                200,
                -16,
                0.9,
                0.05,
                2,
                0.025,
                0.66,
                0.0005,
                0.13,
                0.015,
                0.0005,
                0.006,
                0.94,
                0.013,
                0.005,
                0.0005,
                0.013,
                0.061,
                6.5,
                0.58,
                0.0005,
                0.13,
                0.015,
                0.0005,
                0.006,
                0.95,
                0.014,
                0.004,
                0.0005,
                0.012,
                0.065,
                5.5
              ],
              [
                'GW112473',
                'GW112473',
                45139,
                'Private Bore',
                14.67,
                null,
                null,
                395.85,
                9.86,
                609.7,
                4.76,
                -69.8,
                14.5,
                0.9,
                0.6,
                75,
                10,
                44,
                2.5,
                2.5,
                2.5,
                2.5,
                3,
                170,
                -6,
                1.3,
                0.05,
                1,
                0.025,
                0.54,
                0.0005,
                0.13,
                0.015,
                0.0005,
                0.005,
                0.9,
                0.014,
                0.005,
                0.0005,
                0.012,
                0.06,
                3.5,
                0.48,
                0.0005,
                0.13,
                0.016,
                0.001,
                0.005,
                0.93,
                0.015,
                0.003,
                0.0005,
                0.012,
                0.064,
                3.3
              ],
              [
                'GW112473',
                'GW112473',
                45173,
                'Private Bore',
                65.93,
                null,
                null,
                308.1,
                1.82,
                473.7,
                5.26,
                91.7,
                17.9,
                2,
                1,
                53,
                8.7,
                40,
                2.5,
                41,
                2.5,
                41,
                3,
                100,
                -9,
                0.4,
                0.05,
                0.5,
                0.025,
                0.12,
                0.0005,
                0.11,
                0.014,
                0.0005,
                0.007,
                1.9,
                0.016,
                0.001,
                0.0005,
                0.019,
                0.043,
                29,
                0.1,
                0.0005,
                0.11,
                0.014,
                0.0005,
                0.008,
                1.6,
                0.015,
                0.0005,
                0.0005,
                0.018,
                0.046,
                22
              ],
              [
                'GW112473',
                'GW112473',
                45202,
                null,
                33.54,
                null,
                null,
                313.95,
                2.52,
                483.5,
                4.95,
                77.4,
                18.7,
                2,
                0.9,
                49,
                8.9,
                41,
                2.5,
                41,
                2.5,
                41,
                4,
                130,
                -22,
                0.6,
                0.05,
                0.5,
                0.025,
                0.16,
                0.0005,
                0.1,
                0.014,
                0.0005,
                0.006,
                1.7,
                0.015,
                0.001,
                0.0005,
                0.016,
                0.043,
                26,
                0.12,
                0.0005,
                0.1,
                0.014,
                0.0005,
                0.006,
                1.7,
                0.014,
                0.0005,
                0.0005,
                0.017,
                0.045,
                27
              ],
              [
                'GW112473',
                'GW112473',
                45237,
                null,
                25.06,
                null,
                null,
                254.15,
                0.005,
                327.3,
                5.45,
                -327.1,
                16.5,
                2,
                1,
                57,
                10,
                46,
                2.5,
                35,
                2.5,
                35,
                4,
                120,
                -9,
                0.4,
                0.05,
                1,
                0.025,
                0.1,
                0.0005,
                0.11,
                0.014,
                0.0005,
                26,
                0.006,
                1.7,
                0.015,
                0.0005,
                0.0005,
                0.019,
                0.043,
                0.07,
                0.0005,
                0.1,
                0.013,
                0.0005,
                0.006,
                1.7,
                0.015,
                0.0005,
                0.0005,
                0.019,
                0.062,
                26
              ],
              [
                'GW112473',
                'GW112473',
                45264,
                null,
                52.1,
                null,
                null,
                416,
                4.04,
                641,
                4.78,
                96.8,
                17.8,
                1,
                0.8,
                81,
                13,
                57,
                2.5,
                7,
                2.5,
                7,
                5,
                180,
                -6,
                1.5,
                0.05,
                2,
                0.025,
                0.49,
                0.0005,
                0.13,
                0.017,
                0.0005,
                12,
                0.005,
                1.2,
                0.016,
                0.004,
                0.0005,
                0.015,
                0.065,
                0.51,
                0.0005,
                0.15,
                0.018,
                0.001,
                0.005,
                1.2,
                0.017,
                0.002,
                0.0005,
                0.018,
                0.068,
                12
              ],
              [
                'GW112473',
                'GW112473',
                45300,
                null,
                51.88,
                null,
                null,
                4.22,
                5.44,
                651,
                4.81,
                4.8,
                18.5,
                1,
                0.9,
                83,
                13,
                55,
                2.5,
                9,
                2.5,
                9,
                5,
                160,
                0,
                1.7,
                0.05,
                0.5,
                0.025,
                0.46,
                0.0005,
                0.12,
                0.016,
                0.0005,
                17,
                0.006,
                1.2,
                0.015,
                0.005,
                0.0005,
                0.018,
                0.063,
                0.35,
                0.0005,
                0.14,
                0.017,
                0.002,
                0.005,
                1.1,
                0.016,
                0.002,
                0.0005,
                0.016,
                0.068,
                12
              ],
              [
                'P51A',
                'P51A',
                44803.375,
                'Shallow Open Standpipe',
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44803.416666666664,
                'Shallow Open Standpipe',
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44803.458333333336,
                'Shallow Open Standpipe',
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44803.5,
                'Shallow Open Standpipe',
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44803.541666666664,
                'Shallow Open Standpipe',
                null,
                13.21507837,
                298.12292163,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44803.583333333336,
                'Shallow Open Standpipe',
                null,
                13.21405865,
                298.12394135,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44803.625,
                'Shallow Open Standpipe',
                null,
                13.2138547,
                298.1241453,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44803.666666666664,
                'Shallow Open Standpipe',
                null,
                13.21334484,
                298.12465516000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44803.708333333336,
                'Shallow Open Standpipe',
                null,
                13.21283498,
                298.12516502,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44803.75,
                'Shallow Open Standpipe',
                null,
                13.21201921,
                298.12598079,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44803.791666666664,
                'Shallow Open Standpipe',
                null,
                13.21140738,
                298.12659262,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44803.833333333336,
                'Shallow Open Standpipe',
                null,
                13.21150935,
                298.12649065000005,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44803.875,
                'Shallow Open Standpipe',
                null,
                13.2113054,
                298.1266946,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44803.916666666664,
                'Shallow Open Standpipe',
                null,
                13.21110146,
                298.12689854,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44803.958333333336,
                'Shallow Open Standpipe',
                null,
                13.2105916,
                298.12740840000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44804,
                'Shallow Open Standpipe',
                null,
                13.21048963,
                298.12751037000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44804.041666666664,
                'Shallow Open Standpipe',
                null,
                13.21038766,
                298.12761234000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44804.083333333336,
                'Shallow Open Standpipe',
                null,
                13.21008174,
                298.12791826,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44804.125,
                'Shallow Open Standpipe',
                null,
                13.20997977,
                298.12802023,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44804.166666666664,
                'Shallow Open Standpipe',
                null,
                13.2098778,
                298.1281222,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44804.208333333336,
                'Shallow Open Standpipe',
                null,
                13.20916399,
                298.12883601000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44804.25,
                'Shallow Open Standpipe',
                null,
                13.2087561,
                298.1292439,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44804.291666666664,
                'Shallow Open Standpipe',
                null,
                13.2087561,
                298.1292439,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44804.333333333336,
                'Shallow Open Standpipe',
                null,
                13.20814427,
                298.12985573000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44804.375,
                'Shallow Open Standpipe',
                null,
                13.20824624,
                298.12975376,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44804.416666666664,
                'Shallow Open Standpipe',
                null,
                13.20814427,
                298.12985573000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44804.458333333336,
                'Shallow Open Standpipe',
                null,
                13.20814427,
                298.12985573000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44804.5,
                'Shallow Open Standpipe',
                null,
                13.20814427,
                298.12985573000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44804.541666666664,
                'Shallow Open Standpipe',
                null,
                13.20794033,
                298.13005967000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44804.583333333336,
                'Shallow Open Standpipe',
                null,
                13.20783836,
                298.13016164000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44804.625,
                'Shallow Open Standpipe',
                null,
                13.20783836,
                298.13016164000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44804.666666666664,
                'Shallow Open Standpipe',
                null,
                13.20722652,
                298.13077348,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44804.708333333336,
                'Shallow Open Standpipe',
                null,
                13.20722652,
                298.13077348,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44804.75,
                'Shallow Open Standpipe',
                null,
                13.20641075,
                298.13158925000005,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44804.791666666664,
                'Shallow Open Standpipe',
                null,
                13.20641075,
                298.13158925000005,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44804.833333333336,
                'Shallow Open Standpipe',
                null,
                13.20630878,
                298.13169122,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44804.875,
                'Shallow Open Standpipe',
                null,
                13.2062068,
                298.1317932,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44804.916666666664,
                'Shallow Open Standpipe',
                null,
                13.20610483,
                298.13189517,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44804.958333333336,
                'Shallow Open Standpipe',
                null,
                13.20590089,
                298.13209911,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44805,
                'Shallow Open Standpipe',
                null,
                13.20579892,
                298.13220108,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44805.041666666664,
                'Shallow Open Standpipe',
                null,
                13.20610483,
                298.13189517,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44805.083333333336,
                'Shallow Open Standpipe',
                null,
                13.20579892,
                298.13220108,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44805.125,
                'Shallow Open Standpipe',
                null,
                13.20579892,
                298.13220108,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44805.166666666664,
                'Shallow Open Standpipe',
                null,
                13.205493,
                298.13250700000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44805.208333333336,
                'Shallow Open Standpipe',
                null,
                13.20528906,
                298.13271094000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44805.25,
                'Shallow Open Standpipe',
                null,
                13.20518708,
                298.13281292000005,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44805.291666666664,
                'Shallow Open Standpipe',
                null,
                13.20498314,
                298.13301686,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44805.333333333336,
                'Shallow Open Standpipe',
                null,
                13.20467722,
                298.13332278,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44805.375,
                'Shallow Open Standpipe',
                null,
                13.20457525,
                298.13342475,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44805.416666666664,
                'Shallow Open Standpipe',
                null,
                13.20437131,
                298.13362869,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44805.458333333336,
                'Shallow Open Standpipe',
                null,
                13.20467722,
                298.13332278,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44805.5,
                'Shallow Open Standpipe',
                null,
                13.20447328,
                298.13352672,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44805.541666666664,
                'Shallow Open Standpipe',
                null,
                13.20447328,
                298.13352672,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44805.583333333336,
                'Shallow Open Standpipe',
                null,
                13.20457525,
                298.13342475,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44805.625,
                'Shallow Open Standpipe',
                null,
                13.20447328,
                298.13352672,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44805.666666666664,
                'Shallow Open Standpipe',
                null,
                13.20406539,
                298.13393461000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44805.708333333336,
                'Shallow Open Standpipe',
                null,
                13.20355553,
                298.13444447,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44805.75,
                'Shallow Open Standpipe',
                null,
                13.20335159,
                298.13464841,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44805.791666666664,
                'Shallow Open Standpipe',
                null,
                13.20284173,
                298.13515827000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44805.833333333336,
                'Shallow Open Standpipe',
                null,
                13.20273976,
                298.13526024000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44805.875,
                'Shallow Open Standpipe',
                null,
                13.20243384,
                298.13556616,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44805.916666666664,
                'Shallow Open Standpipe',
                null,
                13.20243384,
                298.13556616,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44805.958333333336,
                'Shallow Open Standpipe',
                null,
                13.20253581,
                298.13546419,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44806,
                'Shallow Open Standpipe',
                null,
                13.20202595,
                298.13597405,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44806.041666666664,
                'Shallow Open Standpipe',
                null,
                13.20202595,
                298.13597405,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44806.083333333336,
                'Shallow Open Standpipe',
                null,
                13.20212792,
                298.13587208,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44806.125,
                'Shallow Open Standpipe',
                null,
                13.20121018,
                298.13678982000005,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44806.166666666664,
                'Shallow Open Standpipe',
                null,
                13.20100623,
                298.13699377,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44806.208333333336,
                'Shallow Open Standpipe',
                null,
                13.20090426,
                298.13709574,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44806.25,
                'Shallow Open Standpipe',
                null,
                13.20070032,
                298.13729968,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44806.291666666664,
                'Shallow Open Standpipe',
                null,
                13.20070032,
                298.13729968,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44806.333333333336,
                'Shallow Open Standpipe',
                null,
                13.20019046,
                298.13780954000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44806.375,
                'Shallow Open Standpipe',
                null,
                13.20080229,
                298.13719771,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44806.416666666664,
                'Shallow Open Standpipe',
                null,
                13.2003944,
                298.13760560000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44806.458333333336,
                'Shallow Open Standpipe',
                null,
                13.20008848,
                298.13791152000005,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44806.5,
                'Shallow Open Standpipe',
                null,
                13.20008848,
                298.13791152000005,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44806.541666666664,
                'Shallow Open Standpipe',
                null,
                13.20029243,
                298.13770757000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44806.583333333336,
                'Shallow Open Standpipe',
                null,
                13.20008848,
                298.13791152000005,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44806.625,
                'Shallow Open Standpipe',
                null,
                13.1996806,
                298.1383194,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44806.666666666664,
                'Shallow Open Standpipe',
                null,
                13.19957862,
                298.13842138,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44806.708333333336,
                'Shallow Open Standpipe',
                null,
                13.19947665,
                298.13852335,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44806.75,
                'Shallow Open Standpipe',
                null,
                13.19937468,
                298.13862532,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44806.791666666664,
                'Shallow Open Standpipe',
                null,
                13.19906876,
                298.13893124000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44806.833333333336,
                'Shallow Open Standpipe',
                null,
                13.19917074,
                298.13882926,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44806.875,
                'Shallow Open Standpipe',
                null,
                13.19876285,
                298.13923715000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44806.916666666664,
                'Shallow Open Standpipe',
                null,
                13.19896679,
                298.13903321000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44806.958333333336,
                'Shallow Open Standpipe',
                null,
                13.19866088,
                298.13933912000005,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44807,
                'Shallow Open Standpipe',
                null,
                13.19866088,
                298.13933912000005,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44807.041666666664,
                'Shallow Open Standpipe',
                null,
                13.19876285,
                298.13923715000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44807.083333333336,
                'Shallow Open Standpipe',
                null,
                13.19815102,
                298.13984898,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44807.125,
                'Shallow Open Standpipe',
                null,
                13.19845693,
                298.13954307,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44807.166666666664,
                'Shallow Open Standpipe',
                null,
                13.19794707,
                298.14005293,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44807.208333333336,
                'Shallow Open Standpipe',
                null,
                13.19764116,
                298.14035884000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44807.25,
                'Shallow Open Standpipe',
                null,
                13.19804904,
                298.13995096,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44807.291666666664,
                'Shallow Open Standpipe',
                null,
                13.19753918,
                298.14046082000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44807.333333333336,
                'Shallow Open Standpipe',
                null,
                13.19733524,
                298.14066476000005,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44807.375,
                'Shallow Open Standpipe',
                null,
                13.19723327,
                298.14076673,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44807.416666666664,
                'Shallow Open Standpipe',
                null,
                13.19723327,
                298.14076673,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44807.458333333336,
                'Shallow Open Standpipe',
                null,
                13.19753918,
                298.14046082000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44807.5,
                'Shallow Open Standpipe',
                null,
                13.19682538,
                298.14117462,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44807.541666666664,
                'Shallow Open Standpipe',
                null,
                13.19723327,
                298.14076673,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44807.583333333336,
                'Shallow Open Standpipe',
                null,
                13.19723327,
                298.14076673,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44807.625,
                'Shallow Open Standpipe',
                null,
                13.19651946,
                298.14148054000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44807.666666666664,
                'Shallow Open Standpipe',
                null,
                13.19672341,
                298.14127659,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44807.708333333336,
                'Shallow Open Standpipe',
                null,
                13.19621355,
                298.14178645000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44807.75,
                'Shallow Open Standpipe',
                null,
                13.19611158,
                298.14188842000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44807.791666666664,
                'Shallow Open Standpipe',
                null,
                13.19580566,
                298.14219434,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44807.833333333336,
                'Shallow Open Standpipe',
                null,
                13.19570369,
                298.14229631,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44807.875,
                'Shallow Open Standpipe',
                null,
                13.19580566,
                298.14219434,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44807.916666666664,
                'Shallow Open Standpipe',
                null,
                13.1952958,
                298.1427042,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44807.958333333336,
                'Shallow Open Standpipe',
                null,
                13.19539777,
                298.14260223,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44808,
                'Shallow Open Standpipe',
                null,
                13.19509186,
                298.14290814000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44808.041666666664,
                'Shallow Open Standpipe',
                null,
                13.19478594,
                298.14321406000005,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44808.083333333336,
                'Shallow Open Standpipe',
                null,
                13.19509186,
                298.14290814000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44808.125,
                'Shallow Open Standpipe',
                null,
                13.19478594,
                298.14321406000005,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44808.166666666664,
                'Shallow Open Standpipe',
                null,
                13.19448002,
                298.14351998,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44808.208333333336,
                'Shallow Open Standpipe',
                null,
                13.194582,
                298.143418,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44808.25,
                'Shallow Open Standpipe',
                null,
                13.19427608,
                298.14372392,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44808.291666666664,
                'Shallow Open Standpipe',
                null,
                13.19386819,
                298.14413181000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44808.333333333336,
                'Shallow Open Standpipe',
                null,
                13.19397016,
                298.14402984000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44808.375,
                'Shallow Open Standpipe',
                null,
                13.19386819,
                298.14413181000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44808.416666666664,
                'Shallow Open Standpipe',
                null,
                13.19386819,
                298.14413181000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44808.458333333336,
                'Shallow Open Standpipe',
                null,
                13.19386819,
                298.14413181000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44808.5,
                'Shallow Open Standpipe',
                null,
                13.19386819,
                298.14413181000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44808.541666666664,
                'Shallow Open Standpipe',
                null,
                13.19376622,
                298.14423378000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44808.583333333336,
                'Shallow Open Standpipe',
                null,
                13.19356228,
                298.14443772000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44808.625,
                'Shallow Open Standpipe',
                null,
                13.19335833,
                298.14464167,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44808.666666666664,
                'Shallow Open Standpipe',
                null,
                13.19335833,
                298.14464167,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44808.708333333336,
                'Shallow Open Standpipe',
                null,
                13.19315439,
                298.14484561,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44808.75,
                'Shallow Open Standpipe',
                null,
                13.19305242,
                298.14494758,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44808.791666666664,
                'Shallow Open Standpipe',
                null,
                13.19295044,
                298.14504956,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44808.833333333336,
                'Shallow Open Standpipe',
                null,
                13.19295044,
                298.14504956,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44808.875,
                'Shallow Open Standpipe',
                null,
                13.19244058,
                298.14555942000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44808.916666666664,
                'Shallow Open Standpipe',
                null,
                13.19193072,
                298.14606928,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44808.958333333336,
                'Shallow Open Standpipe',
                null,
                13.19182875,
                298.14617125,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44809,
                'Shallow Open Standpipe',
                null,
                13.1920327,
                298.1459673,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44809.041666666664,
                'Shallow Open Standpipe',
                null,
                13.19193072,
                298.14606928,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44809.083333333336,
                'Shallow Open Standpipe',
                null,
                13.19193072,
                298.14606928,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44809.125,
                'Shallow Open Standpipe',
                null,
                13.19193072,
                298.14606928,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44809.166666666664,
                'Shallow Open Standpipe',
                null,
                13.19152284,
                298.14647716,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44809.208333333336,
                'Shallow Open Standpipe',
                null,
                13.19121692,
                298.14678308000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44809.25,
                'Shallow Open Standpipe',
                null,
                13.19111495,
                298.14688505000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44809.291666666664,
                'Shallow Open Standpipe',
                null,
                13.19060509,
                298.14739491,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44809.333333333336,
                'Shallow Open Standpipe',
                null,
                13.19050312,
                298.14749688,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44809.375,
                'Shallow Open Standpipe',
                null,
                13.19050312,
                298.14749688,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44809.416666666664,
                'Shallow Open Standpipe',
                null,
                13.19050312,
                298.14749688,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44809.458333333336,
                'Shallow Open Standpipe',
                null,
                13.1901972,
                298.1478028,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44809.5,
                'Shallow Open Standpipe',
                null,
                13.19040114,
                298.14759886,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44809.541666666664,
                'Shallow Open Standpipe',
                null,
                13.19070706,
                298.14729294,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44809.583333333336,
                'Shallow Open Standpipe',
                null,
                13.18999326,
                298.14800674,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44809.625,
                'Shallow Open Standpipe',
                null,
                13.18999326,
                298.14800674,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44809.666666666664,
                'Shallow Open Standpipe',
                null,
                13.18989128,
                298.14810872000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44809.708333333336,
                'Shallow Open Standpipe',
                null,
                13.1894834,
                298.1485166,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44809.75,
                'Shallow Open Standpipe',
                null,
                13.18938142,
                298.14861858,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44809.791666666664,
                'Shallow Open Standpipe',
                null,
                13.18927945,
                298.14872055,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44809.833333333336,
                'Shallow Open Standpipe',
                null,
                13.18897354,
                298.14902646,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44809.875,
                'Shallow Open Standpipe',
                null,
                13.18897354,
                298.14902646,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44809.916666666664,
                'Shallow Open Standpipe',
                null,
                13.18876959,
                298.14923041000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44809.958333333336,
                'Shallow Open Standpipe',
                null,
                13.18876959,
                298.14923041000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44810,
                'Shallow Open Standpipe',
                null,
                13.18876959,
                298.14923041000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44810.041666666664,
                'Shallow Open Standpipe',
                null,
                13.18856565,
                298.14943435000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44810.083333333336,
                'Shallow Open Standpipe',
                null,
                13.18846368,
                298.14953632000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44810.125,
                'Shallow Open Standpipe',
                null,
                13.18825973,
                298.14974027,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44810.166666666664,
                'Shallow Open Standpipe',
                null,
                13.18805579,
                298.14994421,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44810.208333333336,
                'Shallow Open Standpipe',
                null,
                13.18754593,
                298.15045407,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44810.25,
                'Shallow Open Standpipe',
                null,
                13.18795382,
                298.15004618,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44810.291666666664,
                'Shallow Open Standpipe',
                null,
                13.18774987,
                298.15025013,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44810.333333333336,
                'Shallow Open Standpipe',
                null,
                13.18754593,
                298.15045407,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44810.375,
                'Shallow Open Standpipe',
                null,
                13.18754593,
                298.15045407,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44810.416666666664,
                'Shallow Open Standpipe',
                null,
                13.18744396,
                298.15055604,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44810.458333333336,
                'Shallow Open Standpipe',
                null,
                13.18754593,
                298.15045407,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44810.5,
                'Shallow Open Standpipe',
                null,
                13.1876479,
                298.1503521,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44810.541666666664,
                'Shallow Open Standpipe',
                null,
                13.1876479,
                298.1503521,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44810.583333333336,
                'Shallow Open Standpipe',
                null,
                13.18744396,
                298.15055604,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44810.625,
                'Shallow Open Standpipe',
                null,
                13.18734198,
                298.15065802000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44810.666666666664,
                'Shallow Open Standpipe',
                null,
                13.18713804,
                298.15086196000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44810.708333333336,
                'Shallow Open Standpipe',
                null,
                13.18662818,
                298.15137182,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44810.75,
                'Shallow Open Standpipe',
                null,
                13.18673015,
                298.15126985,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44810.791666666664,
                'Shallow Open Standpipe',
                null,
                13.18652621,
                298.15147379,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44810.833333333336,
                'Shallow Open Standpipe',
                null,
                13.18662818,
                298.15137182,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44810.875,
                'Shallow Open Standpipe',
                null,
                13.18652621,
                298.15147379,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44810.916666666664,
                'Shallow Open Standpipe',
                null,
                13.18652621,
                298.15147379,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44810.958333333336,
                'Shallow Open Standpipe',
                null,
                13.18662818,
                298.15137182,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44811,
                'Shallow Open Standpipe',
                null,
                13.18632226,
                298.15167774,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44811.041666666664,
                'Shallow Open Standpipe',
                null,
                13.18611832,
                298.15188168000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44811.083333333336,
                'Shallow Open Standpipe',
                null,
                13.1858124,
                298.15218760000005,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44811.125,
                'Shallow Open Standpipe',
                null,
                13.18611832,
                298.15188168000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44811.166666666664,
                'Shallow Open Standpipe',
                null,
                13.1858124,
                298.15218760000005,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44811.208333333336,
                'Shallow Open Standpipe',
                null,
                13.18530254,
                298.15269746,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44811.25,
                'Shallow Open Standpipe',
                null,
                13.1850986,
                298.1529014,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44811.291666666664,
                'Shallow Open Standpipe',
                null,
                13.18499663,
                298.15300337,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44811.333333333336,
                'Shallow Open Standpipe',
                null,
                13.1850986,
                298.1529014,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44811.375,
                'Shallow Open Standpipe',
                null,
                13.18479268,
                298.15320732000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44811.416666666664,
                'Shallow Open Standpipe',
                null,
                13.18458874,
                298.15341126000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44811.458333333336,
                'Shallow Open Standpipe',
                null,
                13.18489466,
                298.15310534,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44811.5,
                'Shallow Open Standpipe',
                null,
                13.18469071,
                298.15330929000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44811.541666666664,
                'Shallow Open Standpipe',
                null,
                13.18469071,
                298.15330929000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44811.583333333336,
                'Shallow Open Standpipe',
                null,
                13.18469071,
                298.15330929000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44811.625,
                'Shallow Open Standpipe',
                null,
                13.1843848,
                298.15361520000005,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44811.666666666664,
                'Shallow Open Standpipe',
                null,
                13.18418085,
                298.15381915,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44811.708333333336,
                'Shallow Open Standpipe',
                null,
                13.18428282,
                298.15371718,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44811.75,
                'Shallow Open Standpipe',
                null,
                13.18428282,
                298.15371718,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44811.791666666664,
                'Shallow Open Standpipe',
                null,
                13.18387494,
                298.15412506,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44811.833333333336,
                'Shallow Open Standpipe',
                null,
                13.18377296,
                298.15422704,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44811.875,
                'Shallow Open Standpipe',
                null,
                13.18407888,
                298.15392112,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44811.916666666664,
                'Shallow Open Standpipe',
                null,
                13.18407888,
                298.15392112,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44811.958333333336,
                'Shallow Open Standpipe',
                null,
                13.18377296,
                298.15422704,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44812,
                'Shallow Open Standpipe',
                null,
                13.18356902,
                298.15443098000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44812.041666666664,
                'Shallow Open Standpipe',
                null,
                13.18377296,
                298.15422704,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44812.083333333336,
                'Shallow Open Standpipe',
                null,
                13.18316113,
                298.15483887000005,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44812.125,
                'Shallow Open Standpipe',
                null,
                13.18295719,
                298.15504281,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44812.166666666664,
                'Shallow Open Standpipe',
                null,
                13.18295719,
                298.15504281,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44812.208333333336,
                'Shallow Open Standpipe',
                null,
                13.18265127,
                298.15534873,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44812.25,
                'Shallow Open Standpipe',
                null,
                13.18305916,
                298.15494084,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44812.291666666664,
                'Shallow Open Standpipe',
                null,
                13.1825493,
                298.1554507,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44812.333333333336,
                'Shallow Open Standpipe',
                null,
                13.18265127,
                298.15534873,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44812.375,
                'Shallow Open Standpipe',
                null,
                13.18275324,
                298.15524676,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44812.416666666664,
                'Shallow Open Standpipe',
                null,
                13.18295719,
                298.15504281,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44812.458333333336,
                'Shallow Open Standpipe',
                null,
                13.18265127,
                298.15534873,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44812.5,
                'Shallow Open Standpipe',
                null,
                13.18244733,
                298.15555267,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44812.541666666664,
                'Shallow Open Standpipe',
                null,
                13.18316113,
                298.15483887000005,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44812.583333333336,
                'Shallow Open Standpipe',
                null,
                13.18244733,
                298.15555267,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44812.625,
                'Shallow Open Standpipe',
                null,
                13.18285522,
                298.15514478,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44812.666666666664,
                'Shallow Open Standpipe',
                null,
                13.18234536,
                298.15565464,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44812.708333333336,
                'Shallow Open Standpipe',
                null,
                13.1818355,
                298.15616450000005,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44812.75,
                'Shallow Open Standpipe',
                null,
                13.18173352,
                298.15626648,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44812.791666666664,
                'Shallow Open Standpipe',
                null,
                13.18173352,
                298.15626648,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44812.833333333336,
                'Shallow Open Standpipe',
                null,
                13.18163155,
                298.15636845,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44812.875,
                'Shallow Open Standpipe',
                null,
                13.18173352,
                298.15626648,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44812.916666666664,
                'Shallow Open Standpipe',
                null,
                13.18152958,
                298.15647042,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44812.958333333336,
                'Shallow Open Standpipe',
                null,
                13.18142761,
                298.15657239,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44813,
                'Shallow Open Standpipe',
                null,
                13.18152958,
                298.15647042,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44813.041666666664,
                'Shallow Open Standpipe',
                null,
                13.18142761,
                298.15657239,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44813.083333333336,
                'Shallow Open Standpipe',
                null,
                13.18101972,
                298.15698028,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44813.125,
                'Shallow Open Standpipe',
                null,
                13.18101972,
                298.15698028,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44813.166666666664,
                'Shallow Open Standpipe',
                null,
                13.1807138,
                298.15728620000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44813.208333333336,
                'Shallow Open Standpipe',
                null,
                13.1807138,
                298.15728620000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44813.25,
                'Shallow Open Standpipe',
                null,
                13.18081578,
                298.15718422000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44813.291666666664,
                'Shallow Open Standpipe',
                null,
                13.18020394,
                298.15779606,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44813.333333333336,
                'Shallow Open Standpipe',
                null,
                13.18050986,
                298.15749014000005,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44813.375,
                'Shallow Open Standpipe',
                null,
                13.18061183,
                298.15738817000005,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44813.416666666664,
                'Shallow Open Standpipe',
                null,
                13.18040789,
                298.15759211,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44813.458333333336,
                'Shallow Open Standpipe',
                null,
                13.18050986,
                298.15749014000005,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44813.5,
                'Shallow Open Standpipe',
                null,
                13.18020394,
                298.15779606,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44813.541666666664,
                'Shallow Open Standpipe',
                null,
                13.18,
                298.158,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44813.583333333336,
                'Shallow Open Standpipe',
                null,
                13.18,
                298.158,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44813.625,
                'Shallow Open Standpipe',
                null,
                13.17969408,
                298.15830592000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44813.666666666664,
                'Shallow Open Standpipe',
                null,
                13.17979606,
                298.15820394,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44813.708333333336,
                'Shallow Open Standpipe',
                null,
                13.17969408,
                298.15830592000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44813.75,
                'Shallow Open Standpipe',
                null,
                13.17959211,
                298.15840789000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44813.791666666664,
                'Shallow Open Standpipe',
                null,
                13.17908225,
                298.15891775,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44813.833333333336,
                'Shallow Open Standpipe',
                null,
                13.17898028,
                298.15901972,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44813.875,
                'Shallow Open Standpipe',
                null,
                13.17918422,
                298.15881578,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44813.916666666664,
                'Shallow Open Standpipe',
                null,
                13.1792862,
                298.15871380000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44813.958333333336,
                'Shallow Open Standpipe',
                null,
                13.17918422,
                298.15881578,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44814,
                'Shallow Open Standpipe',
                null,
                13.17847042,
                298.15952958,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44814.041666666664,
                'Shallow Open Standpipe',
                null,
                13.17887831,
                298.15912169,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44814.083333333336,
                'Shallow Open Standpipe',
                null,
                13.17857239,
                298.15942761,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44814.125,
                'Shallow Open Standpipe',
                null,
                13.17847042,
                298.15952958,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44814.166666666664,
                'Shallow Open Standpipe',
                null,
                13.17826648,
                298.15973352000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44814.208333333336,
                'Shallow Open Standpipe',
                null,
                13.17785859,
                298.16014141000005,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44814.25,
                'Shallow Open Standpipe',
                null,
                13.17785859,
                298.16014141000005,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44814.291666666664,
                'Shallow Open Standpipe',
                null,
                13.17775662,
                298.16024338,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44814.333333333336,
                'Shallow Open Standpipe',
                null,
                13.17765464,
                298.16034536,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44814.375,
                'Shallow Open Standpipe',
                null,
                13.1774507,
                298.1605493,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44814.416666666664,
                'Shallow Open Standpipe',
                null,
                13.17755267,
                298.16044733,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44814.458333333336,
                'Shallow Open Standpipe',
                null,
                13.17755267,
                298.16044733,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44814.5,
                'Shallow Open Standpipe',
                null,
                13.17724676,
                298.16075324,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44814.541666666664,
                'Shallow Open Standpipe',
                null,
                13.17734873,
                298.16065127,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44814.583333333336,
                'Shallow Open Standpipe',
                null,
                13.17775662,
                298.16024338,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44814.625,
                'Shallow Open Standpipe',
                null,
                13.17632901,
                298.16167099,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44814.666666666664,
                'Shallow Open Standpipe',
                null,
                13.1767369,
                298.16126310000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44814.708333333336,
                'Shallow Open Standpipe',
                null,
                13.17704281,
                298.16095719000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44814.75,
                'Shallow Open Standpipe',
                null,
                13.17632901,
                298.16167099,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44814.791666666664,
                'Shallow Open Standpipe',
                null,
                13.17602309,
                298.16197691,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44814.833333333336,
                'Shallow Open Standpipe',
                null,
                13.17622704,
                298.16177296,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44814.875,
                'Shallow Open Standpipe',
                null,
                13.17602309,
                298.16197691,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44814.916666666664,
                'Shallow Open Standpipe',
                null,
                13.17612506,
                298.16187494,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44814.958333333336,
                'Shallow Open Standpipe',
                null,
                13.17602309,
                298.16197691,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44815,
                'Shallow Open Standpipe',
                null,
                13.1756152,
                298.16238480000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44815.041666666664,
                'Shallow Open Standpipe',
                null,
                13.17541126,
                298.16258874000005,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44815.083333333336,
                'Shallow Open Standpipe',
                null,
                13.17551323,
                298.16248677000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44815.125,
                'Shallow Open Standpipe',
                null,
                13.17500337,
                298.16299663,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44815.166666666664,
                'Shallow Open Standpipe',
                null,
                13.17541126,
                298.16258874000005,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44815.208333333336,
                'Shallow Open Standpipe',
                null,
                13.17530929,
                298.16269071000005,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44815.25,
                'Shallow Open Standpipe',
                null,
                13.17510534,
                298.16289466,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44815.291666666664,
                'Shallow Open Standpipe',
                null,
                13.1749014,
                298.1630986,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44815.333333333336,
                'Shallow Open Standpipe',
                null,
                13.17459548,
                298.16340452000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44815.375,
                'Shallow Open Standpipe',
                null,
                13.17449351,
                298.16350649000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44815.416666666664,
                'Shallow Open Standpipe',
                null,
                13.17449351,
                298.16350649000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44815.458333333336,
                'Shallow Open Standpipe',
                null,
                13.17449351,
                298.16350649000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44815.5,
                'Shallow Open Standpipe',
                null,
                13.17479943,
                298.16320057,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44815.541666666664,
                'Shallow Open Standpipe',
                null,
                13.17459548,
                298.16340452000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44815.583333333336,
                'Shallow Open Standpipe',
                null,
                13.17428957,
                298.16371043000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44815.625,
                'Shallow Open Standpipe',
                null,
                13.17459548,
                298.16340452000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44815.666666666664,
                'Shallow Open Standpipe',
                null,
                13.17347379,
                298.16452621,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44815.708333333336,
                'Shallow Open Standpipe',
                null,
                13.17398365,
                298.16401635,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44815.75,
                'Shallow Open Standpipe',
                null,
                13.17347379,
                298.16452621,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44815.791666666664,
                'Shallow Open Standpipe',
                null,
                13.17326985,
                298.16473015,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44815.833333333336,
                'Shallow Open Standpipe',
                null,
                13.17347379,
                298.16452621,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44815.875,
                'Shallow Open Standpipe',
                null,
                13.17326985,
                298.16473015,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44815.916666666664,
                'Shallow Open Standpipe',
                null,
                13.17296393,
                298.16503607000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44815.958333333336,
                'Shallow Open Standpipe',
                null,
                13.17326985,
                298.16473015,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44816,
                'Shallow Open Standpipe',
                null,
                13.1730659,
                298.16493410000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44816.041666666664,
                'Shallow Open Standpipe',
                null,
                13.17337182,
                298.16462818,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44816.083333333336,
                'Shallow Open Standpipe',
                null,
                13.17286196,
                298.16513804000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44816.125,
                'Shallow Open Standpipe',
                null,
                13.17357576,
                298.16442424,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44816.166666666664,
                'Shallow Open Standpipe',
                null,
                13.17225013,
                298.16574987,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44816.208333333336,
                'Shallow Open Standpipe',
                null,
                13.1723521,
                298.1656479,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44816.25,
                'Shallow Open Standpipe',
                null,
                13.17245407,
                298.16554593,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44816.291666666664,
                'Shallow Open Standpipe',
                null,
                13.17225013,
                298.16574987,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44816.333333333336,
                'Shallow Open Standpipe',
                null,
                13.17194421,
                298.16605579000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44816.375,
                'Shallow Open Standpipe',
                null,
                13.17214816,
                298.16585184,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44816.416666666664,
                'Shallow Open Standpipe',
                null,
                13.17225013,
                298.16574987,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44816.458333333336,
                'Shallow Open Standpipe',
                null,
                13.17214816,
                298.16585184,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44816.5,
                'Shallow Open Standpipe',
                null,
                13.17245407,
                298.16554593,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44816.541666666664,
                'Shallow Open Standpipe',
                null,
                13.1723521,
                298.1656479,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44816.583333333336,
                'Shallow Open Standpipe',
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44816.625,
                'Shallow Open Standpipe',
                null,
                13.18407888,
                298.15392112,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44816.666666666664,
                'Shallow Open Standpipe',
                null,
                13.1825493,
                298.1554507,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44816.708333333336,
                'Shallow Open Standpipe',
                null,
                13.18152958,
                298.15647042,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44816.75,
                'Shallow Open Standpipe',
                null,
                13.18122366,
                298.15677634,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44816.791666666664,
                'Shallow Open Standpipe',
                null,
                13.18091775,
                298.15708225000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44816.833333333336,
                'Shallow Open Standpipe',
                null,
                13.18061183,
                298.15738817000005,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44816.875,
                'Shallow Open Standpipe',
                null,
                13.18020394,
                298.15779606,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44816.916666666664,
                'Shallow Open Standpipe',
                null,
                13.18030592,
                298.15769408,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44816.958333333336,
                'Shallow Open Standpipe',
                null,
                13.18030592,
                298.15769408,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44817,
                'Shallow Open Standpipe',
                null,
                13.17979606,
                298.15820394,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44817.041666666664,
                'Shallow Open Standpipe',
                null,
                13.17969408,
                298.15830592000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44817.083333333336,
                'Shallow Open Standpipe',
                null,
                13.17959211,
                298.15840789000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44817.125,
                'Shallow Open Standpipe',
                null,
                13.17908225,
                298.15891775,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44817.166666666664,
                'Shallow Open Standpipe',
                null,
                13.17898028,
                298.15901972,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44817.208333333336,
                'Shallow Open Standpipe',
                null,
                13.17867436,
                298.15932564,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44817.25,
                'Shallow Open Standpipe',
                null,
                13.17826648,
                298.15973352000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44817.291666666664,
                'Shallow Open Standpipe',
                null,
                13.17806253,
                298.15993747000005,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44817.333333333336,
                'Shallow Open Standpipe',
                null,
                13.17826648,
                298.15973352000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44817.375,
                'Shallow Open Standpipe',
                null,
                13.1781645,
                298.15983550000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44817.416666666664,
                'Shallow Open Standpipe',
                null,
                13.17796056,
                298.16003944000005,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44817.458333333336,
                'Shallow Open Standpipe',
                null,
                13.17826648,
                298.15973352000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44817.5,
                'Shallow Open Standpipe',
                null,
                13.17806253,
                298.15993747000005,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44817.541666666664,
                'Shallow Open Standpipe',
                null,
                13.17775662,
                298.16024338,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44817.583333333336,
                'Shallow Open Standpipe',
                null,
                13.17775662,
                298.16024338,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44817.625,
                'Shallow Open Standpipe',
                null,
                13.17775662,
                298.16024338,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44817.666666666664,
                'Shallow Open Standpipe',
                null,
                13.1774507,
                298.1605493,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44817.708333333336,
                'Shallow Open Standpipe',
                null,
                13.17714478,
                298.16085522000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44817.75,
                'Shallow Open Standpipe',
                null,
                13.17714478,
                298.16085522000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44817.791666666664,
                'Shallow Open Standpipe',
                null,
                13.17683887,
                298.16116113000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44817.833333333336,
                'Shallow Open Standpipe',
                null,
                13.17704281,
                298.16095719000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44817.875,
                'Shallow Open Standpipe',
                null,
                13.17663492,
                298.16136508,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44817.916666666664,
                'Shallow Open Standpipe',
                null,
                13.17653295,
                298.16146705,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44817.958333333336,
                'Shallow Open Standpipe',
                null,
                13.17663492,
                298.16136508,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44818,
                'Shallow Open Standpipe',
                null,
                13.1767369,
                298.16126310000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44818.041666666664,
                'Shallow Open Standpipe',
                null,
                13.17643098,
                298.16156902,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44818.083333333336,
                'Shallow Open Standpipe',
                null,
                13.17663492,
                298.16136508,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44818.125,
                'Shallow Open Standpipe',
                null,
                13.17602309,
                298.16197691,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44818.166666666664,
                'Shallow Open Standpipe',
                null,
                13.17622704,
                298.16177296,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44818.208333333336,
                'Shallow Open Standpipe',
                null,
                13.17592112,
                298.16207888,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44818.25,
                'Shallow Open Standpipe',
                null,
                13.17571718,
                298.16228282000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44818.291666666664,
                'Shallow Open Standpipe',
                null,
                13.1756152,
                298.16238480000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44818.333333333336,
                'Shallow Open Standpipe',
                null,
                13.17541126,
                298.16258874000005,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44818.375,
                'Shallow Open Standpipe',
                null,
                13.17551323,
                298.16248677000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44818.416666666664,
                'Shallow Open Standpipe',
                null,
                13.17541126,
                298.16258874000005,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44818.458333333336,
                'Shallow Open Standpipe',
                null,
                13.17551323,
                298.16248677000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44818.5,
                'Shallow Open Standpipe',
                null,
                13.17581915,
                298.16218085,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44818.541666666664,
                'Shallow Open Standpipe',
                null,
                13.17551323,
                298.16248677000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44818.583333333336,
                'Shallow Open Standpipe',
                null,
                13.1756152,
                298.16238480000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44818.625,
                'Shallow Open Standpipe',
                null,
                13.17541126,
                298.16258874000005,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44818.666666666664,
                'Shallow Open Standpipe',
                null,
                13.17530929,
                298.16269071000005,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44818.708333333336,
                'Shallow Open Standpipe',
                null,
                13.17510534,
                298.16289466,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44818.75,
                'Shallow Open Standpipe',
                null,
                13.17500337,
                298.16299663,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44818.791666666664,
                'Shallow Open Standpipe',
                null,
                13.17439154,
                298.16360846000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44818.833333333336,
                'Shallow Open Standpipe',
                null,
                13.17449351,
                298.16350649000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44818.875,
                'Shallow Open Standpipe',
                null,
                13.17469746,
                298.16330254,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44818.916666666664,
                'Shallow Open Standpipe',
                null,
                13.17439154,
                298.16360846000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44818.958333333336,
                'Shallow Open Standpipe',
                null,
                13.17428957,
                298.16371043000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44819,
                'Shallow Open Standpipe',
                null,
                13.17398365,
                298.16401635,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44819.041666666664,
                'Shallow Open Standpipe',
                null,
                13.17439154,
                298.16360846000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44819.083333333336,
                'Shallow Open Standpipe',
                null,
                13.17449351,
                298.16350649000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44819.125,
                'Shallow Open Standpipe',
                null,
                13.1741876,
                298.16381240000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44819.166666666664,
                'Shallow Open Standpipe',
                null,
                13.17408562,
                298.16391438000005,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44819.208333333336,
                'Shallow Open Standpipe',
                null,
                13.17388168,
                298.16411832,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44819.25,
                'Shallow Open Standpipe',
                null,
                13.1741876,
                298.16381240000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44819.291666666664,
                'Shallow Open Standpipe',
                null,
                13.17357576,
                298.16442424,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44819.333333333336,
                'Shallow Open Standpipe',
                null,
                13.17347379,
                298.16452621,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44819.375,
                'Shallow Open Standpipe',
                null,
                13.17377971,
                298.16422029,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44819.416666666664,
                'Shallow Open Standpipe',
                null,
                13.17367774,
                298.16432226,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44819.458333333336,
                'Shallow Open Standpipe',
                null,
                13.17398365,
                298.16401635,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44819.5,
                'Shallow Open Standpipe',
                null,
                13.17377971,
                298.16422029,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44819.541666666664,
                'Shallow Open Standpipe',
                null,
                13.17357576,
                298.16442424,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44819.583333333336,
                'Shallow Open Standpipe',
                null,
                13.17388168,
                298.16411832,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44819.625,
                'Shallow Open Standpipe',
                null,
                13.17398365,
                298.16401635,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44819.666666666664,
                'Shallow Open Standpipe',
                null,
                13.17326985,
                298.16473015,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44819.708333333336,
                'Shallow Open Standpipe',
                null,
                13.17347379,
                298.16452621,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44819.75,
                'Shallow Open Standpipe',
                null,
                13.17347379,
                298.16452621,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44819.791666666664,
                'Shallow Open Standpipe',
                null,
                13.17326985,
                298.16473015,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44819.833333333336,
                'Shallow Open Standpipe',
                null,
                13.17296393,
                298.16503607000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44819.875,
                'Shallow Open Standpipe',
                null,
                13.1730659,
                298.16493410000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44819.916666666664,
                'Shallow Open Standpipe',
                null,
                13.17296393,
                298.16503607000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44819.958333333336,
                'Shallow Open Standpipe',
                null,
                13.17275999,
                298.16524001000005,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44820,
                'Shallow Open Standpipe',
                null,
                13.17296393,
                298.16503607000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44820.041666666664,
                'Shallow Open Standpipe',
                null,
                13.1730659,
                298.16493410000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44820.083333333336,
                'Shallow Open Standpipe',
                null,
                13.17265802,
                298.16534198,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44820.125,
                'Shallow Open Standpipe',
                null,
                13.17245407,
                298.16554593,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44820.166666666664,
                'Shallow Open Standpipe',
                null,
                13.1723521,
                298.1656479,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44820.208333333336,
                'Shallow Open Standpipe',
                null,
                13.17214816,
                298.16585184,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44820.25,
                'Shallow Open Standpipe',
                null,
                13.17225013,
                298.16574987,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44820.291666666664,
                'Shallow Open Standpipe',
                null,
                13.17194421,
                298.16605579000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44820.333333333336,
                'Shallow Open Standpipe',
                null,
                13.17184224,
                298.16615776000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44820.375,
                'Shallow Open Standpipe',
                null,
                13.17174027,
                298.16625973000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44820.416666666664,
                'Shallow Open Standpipe',
                null,
                13.17184224,
                298.16615776000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44820.458333333336,
                'Shallow Open Standpipe',
                null,
                13.17174027,
                298.16625973000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44820.5,
                'Shallow Open Standpipe',
                null,
                13.17153632,
                298.16646368000005,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44820.541666666664,
                'Shallow Open Standpipe',
                null,
                13.1716383,
                298.16636170000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44820.583333333336,
                'Shallow Open Standpipe',
                null,
                13.17133238,
                298.16666762,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44820.625,
                'Shallow Open Standpipe',
                null,
                13.17153632,
                298.16646368000005,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44820.666666666664,
                'Shallow Open Standpipe',
                null,
                13.17112844,
                298.16687156,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44820.708333333336,
                'Shallow Open Standpipe',
                null,
                13.17092449,
                298.16707551,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44820.75,
                'Shallow Open Standpipe',
                null,
                13.17082252,
                298.16717748,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44820.791666666664,
                'Shallow Open Standpipe',
                null,
                13.17061858,
                298.16738142,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44820.833333333336,
                'Shallow Open Standpipe',
                null,
                13.17031266,
                298.16768734000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44820.875,
                'Shallow Open Standpipe',
                null,
                13.17041463,
                298.16758537000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44820.916666666664,
                'Shallow Open Standpipe',
                null,
                13.17041463,
                298.16758537000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44820.958333333336,
                'Shallow Open Standpipe',
                null,
                13.17021069,
                298.16778931000005,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44821,
                'Shallow Open Standpipe',
                null,
                13.17000674,
                298.16799326,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44821.041666666664,
                'Shallow Open Standpipe',
                null,
                13.17010872,
                298.16789128000005,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44821.083333333336,
                'Shallow Open Standpipe',
                null,
                13.17000674,
                298.16799326,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44821.125,
                'Shallow Open Standpipe',
                null,
                13.16970083,
                298.16829917,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44821.166666666664,
                'Shallow Open Standpipe',
                null,
                13.16949688,
                298.16850312,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44821.208333333336,
                'Shallow Open Standpipe',
                null,
                13.16919097,
                298.16880903000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44821.25,
                'Shallow Open Standpipe',
                null,
                13.16929294,
                298.16870706000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44821.291666666664,
                'Shallow Open Standpipe',
                null,
                13.16919097,
                298.16880903000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44821.333333333336,
                'Shallow Open Standpipe',
                null,
                13.169089,
                298.16891100000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44821.375,
                'Shallow Open Standpipe',
                null,
                13.16929294,
                298.16870706000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44821.416666666664,
                'Shallow Open Standpipe',
                null,
                13.16898702,
                298.16901298000005,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44821.458333333336,
                'Shallow Open Standpipe',
                null,
                13.16929294,
                298.16870706000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44821.5,
                'Shallow Open Standpipe',
                null,
                13.16888505,
                298.16911495,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44821.541666666664,
                'Shallow Open Standpipe',
                null,
                13.16939491,
                298.16860509,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44821.583333333336,
                'Shallow Open Standpipe',
                null,
                13.1679673,
                298.17003270000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44821.625,
                'Shallow Open Standpipe',
                null,
                13.16888505,
                298.16911495,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44821.666666666664,
                'Shallow Open Standpipe',
                null,
                13.16847716,
                298.16952284,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44821.708333333336,
                'Shallow Open Standpipe',
                null,
                13.16837519,
                298.16962481,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44821.75,
                'Shallow Open Standpipe',
                null,
                13.16806928,
                298.16993072,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44821.791666666664,
                'Shallow Open Standpipe',
                null,
                13.16786533,
                298.17013467000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44821.833333333336,
                'Shallow Open Standpipe',
                null,
                13.16786533,
                298.17013467000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44821.875,
                'Shallow Open Standpipe',
                null,
                13.16827322,
                298.16972678,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44821.916666666664,
                'Shallow Open Standpipe',
                null,
                13.169089,
                298.16891100000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44821.958333333336,
                'Shallow Open Standpipe',
                null,
                13.16786533,
                298.17013467000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44822,
                'Shallow Open Standpipe',
                null,
                13.16602984,
                298.17197016,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44822.041666666664,
                'Shallow Open Standpipe',
                null,
                13.16857914,
                298.16942086,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44822.083333333336,
                'Shallow Open Standpipe',
                null,
                13.16755942,
                298.17044058000005,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44822.125,
                'Shallow Open Standpipe',
                null,
                13.16817125,
                298.16982875,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44822.166666666664,
                'Shallow Open Standpipe',
                null,
                13.16755942,
                298.17044058000005,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44822.208333333336,
                'Shallow Open Standpipe',
                null,
                13.16613181,
                298.17186819,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44822.25,
                'Shallow Open Standpipe',
                null,
                13.16674364,
                298.17125636000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44822.291666666664,
                'Shallow Open Standpipe',
                null,
                13.16643772,
                298.17156228000005,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44822.333333333336,
                'Shallow Open Standpipe',
                null,
                13.1665397,
                298.17146030000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44822.375,
                'Shallow Open Standpipe',
                null,
                13.16704956,
                298.17095044,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44822.416666666664,
                'Shallow Open Standpipe',
                null,
                13.16664167,
                298.17135833000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44822.458333333336,
                'Shallow Open Standpipe',
                null,
                13.16664167,
                298.17135833000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44822.5,
                'Shallow Open Standpipe',
                null,
                13.16664167,
                298.17135833000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44822.541666666664,
                'Shallow Open Standpipe',
                null,
                13.16684561,
                298.17115439,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44822.583333333336,
                'Shallow Open Standpipe',
                null,
                13.1665397,
                298.17146030000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44822.625,
                'Shallow Open Standpipe',
                null,
                13.16602984,
                298.17197016,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44822.666666666664,
                'Shallow Open Standpipe',
                null,
                13.16602984,
                298.17197016,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44822.708333333336,
                'Shallow Open Standpipe',
                null,
                13.16572392,
                298.17227608,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44822.75,
                'Shallow Open Standpipe',
                null,
                13.16562195,
                298.17237805,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44822.791666666664,
                'Shallow Open Standpipe',
                null,
                13.16562195,
                298.17237805,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44822.833333333336,
                'Shallow Open Standpipe',
                null,
                13.16592786,
                298.17207214,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44822.875,
                'Shallow Open Standpipe',
                null,
                13.16562195,
                298.17237805,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44822.916666666664,
                'Shallow Open Standpipe',
                null,
                13.16562195,
                298.17237805,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44822.958333333336,
                'Shallow Open Standpipe',
                null,
                13.16531603,
                298.17268397000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44823,
                'Shallow Open Standpipe',
                null,
                13.16531603,
                298.17268397000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44823.041666666664,
                'Shallow Open Standpipe',
                null,
                13.16562195,
                298.17237805,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44823.083333333336,
                'Shallow Open Standpipe',
                null,
                13.16521406,
                298.17278594000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44823.125,
                'Shallow Open Standpipe',
                null,
                13.165418,
                298.17258200000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44823.166666666664,
                'Shallow Open Standpipe',
                null,
                13.16521406,
                298.17278594000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44823.208333333336,
                'Shallow Open Standpipe',
                null,
                13.16460223,
                298.17339777,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44823.25,
                'Shallow Open Standpipe',
                null,
                13.16490814,
                298.17309186,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44823.291666666664,
                'Shallow Open Standpipe',
                null,
                13.16460223,
                298.17339777,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44823.333333333336,
                'Shallow Open Standpipe',
                null,
                13.16388842,
                298.17411158000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44823.375,
                'Shallow Open Standpipe',
                null,
                13.16409237,
                298.17390763000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44823.416666666664,
                'Shallow Open Standpipe',
                null,
                13.16429631,
                298.17370369,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44823.458333333336,
                'Shallow Open Standpipe',
                null,
                13.1639904,
                298.17400960000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44823.5,
                'Shallow Open Standpipe',
                null,
                13.16439828,
                298.17360172,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44823.541666666664,
                'Shallow Open Standpipe',
                null,
                13.16439828,
                298.17360172,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44823.583333333336,
                'Shallow Open Standpipe',
                null,
                13.1639904,
                298.17400960000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44823.625,
                'Shallow Open Standpipe',
                null,
                13.16368448,
                298.17431552000005,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44823.666666666664,
                'Shallow Open Standpipe',
                null,
                13.16348054,
                298.17451946,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44823.708333333336,
                'Shallow Open Standpipe',
                null,
                13.16348054,
                298.17451946,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44823.75,
                'Shallow Open Standpipe',
                null,
                13.16317462,
                298.17482538,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44823.791666666664,
                'Shallow Open Standpipe',
                null,
                13.16317462,
                298.17482538,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44823.833333333336,
                'Shallow Open Standpipe',
                null,
                13.16256279,
                298.17543721000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44823.875,
                'Shallow Open Standpipe',
                null,
                13.16266476,
                298.17533524000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44823.916666666664,
                'Shallow Open Standpipe',
                null,
                13.16256279,
                298.17543721000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44823.958333333336,
                'Shallow Open Standpipe',
                null,
                13.16246082,
                298.17553918000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44824,
                'Shallow Open Standpipe',
                null,
                13.16225687,
                298.17574313,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44824.041666666664,
                'Shallow Open Standpipe',
                null,
                13.16246082,
                298.17553918000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44824.083333333336,
                'Shallow Open Standpipe',
                null,
                13.16235884,
                298.17564116,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44824.125,
                'Shallow Open Standpipe',
                null,
                13.16205293,
                298.17594707,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44824.166666666664,
                'Shallow Open Standpipe',
                null,
                13.16174701,
                298.17625299,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44824.208333333336,
                'Shallow Open Standpipe',
                null,
                13.16195096,
                298.17604904,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44824.25,
                'Shallow Open Standpipe',
                null,
                13.16164504,
                298.17635496,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44824.291666666664,
                'Shallow Open Standpipe',
                null,
                13.16164504,
                298.17635496,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44824.333333333336,
                'Shallow Open Standpipe',
                null,
                13.16154307,
                298.17645693000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44824.375,
                'Shallow Open Standpipe',
                null,
                13.16154307,
                298.17645693000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44824.416666666664,
                'Shallow Open Standpipe',
                null,
                13.16133912,
                298.17666088000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44824.458333333336,
                'Shallow Open Standpipe',
                null,
                13.16184898,
                298.17615102,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44824.5,
                'Shallow Open Standpipe',
                null,
                13.1621549,
                298.1758451,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44824.541666666664,
                'Shallow Open Standpipe',
                null,
                13.16133912,
                298.17666088000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44824.583333333336,
                'Shallow Open Standpipe',
                null,
                13.16123715,
                298.17676285000005,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44824.625,
                'Shallow Open Standpipe',
                null,
                13.16164504,
                298.17635496,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44824.666666666664,
                'Shallow Open Standpipe',
                null,
                13.16154307,
                298.17645693000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44824.708333333336,
                'Shallow Open Standpipe',
                null,
                13.16103321,
                298.17696679000005,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44824.75,
                'Shallow Open Standpipe',
                null,
                13.16103321,
                298.17696679000005,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44824.791666666664,
                'Shallow Open Standpipe',
                null,
                13.16103321,
                298.17696679000005,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44824.833333333336,
                'Shallow Open Standpipe',
                null,
                13.16052335,
                298.17747665,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44824.875,
                'Shallow Open Standpipe',
                null,
                13.16082926,
                298.17717074,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44824.916666666664,
                'Shallow Open Standpipe',
                null,
                13.16082926,
                298.17717074,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44824.958333333336,
                'Shallow Open Standpipe',
                null,
                13.16072729,
                298.17727271,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44825,
                'Shallow Open Standpipe',
                null,
                13.16082926,
                298.17717074,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44825.041666666664,
                'Shallow Open Standpipe',
                null,
                13.16011546,
                298.17788454000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44825.083333333336,
                'Shallow Open Standpipe',
                null,
                13.1603194,
                298.17768060000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44825.125,
                'Shallow Open Standpipe',
                null,
                13.1603194,
                298.17768060000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44825.166666666664,
                'Shallow Open Standpipe',
                null,
                13.16021743,
                298.17778257000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44825.208333333336,
                'Shallow Open Standpipe',
                null,
                13.15991152,
                298.17808848000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44825.25,
                'Shallow Open Standpipe',
                null,
                13.15980954,
                298.17819046,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44825.291666666664,
                'Shallow Open Standpipe',
                null,
                13.16011546,
                298.17788454000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44825.333333333336,
                'Shallow Open Standpipe',
                null,
                13.16001349,
                298.17798651000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44825.375,
                'Shallow Open Standpipe',
                null,
                13.15950363,
                298.17849637,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44825.416666666664,
                'Shallow Open Standpipe',
                null,
                13.16011546,
                298.17788454000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44825.458333333336,
                'Shallow Open Standpipe',
                null,
                13.15991152,
                298.17808848000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44825.5,
                'Shallow Open Standpipe',
                null,
                13.1596056,
                298.1783944,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44825.541666666664,
                'Shallow Open Standpipe',
                null,
                13.15980954,
                298.17819046,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44825.583333333336,
                'Shallow Open Standpipe',
                null,
                13.15940166,
                298.17859834,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44825.625,
                'Shallow Open Standpipe',
                null,
                13.1596056,
                298.1783944,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44825.666666666664,
                'Shallow Open Standpipe',
                null,
                13.15909574,
                298.17890426,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44825.708333333336,
                'Shallow Open Standpipe',
                null,
                13.15929968,
                298.17870032,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44825.75,
                'Shallow Open Standpipe',
                null,
                13.1588918,
                298.17910820000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44825.791666666664,
                'Shallow Open Standpipe',
                null,
                13.15919771,
                298.17880229,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44825.833333333336,
                'Shallow Open Standpipe',
                null,
                13.15909574,
                298.17890426,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44825.875,
                'Shallow Open Standpipe',
                null,
                13.1588918,
                298.17910820000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44825.916666666664,
                'Shallow Open Standpipe',
                null,
                13.15909574,
                298.17890426,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44825.958333333336,
                'Shallow Open Standpipe',
                null,
                13.15878982,
                298.17921018000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44826,
                'Shallow Open Standpipe',
                null,
                13.15858588,
                298.17941412000005,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44826.041666666664,
                'Shallow Open Standpipe',
                null,
                13.1588918,
                298.17910820000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44826.083333333336,
                'Shallow Open Standpipe',
                null,
                13.15838194,
                298.17961806,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44826.125,
                'Shallow Open Standpipe',
                null,
                13.15838194,
                298.17961806,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44826.166666666664,
                'Shallow Open Standpipe',
                null,
                13.15827996,
                298.17972004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44826.208333333336,
                'Shallow Open Standpipe',
                null,
                13.15787208,
                298.18012792,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44826.25,
                'Shallow Open Standpipe',
                null,
                13.15766813,
                298.18033187000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44826.291666666664,
                'Shallow Open Standpipe',
                null,
                13.15797405,
                298.18002595,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44826.333333333336,
                'Shallow Open Standpipe',
                null,
                13.15787208,
                298.18012792,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44826.375,
                'Shallow Open Standpipe',
                null,
                13.15766813,
                298.18033187000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44826.416666666664,
                'Shallow Open Standpipe',
                null,
                13.1570563,
                298.1809437,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44826.458333333336,
                'Shallow Open Standpipe',
                null,
                13.1577701,
                298.18022990000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44826.5,
                'Shallow Open Standpipe',
                null,
                13.1577701,
                298.18022990000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44826.541666666664,
                'Shallow Open Standpipe',
                null,
                13.15736222,
                298.18063778000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44826.583333333336,
                'Shallow Open Standpipe',
                null,
                13.15746419,
                298.18053581000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44826.625,
                'Shallow Open Standpipe',
                null,
                13.15736222,
                298.18063778000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44826.666666666664,
                'Shallow Open Standpipe',
                null,
                13.15695433,
                298.18104567,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44826.708333333336,
                'Shallow Open Standpipe',
                null,
                13.15675038,
                298.18124962,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44826.75,
                'Shallow Open Standpipe',
                null,
                13.1570563,
                298.1809437,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44826.791666666664,
                'Shallow Open Standpipe',
                null,
                13.15675038,
                298.18124962,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44826.833333333336,
                'Shallow Open Standpipe',
                null,
                13.15685236,
                298.18114764,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44826.875,
                'Shallow Open Standpipe',
                null,
                13.15675038,
                298.18124962,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44826.916666666664,
                'Shallow Open Standpipe',
                null,
                13.15654644,
                298.18145356,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44826.958333333336,
                'Shallow Open Standpipe',
                null,
                13.15644447,
                298.18155553,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44827,
                'Shallow Open Standpipe',
                null,
                13.15613855,
                298.18186145000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44827.041666666664,
                'Shallow Open Standpipe',
                null,
                13.15613855,
                298.18186145000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44827.083333333336,
                'Shallow Open Standpipe',
                null,
                13.15624052,
                298.18175948000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44827.125,
                'Shallow Open Standpipe',
                null,
                13.15593461,
                298.18206539000005,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44827.166666666664,
                'Shallow Open Standpipe',
                null,
                13.15573066,
                298.18226934,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44827.208333333336,
                'Shallow Open Standpipe',
                null,
                13.15552672,
                298.18247328,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44827.25,
                'Shallow Open Standpipe',
                null,
                13.15562869,
                298.18237131,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44827.291666666664,
                'Shallow Open Standpipe',
                null,
                13.15542475,
                298.18257525,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44827.333333333336,
                'Shallow Open Standpipe',
                null,
                13.15511883,
                298.18288117000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44827.375,
                'Shallow Open Standpipe',
                null,
                13.15532278,
                298.18267722,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44827.416666666664,
                'Shallow Open Standpipe',
                null,
                13.15552672,
                298.18247328,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44827.458333333336,
                'Shallow Open Standpipe',
                null,
                13.15491489,
                298.18308511000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44827.5,
                'Shallow Open Standpipe',
                null,
                13.15532278,
                298.18267722,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44827.541666666664,
                'Shallow Open Standpipe',
                null,
                13.15542475,
                298.18257525,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44827.583333333336,
                'Shallow Open Standpipe',
                null,
                13.15511883,
                298.18288117000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44827.625,
                'Shallow Open Standpipe',
                null,
                13.15501686,
                298.18298314000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44827.666666666664,
                'Shallow Open Standpipe',
                null,
                13.15471094,
                298.18328906000005,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44827.708333333336,
                'Shallow Open Standpipe',
                null,
                13.15460897,
                298.18339103,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44827.75,
                'Shallow Open Standpipe',
                null,
                13.15471094,
                298.18328906000005,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44827.791666666664,
                'Shallow Open Standpipe',
                null,
                13.154507,
                298.183493,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44827.833333333336,
                'Shallow Open Standpipe',
                null,
                13.15440503,
                298.18359497,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44827.875,
                'Shallow Open Standpipe',
                null,
                13.15440503,
                298.18359497,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44827.916666666664,
                'Shallow Open Standpipe',
                null,
                13.15430306,
                298.18369694,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44827.958333333336,
                'Shallow Open Standpipe',
                null,
                13.15430306,
                298.18369694,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44828,
                'Shallow Open Standpipe',
                null,
                13.15409911,
                298.18390089,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44828.041666666664,
                'Shallow Open Standpipe',
                null,
                13.15409911,
                298.18390089,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44828.083333333336,
                'Shallow Open Standpipe',
                null,
                13.15389517,
                298.18410483,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44828.125,
                'Shallow Open Standpipe',
                null,
                13.15389517,
                298.18410483,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44828.166666666664,
                'Shallow Open Standpipe',
                null,
                13.15358925,
                298.18441075000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44828.208333333336,
                'Shallow Open Standpipe',
                null,
                13.15358925,
                298.18441075000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44828.25,
                'Shallow Open Standpipe',
                null,
                13.15338531,
                298.18461469000005,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44828.291666666664,
                'Shallow Open Standpipe',
                null,
                13.15348728,
                298.18451272000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44828.333333333336,
                'Shallow Open Standpipe',
                null,
                13.15297742,
                298.18502258,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44828.375,
                'Shallow Open Standpipe',
                null,
                13.15297742,
                298.18502258,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44828.416666666664,
                'Shallow Open Standpipe',
                null,
                13.1526715,
                298.1853285,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44828.458333333336,
                'Shallow Open Standpipe',
                null,
                13.15277348,
                298.18522652,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44828.5,
                'Shallow Open Standpipe',
                null,
                13.15297742,
                298.18502258,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44828.541666666664,
                'Shallow Open Standpipe',
                null,
                13.1526715,
                298.1853285,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44828.583333333336,
                'Shallow Open Standpipe',
                null,
                13.1526715,
                298.1853285,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44828.625,
                'Shallow Open Standpipe',
                null,
                13.15226362,
                298.18573638000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44828.666666666664,
                'Shallow Open Standpipe',
                null,
                13.15256953,
                298.18543047000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44828.708333333336,
                'Shallow Open Standpipe',
                null,
                13.15175376,
                298.18624624,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44828.75,
                'Shallow Open Standpipe',
                null,
                13.15185573,
                298.18614427,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44828.791666666664,
                'Shallow Open Standpipe',
                null,
                13.15154981,
                298.18645019,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44828.833333333336,
                'Shallow Open Standpipe',
                null,
                13.15114192,
                298.18685808000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44828.875,
                'Shallow Open Standpipe',
                null,
                13.15144784,
                298.18655216,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44828.916666666664,
                'Shallow Open Standpipe',
                null,
                13.15154981,
                298.18645019,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44828.958333333336,
                'Shallow Open Standpipe',
                null,
                13.15134587,
                298.18665413,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44829,
                'Shallow Open Standpipe',
                null,
                13.15134587,
                298.18665413,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44829.041666666664,
                'Shallow Open Standpipe',
                null,
                13.15114192,
                298.18685808000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44829.083333333336,
                'Shallow Open Standpipe',
                null,
                13.15103995,
                298.18696005000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44829.125,
                'Shallow Open Standpipe',
                null,
                13.15093798,
                298.18706202000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44829.166666666664,
                'Shallow Open Standpipe',
                null,
                13.15093798,
                298.18706202000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44829.208333333336,
                'Shallow Open Standpipe',
                null,
                13.15083601,
                298.18716399000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44829.25,
                'Shallow Open Standpipe',
                null,
                13.15042812,
                298.18757188,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44829.291666666664,
                'Shallow Open Standpipe',
                null,
                13.15032615,
                298.18767385,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44829.333333333336,
                'Shallow Open Standpipe',
                null,
                13.15032615,
                298.18767385,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44829.375,
                'Shallow Open Standpipe',
                null,
                13.15032615,
                298.18767385,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44829.416666666664,
                'Shallow Open Standpipe',
                null,
                13.15032615,
                298.18767385,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44829.458333333336,
                'Shallow Open Standpipe',
                null,
                13.15042812,
                298.18757188,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44829.5,
                'Shallow Open Standpipe',
                null,
                13.15032615,
                298.18767385,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44829.541666666664,
                'Shallow Open Standpipe',
                null,
                13.1501222,
                298.1878778,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44829.583333333336,
                'Shallow Open Standpipe',
                null,
                13.1501222,
                298.1878778,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44829.625,
                'Shallow Open Standpipe',
                null,
                13.14991826,
                298.18808174000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44829.666666666664,
                'Shallow Open Standpipe',
                null,
                13.14981629,
                298.18818371000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44829.708333333336,
                'Shallow Open Standpipe',
                null,
                13.14971432,
                298.18828568000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44829.75,
                'Shallow Open Standpipe',
                null,
                13.14930643,
                298.18869357,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44829.791666666664,
                'Shallow Open Standpipe',
                null,
                13.14920446,
                298.18879554,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44829.833333333336,
                'Shallow Open Standpipe',
                null,
                13.14900051,
                298.18899949,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44829.875,
                'Shallow Open Standpipe',
                null,
                13.14920446,
                298.18879554,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44829.916666666664,
                'Shallow Open Standpipe',
                null,
                13.14920446,
                298.18879554,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44829.958333333336,
                'Shallow Open Standpipe',
                null,
                13.14910248,
                298.18889752,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44830,
                'Shallow Open Standpipe',
                null,
                13.14879657,
                298.18920343,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44830.041666666664,
                'Shallow Open Standpipe',
                null,
                13.14889854,
                298.18910146,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44830.083333333336,
                'Shallow Open Standpipe',
                null,
                13.14889854,
                298.18910146,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44830.125,
                'Shallow Open Standpipe',
                null,
                13.14859262,
                298.18940738000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44830.166666666664,
                'Shallow Open Standpipe',
                null,
                13.14879657,
                298.18920343,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44830.208333333336,
                'Shallow Open Standpipe',
                null,
                13.14828671,
                298.18971329000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44830.25,
                'Shallow Open Standpipe',
                null,
                13.14828671,
                298.18971329000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44830.291666666664,
                'Shallow Open Standpipe',
                null,
                13.14828671,
                298.18971329000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44830.333333333336,
                'Shallow Open Standpipe',
                null,
                13.14808276,
                298.18991724,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44830.375,
                'Shallow Open Standpipe',
                null,
                13.14787882,
                298.19012118,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44830.416666666664,
                'Shallow Open Standpipe',
                null,
                13.14798079,
                298.19001921,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44830.458333333336,
                'Shallow Open Standpipe',
                null,
                13.14808276,
                298.18991724,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44830.5,
                'Shallow Open Standpipe',
                null,
                13.14808276,
                298.18991724,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44830.541666666664,
                'Shallow Open Standpipe',
                null,
                13.14808276,
                298.18991724,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44830.583333333336,
                'Shallow Open Standpipe',
                null,
                13.14798079,
                298.19001921,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44830.625,
                'Shallow Open Standpipe',
                null,
                13.14787882,
                298.19012118,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44830.666666666664,
                'Shallow Open Standpipe',
                null,
                13.14777685,
                298.19022315,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44830.708333333336,
                'Shallow Open Standpipe',
                null,
                13.1475729,
                298.1904271,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44830.75,
                'Shallow Open Standpipe',
                null,
                13.14736896,
                298.19063104,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44830.791666666664,
                'Shallow Open Standpipe',
                null,
                13.1475729,
                298.1904271,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44830.833333333336,
                'Shallow Open Standpipe',
                null,
                13.14726699,
                298.19073301000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44830.875,
                'Shallow Open Standpipe',
                null,
                13.1475729,
                298.1904271,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44830.916666666664,
                'Shallow Open Standpipe',
                null,
                13.14726699,
                298.19073301000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44830.958333333336,
                'Shallow Open Standpipe',
                null,
                13.14716502,
                298.19083498000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44831,
                'Shallow Open Standpipe',
                null,
                13.14736896,
                298.19063104,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44831.041666666664,
                'Shallow Open Standpipe',
                null,
                13.14726699,
                298.19073301000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44831.083333333336,
                'Shallow Open Standpipe',
                null,
                13.14726699,
                298.19073301000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44831.125,
                'Shallow Open Standpipe',
                null,
                13.14706304,
                298.19093696000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44831.166666666664,
                'Shallow Open Standpipe',
                null,
                13.14696107,
                298.19103893000005,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44831.208333333336,
                'Shallow Open Standpipe',
                null,
                13.14655318,
                298.19144682,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44831.25,
                'Shallow Open Standpipe',
                null,
                13.14655318,
                298.19144682,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44831.291666666664,
                'Shallow Open Standpipe',
                null,
                13.14604332,
                298.19195668000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44831.333333333336,
                'Shallow Open Standpipe',
                null,
                13.14655318,
                298.19144682,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44831.375,
                'Shallow Open Standpipe',
                null,
                13.14624727,
                298.19175273,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44831.416666666664,
                'Shallow Open Standpipe',
                null,
                13.14645121,
                298.19154879,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44831.458333333336,
                'Shallow Open Standpipe',
                null,
                13.14624727,
                298.19175273,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44831.5,
                'Shallow Open Standpipe',
                null,
                13.14645121,
                298.19154879,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44831.541666666664,
                'Shallow Open Standpipe',
                null,
                13.14655318,
                298.19144682,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44831.583333333336,
                'Shallow Open Standpipe',
                null,
                13.14645121,
                298.19154879,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44831.625,
                'Shallow Open Standpipe',
                null,
                13.14624727,
                298.19175273,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44831.666666666664,
                'Shallow Open Standpipe',
                null,
                13.14553346,
                298.19246654,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44831.708333333336,
                'Shallow Open Standpipe',
                null,
                13.14543149,
                298.19256851,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44831.75,
                'Shallow Open Standpipe',
                null,
                13.14522755,
                298.19277245,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44831.791666666664,
                'Shallow Open Standpipe',
                null,
                13.14532952,
                298.19267048,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44831.833333333336,
                'Shallow Open Standpipe',
                null,
                13.14522755,
                298.19277245,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44831.875,
                'Shallow Open Standpipe',
                null,
                13.14563544,
                298.19236456000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44831.916666666664,
                'Shallow Open Standpipe',
                null,
                13.14522755,
                298.19277245,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44831.958333333336,
                'Shallow Open Standpipe',
                null,
                13.14512558,
                298.19287442,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44832,
                'Shallow Open Standpipe',
                null,
                13.14553346,
                298.19246654,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44832.041666666664,
                'Shallow Open Standpipe',
                null,
                13.14532952,
                298.19267048,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44832.083333333336,
                'Shallow Open Standpipe',
                null,
                13.14492163,
                298.19307837,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44832.125,
                'Shallow Open Standpipe',
                null,
                13.1450236,
                298.1929764,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44832.166666666664,
                'Shallow Open Standpipe',
                null,
                13.14451374,
                298.19348626000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44832.208333333336,
                'Shallow Open Standpipe',
                null,
                13.14461572,
                298.19338428000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44832.25,
                'Shallow Open Standpipe',
                null,
                13.14451374,
                298.19348626000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44832.291666666664,
                'Shallow Open Standpipe',
                null,
                13.1443098,
                298.19369020000005,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44832.333333333336,
                'Shallow Open Standpipe',
                null,
                13.14420783,
                298.19379217000005,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44832.375,
                'Shallow Open Standpipe',
                null,
                13.14420783,
                298.19379217000005,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44832.416666666664,
                'Shallow Open Standpipe',
                null,
                13.14420783,
                298.19379217000005,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44832.458333333336,
                'Shallow Open Standpipe',
                null,
                13.14451374,
                298.19348626000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44832.5,
                'Shallow Open Standpipe',
                null,
                13.14420783,
                298.19379217000005,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44832.541666666664,
                'Shallow Open Standpipe',
                null,
                13.14410586,
                298.19389414,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44832.583333333336,
                'Shallow Open Standpipe',
                null,
                13.14400388,
                298.19399612,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44832.625,
                'Shallow Open Standpipe',
                null,
                13.143596,
                298.194404,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44832.666666666664,
                'Shallow Open Standpipe',
                null,
                13.14329008,
                298.19470992000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44832.708333333336,
                'Shallow Open Standpipe',
                null,
                13.14298416,
                298.19501584,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44832.75,
                'Shallow Open Standpipe',
                null,
                13.14298416,
                298.19501584,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44832.791666666664,
                'Shallow Open Standpipe',
                null,
                13.14257628,
                298.19542372,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44832.833333333336,
                'Shallow Open Standpipe',
                null,
                13.14257628,
                298.19542372,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44832.875,
                'Shallow Open Standpipe',
                null,
                13.14257628,
                298.19542372,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44832.916666666664,
                'Shallow Open Standpipe',
                null,
                13.14257628,
                298.19542372,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44832.958333333336,
                'Shallow Open Standpipe',
                null,
                13.14267825,
                298.19532175,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44833,
                'Shallow Open Standpipe',
                null,
                13.14237233,
                298.19562767,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44833.041666666664,
                'Shallow Open Standpipe',
                null,
                13.14216839,
                298.19583161,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44833.083333333336,
                'Shallow Open Standpipe',
                null,
                13.14206642,
                298.19593358000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44833.125,
                'Shallow Open Standpipe',
                null,
                13.14186247,
                298.19613753000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44833.166666666664,
                'Shallow Open Standpipe',
                null,
                13.14165853,
                298.19634147,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44833.208333333336,
                'Shallow Open Standpipe',
                null,
                13.1417605,
                298.19623950000005,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44833.25,
                'Shallow Open Standpipe',
                null,
                13.14145458,
                298.19654542,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44833.291666666664,
                'Shallow Open Standpipe',
                null,
                13.14135261,
                298.19664739,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44833.333333333336,
                'Shallow Open Standpipe',
                null,
                13.14145458,
                298.19654542,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44833.375,
                'Shallow Open Standpipe',
                null,
                13.14135261,
                298.19664739,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44833.416666666664,
                'Shallow Open Standpipe',
                null,
                13.14135261,
                298.19664739,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44833.458333333336,
                'Shallow Open Standpipe',
                null,
                13.14135261,
                298.19664739,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44833.5,
                'Shallow Open Standpipe',
                null,
                13.14125064,
                298.19674936,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44833.541666666664,
                'Shallow Open Standpipe',
                null,
                13.14094472,
                298.19705528000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44833.583333333336,
                'Shallow Open Standpipe',
                null,
                13.14084275,
                298.19715725000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44833.625,
                'Shallow Open Standpipe',
                null,
                13.14053684,
                298.19746316000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44833.666666666664,
                'Shallow Open Standpipe',
                null,
                13.14053684,
                298.19746316000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44833.708333333336,
                'Shallow Open Standpipe',
                null,
                13.13972106,
                298.19827894,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44833.75,
                'Shallow Open Standpipe',
                null,
                13.139925,
                298.198075,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44833.791666666664,
                'Shallow Open Standpipe',
                null,
                13.13982303,
                298.19817697,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44833.833333333336,
                'Shallow Open Standpipe',
                null,
                13.13961909,
                298.19838091,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44833.875,
                'Shallow Open Standpipe',
                null,
                13.139925,
                298.198075,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44833.916666666664,
                'Shallow Open Standpipe',
                null,
                13.13972106,
                298.19827894,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44833.958333333336,
                'Shallow Open Standpipe',
                null,
                13.13972106,
                298.19827894,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44834,
                'Shallow Open Standpipe',
                null,
                13.13972106,
                298.19827894,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44834.041666666664,
                'Shallow Open Standpipe',
                null,
                13.13951712,
                298.19848288000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44834.083333333336,
                'Shallow Open Standpipe',
                null,
                13.13961909,
                298.19838091,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44834.125,
                'Shallow Open Standpipe',
                null,
                13.1392112,
                298.19878880000005,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44834.166666666664,
                'Shallow Open Standpipe',
                null,
                13.13951712,
                298.19848288000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44834.208333333336,
                'Shallow Open Standpipe',
                null,
                13.13910923,
                298.19889077000005,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44834.25,
                'Shallow Open Standpipe',
                null,
                13.13880331,
                298.19919669,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44834.291666666664,
                'Shallow Open Standpipe',
                null,
                13.13910923,
                298.19889077000005,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44834.333333333336,
                'Shallow Open Standpipe',
                null,
                13.13880331,
                298.19919669,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44834.375,
                'Shallow Open Standpipe',
                null,
                13.13859937,
                298.19940063,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44834.416666666664,
                'Shallow Open Standpipe',
                null,
                13.13859937,
                298.19940063,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44834.458333333336,
                'Shallow Open Standpipe',
                null,
                13.13859937,
                298.19940063,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44834.5,
                'Shallow Open Standpipe',
                null,
                13.13859937,
                298.19940063,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44834.541666666664,
                'Shallow Open Standpipe',
                null,
                13.1384974,
                298.1995026,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44834.583333333336,
                'Shallow Open Standpipe',
                null,
                13.1384974,
                298.1995026,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44834.625,
                'Shallow Open Standpipe',
                null,
                13.13839542,
                298.19960458,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44834.666666666664,
                'Shallow Open Standpipe',
                null,
                13.13819148,
                298.19980852000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44834.708333333336,
                'Shallow Open Standpipe',
                null,
                13.13788556,
                298.20011444,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44834.75,
                'Shallow Open Standpipe',
                null,
                13.13778359,
                298.20021641,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44834.791666666664,
                'Shallow Open Standpipe',
                null,
                13.13778359,
                298.20021641,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44834.833333333336,
                'Shallow Open Standpipe',
                null,
                13.13768162,
                298.20031838,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44834.875,
                'Shallow Open Standpipe',
                null,
                13.13768162,
                298.20031838,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44834.916666666664,
                'Shallow Open Standpipe',
                null,
                13.13757965,
                298.20042035,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44834.958333333336,
                'Shallow Open Standpipe',
                null,
                13.1373757,
                298.2006243,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44835,
                'Shallow Open Standpipe',
                null,
                13.13747768,
                298.20052232,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44835.041666666664,
                'Shallow Open Standpipe',
                null,
                13.13727373,
                298.20072627,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44835.083333333336,
                'Shallow Open Standpipe',
                null,
                13.13717176,
                298.20082824,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44835.125,
                'Shallow Open Standpipe',
                null,
                13.1373757,
                298.2006243,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44835.166666666664,
                'Shallow Open Standpipe',
                null,
                13.13686584,
                298.20113416000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44835.208333333336,
                'Shallow Open Standpipe',
                null,
                13.13686584,
                298.20113416000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44835.25,
                'Shallow Open Standpipe',
                null,
                13.1366619,
                298.20133810000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44835.291666666664,
                'Shallow Open Standpipe',
                null,
                13.13655993,
                298.20144007000005,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44835.333333333336,
                'Shallow Open Standpipe',
                null,
                13.13655993,
                298.20144007000005,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44835.375,
                'Shallow Open Standpipe',
                null,
                13.13655993,
                298.20144007000005,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44835.416666666664,
                'Shallow Open Standpipe',
                null,
                13.13645796,
                298.20154204000005,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44835.458333333336,
                'Shallow Open Standpipe',
                null,
                13.1366619,
                298.20133810000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44835.5,
                'Shallow Open Standpipe',
                null,
                13.13655993,
                298.20144007000005,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44835.541666666664,
                'Shallow Open Standpipe',
                null,
                13.13645796,
                298.20154204000005,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44835.583333333336,
                'Shallow Open Standpipe',
                null,
                13.13625401,
                298.20174599,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44835.625,
                'Shallow Open Standpipe',
                null,
                13.13635598,
                298.20164402,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44835.666666666664,
                'Shallow Open Standpipe',
                null,
                13.13645796,
                298.20154204000005,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44835.708333333336,
                'Shallow Open Standpipe',
                null,
                13.1359481,
                298.2020519,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44835.75,
                'Shallow Open Standpipe',
                null,
                13.13564218,
                298.20235782000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44835.791666666664,
                'Shallow Open Standpipe',
                null,
                13.13554021,
                298.20245979000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44835.833333333336,
                'Shallow Open Standpipe',
                null,
                13.13543824,
                298.20256176000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44835.875,
                'Shallow Open Standpipe',
                null,
                13.13543824,
                298.20256176000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44835.916666666664,
                'Shallow Open Standpipe',
                null,
                13.13543824,
                298.20256176000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44835.958333333336,
                'Shallow Open Standpipe',
                null,
                13.13523429,
                298.20276571,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44836,
                'Shallow Open Standpipe',
                null,
                13.13523429,
                298.20276571,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44836.041666666664,
                'Shallow Open Standpipe',
                null,
                13.13503035,
                298.20296965,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44836.083333333336,
                'Shallow Open Standpipe',
                null,
                13.13513232,
                298.20286768,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44836.125,
                'Shallow Open Standpipe',
                null,
                13.13523429,
                298.20276571,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44836.166666666664,
                'Shallow Open Standpipe',
                null,
                13.13492838,
                298.20307162,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44836.208333333336,
                'Shallow Open Standpipe',
                null,
                13.13452049,
                298.20347951,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44836.25,
                'Shallow Open Standpipe',
                null,
                13.13462246,
                298.20337754,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44836.291666666664,
                'Shallow Open Standpipe',
                null,
                13.13462246,
                298.20337754,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44836.333333333336,
                'Shallow Open Standpipe',
                null,
                13.13452049,
                298.20347951,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44836.375,
                'Shallow Open Standpipe',
                null,
                13.13441852,
                298.20358148,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44836.416666666664,
                'Shallow Open Standpipe',
                null,
                13.13441852,
                298.20358148,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44836.458333333336,
                'Shallow Open Standpipe',
                null,
                13.13452049,
                298.20347951,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44836.5,
                'Shallow Open Standpipe',
                null,
                13.13462246,
                298.20337754,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44836.541666666664,
                'Shallow Open Standpipe',
                null,
                13.13431654,
                298.20368346000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44836.583333333336,
                'Shallow Open Standpipe',
                null,
                13.13421457,
                298.20378543000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44836.625,
                'Shallow Open Standpipe',
                null,
                13.13390866,
                298.20409134000005,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44836.666666666664,
                'Shallow Open Standpipe',
                null,
                13.13390866,
                298.20409134000005,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44836.708333333336,
                'Shallow Open Standpipe',
                null,
                13.13360274,
                298.20439726,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44836.75,
                'Shallow Open Standpipe',
                null,
                13.13309288,
                298.20490712000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44836.791666666664,
                'Shallow Open Standpipe',
                null,
                13.13299091,
                298.20500909000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44836.833333333336,
                'Shallow Open Standpipe',
                null,
                13.13288894,
                298.20511106000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44836.875,
                'Shallow Open Standpipe',
                null,
                13.13258302,
                298.20541698,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44836.916666666664,
                'Shallow Open Standpipe',
                null,
                13.13258302,
                298.20541698,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44836.958333333336,
                'Shallow Open Standpipe',
                null,
                13.13248105,
                298.20551895,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44837,
                'Shallow Open Standpipe',
                null,
                13.1322771,
                298.2057229,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44837.041666666664,
                'Shallow Open Standpipe',
                null,
                13.13217513,
                298.20582487,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44837.083333333336,
                'Shallow Open Standpipe',
                null,
                13.1322771,
                298.2057229,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44837.125,
                'Shallow Open Standpipe',
                null,
                13.13207316,
                298.20592684,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44837.166666666664,
                'Shallow Open Standpipe',
                null,
                13.13166527,
                298.20633473000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44837.208333333336,
                'Shallow Open Standpipe',
                null,
                13.13176724,
                298.20623276000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44837.25,
                'Shallow Open Standpipe',
                null,
                13.13146133,
                298.20653867000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44837.291666666664,
                'Shallow Open Standpipe',
                null,
                13.13146133,
                298.20653867000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44837.333333333336,
                'Shallow Open Standpipe',
                null,
                13.13135936,
                298.20664064000005,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44837.375,
                'Shallow Open Standpipe',
                null,
                13.13135936,
                298.20664064000005,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44837.416666666664,
                'Shallow Open Standpipe',
                null,
                13.13135936,
                298.20664064000005,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44837.458333333336,
                'Shallow Open Standpipe',
                null,
                13.13176724,
                298.20623276000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44837.5,
                'Shallow Open Standpipe',
                null,
                13.1315633,
                298.20643670000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44837.541666666664,
                'Shallow Open Standpipe',
                null,
                13.13125738,
                298.20674262,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44837.583333333336,
                'Shallow Open Standpipe',
                null,
                13.1315633,
                298.20643670000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44837.625,
                'Shallow Open Standpipe',
                null,
                13.13115541,
                298.20684459,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44837.666666666664,
                'Shallow Open Standpipe',
                null,
                13.13115541,
                298.20684459,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44837.708333333336,
                'Shallow Open Standpipe',
                null,
                13.13095147,
                298.20704853,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44837.75,
                'Shallow Open Standpipe',
                null,
                13.13095147,
                298.20704853,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44837.791666666664,
                'Shallow Open Standpipe',
                null,
                13.13074752,
                298.20725248,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44837.833333333336,
                'Shallow Open Standpipe',
                null,
                13.13044161,
                298.20755839000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44837.875,
                'Shallow Open Standpipe',
                null,
                13.13023766,
                298.20776234000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44837.916666666664,
                'Shallow Open Standpipe',
                null,
                13.13044161,
                298.20755839000003,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44837.958333333336,
                'Shallow Open Standpipe',
                null,
                13.13023766,
                298.20776234000004,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44838,
                'Shallow Open Standpipe',
                null,
                13.13013569,
                298.20786431000005,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'P51A',
                'P51A',
                44838.041666666664,
                'Shallow Open Standpipe',
                null,
                13.13003372,
                298.20796628000005,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ]
            ]
          },
          {
            columns: [
              {
                id: 'AlternativeID',
                name: 'Alternative ID',
                type: 'string'
              },
              {
                id: 'ID',
                name: 'ID',
                type: 'string'
              },
              {
                id: 'Type',
                name: 'Type',
                type: 'string'
              },
              {
                id: 'Easting',
                name: 'Easting',
                type: 'number'
              },
              {
                id: 'Northing',
                name: 'Northing',
                type: 'number'
              },
              {
                id: 'GroundLevelmAHD',
                name: 'Ground Level (mAHD)',
                type: 'number'
              },
              {
                id: 'ToCmAHD',
                name: 'ToC (mAHD)',
                type: 'number'
              },
              {
                id: 'VWPDepthmAHD',
                name: 'VWP Depth (mAHD)',
                type: 'number'
              },
              {
                id: 'StickUp',
                name: 'Stick Up (m)',
                type: 'number'
              },
              {
                id: 'Comments',
                name: 'Comments',
                type: 'string'
              }
            ],
            data: [
              [
                null,
                'P50A',
                'Shallow Open Standpipe',
                274203.785,
                6209099.692,
                348.26,
                null,
                null,
                null,
                null
              ],
              [
                null,
                'P50C',
                'Shallow Open Standpipe',
                274181.203,
                6209095.28,
                352.033,
                null,
                null,
                null,
                null
              ],
              [
                'BARGOTRIB1A',
                'P51A',
                'Shallow Open Standpipe',
                275622.998,
                6206431.708,
                310.988,
                311.338,
                292.338,
                0.35,
                null
              ],
              [
                'BARGOTRIB1B',
                'P51B',
                'Shallow Open Standpipe',
                275620.598,
                6206419.68,
                311.842,
                312.262,
                277.762,
                0.42,
                null
              ],
              [
                'TTREE1',
                'P52',
                'Shallow Open Standpipe',
                277649.841,
                6206848.299,
                282.048,
                282.978,
                242.978,
                0.93,
                null
              ],
              [
                'TTREE2A',
                'P53A',
                'Shallow Open Standpipe',
                277649.909,
                6206496.481,
                289.028,
                289.958,
                249.95800000000003,
                0.93,
                null
              ],
              [
                'TTREE2B',
                'P53B',
                'Shallow Open Standpipe',
                277658.614,
                6206492.497,
                289.06,
                290.11,
                230.61,
                1.05,
                null
              ],
              [
                'TTREE2C',
                'P53C',
                'Shallow Open Standpipe',
                277665.796,
                6206489.232,
                289.146,
                290.146,
                211.14600000000002,
                1,
                null
              ],
              [
                'TTREE4A',
                'P54A',
                'Shallow Open Standpipe',
                277809.682,
                6205951.975,
                293.879,
                294.879,
                283.277,
                1,
                null
              ],
              [
                'TTREE4B',
                'P54B',
                'Shallow Open Standpipe',
                277806.918,
                6205944.677,
                294.06,
                294.98,
                null,
                0.92,
                null
              ],
              [
                'TTREE6A',
                'P55A',
                'Shallow Open Standpipe',
                277297.765,
                6205283.124,
                302.227,
                303.277,
                null,
                1.05,
                null
              ],
              [
                'TTREE6B',
                'P55B',
                'Shallow Open Standpipe',
                277303.322,
                6205270.964,
                302.435,
                303.485,
                null,
                1.05,
                '39.02'
              ],
              [
                'TTREE6C',
                'P55C',
                'Shallow Open Standpipe',
                277296.447,
                6205262.513,
                302.21500000000003,
                303.175,
                null,
                0.96,
                null
              ],
              [
                'TTREE7A',
                'P56A',
                'Shallow Open Standpipe',
                276645.55,
                6206175.355,
                301.546,
                302.596,
                null,
                1.05,
                null
              ],
              [
                'TTREE7B',
                'P56B',
                'Shallow Open Standpipe',
                276639.178,
                6206166.924,
                300.883,
                301.933,
                null,
                1.05,
                null
              ],
              [
                'TTREE7C',
                'P56C',
                'Shallow Open Standpipe',
                276637.064,
                6206154.374,
                300.444,
                301.404,
                227.404,
                0.96,
                '255.404'
              ],
              [
                'TTREE1-REA4',
                'REA4',
                'Shallow Open Standpipe',
                277650.772,
                6206835.256,
                283.668,
                283.618,
                null,
                -0.05,
                null
              ],
              [
                'GW062068',
                'GW062068',
                'Private Bore',
                null,
                null,
                295.51767,
                295.51767,
                null,
                0,
                null
              ],
              [
                'GW104008',
                'GW104008',
                'Private Bore',
                280367.872,
                6205979.363,
                284.297,
                284.297,
                null,
                0,
                'Surveyed 12 Jan 2024'
              ],
              [
                'GW104323',
                'GW104323',
                'Private Bore',
                279255.685,
                6203320.523,
                325.841,
                325.841,
                null,
                0,
                'Surveyed 12 Jan 2024'
              ],
              [
                'GW104659',
                'GW104659',
                'Private Bore',
                276617.689,
                6207394.512,
                293.84,
                293.84,
                null,
                0,
                'Surveyed 12 Jan 2024'
              ],
              [
                'GW105395',
                'GW105395',
                'Private Bore',
                278547.393,
                6203031.514,
                320.362,
                320.362,
                null,
                0,
                'Surveyed 12 Jan 2024'
              ],
              [
                'GW109257',
                'GW109257',
                'Private Bore',
                276601.479,
                6205057.613,
                315.321,
                315.321,
                null,
                0,
                'Surveyed 12 Jan 2024'
              ],
              [
                'GW112473',
                'GW112473',
                'Private Bore',
                276584.649,
                6202001.573,
                341.404,
                341.404,
                null,
                0,
                'Surveyed 12 Jan 2024'
              ],
              [
                'TBC009 - SWL1',
                'TBC009 (HBSS-30m)',
                'Shallow VWP',
                278511,
                6202058,
                340.22,
                340.22,
                310.22,
                null,
                null
              ],
              [
                'TBC009 - SWL2',
                'TBC009 (HBSS-75m)',
                'Shallow VWP',
                278511,
                6202058,
                340.22,
                340.22,
                265.22,
                null,
                null
              ],
              [
                'TBC009 - SWL3',
                'TBC009 (HBSS-140m)',
                'Shallow VWP',
                278511,
                6202058,
                340.22,
                340.22,
                200.22000000000003,
                null,
                null
              ],
              [
                'TBC009 - SWL4',
                'TBC009 (BHCS-182m)',
                'Shallow VWP',
                278511,
                6202058,
                340.22,
                340.22,
                157.82000000000002,
                null,
                null
              ],
              [
                'TBC009 - SWL5',
                'TBC009 (BGSS-192m)',
                'Shallow VWP',
                278511,
                6202058,
                340.22,
                340.22,
                147.52000000000004,
                null,
                null
              ],
              [
                'TBC009 - SWL6',
                'TBC009 (BGSS-332m)',
                'Deep VWP',
                278511,
                6202058,
                340.22,
                340.22,
                17.52000000000004,
                null,
                null
              ],
              [
                'TBC009 - SWL7',
                'TBC009 (BGSS-343m)',
                'Deep VWP',
                278511,
                6202058,
                340.22,
                340.22,
                -3.4799999999999613,
                null,
                null
              ],
              [
                'TBC009 - SWL8',
                'TBC009 (SBSS/WBCS-357m)',
                'Deep VWP',
                278511,
                6202058,
                340.22,
                340.22,
                -16.97999999999996,
                null,
                null
              ],
              [
                'TBC009 - SWL9',
                'TBC009 (BUSM/BUCO-381m)',
                'Deep VWP',
                278511,
                6202058,
                340.22,
                340.22,
                -41.27999999999997,
                null,
                'Constantly malfunctions at 1150 mAHD - data removed. Check being adjusted?'
              ],
              [
                'TBC009 - SWL10',
                'TBC009 (WWCO-391m)',
                'Deep VWP',
                278511,
                6202058,
                340.22,
                340.22,
                -51.579999999999984,
                null,
                null
              ],
              [
                'TBC009 - SWL11',
                'TBC009 (WWCO-397m)',
                'Deep VWP',
                278511,
                6202058,
                340.22,
                340.22,
                -57.27999999999997,
                null,
                null
              ],
              [
                'TBC018 - SWL1',
                'TBC018 (WWFM/HBSS-70m)',
                'Shallow VWP',
                279645,
                6204509,
                307.7,
                307.7,
                237.7,
                null,
                'Inactive'
              ],
              [
                'TBC018 - SWL2',
                'TBC018 (WWFM/HBSS-117m)',
                'Shallow VWP',
                279645,
                6204509,
                307.7,
                307.7,
                190.39999999999998,
                null,
                null
              ],
              [
                'TBC018 - SWL3',
                'TBC018 (HBSS-164m)',
                'Shallow VWP',
                279645,
                6204509,
                307.7,
                307.7,
                143.1,
                null,
                null
              ],
              [
                'TBC018 - SWL4',
                'TBC018 (BHCS-179m)',
                'Shallow VWP',
                279645,
                6204509,
                307.7,
                307.7,
                128.7,
                null,
                null
              ],
              [
                'TBC018 - SWL5',
                'TBC018 (BGSS-198m)',
                'Shallow VWP',
                279645,
                6204509,
                307.7,
                307.7,
                109.69999999999999,
                null,
                null
              ],
              [
                'TBC018 - SWL6',
                'TBC018 (BGSS-282m)',
                'Deep VWP',
                279645,
                6204509,
                307.7,
                307.7,
                25.69999999999999,
                null,
                null
              ],
              [
                'TBC018 - SWL7',
                'TBC018 (BGSS-366m)',
                'Deep VWP',
                279645,
                6204509,
                307.7,
                307.7,
                -58.30000000000001,
                null,
                null
              ],
              [
                'TBC018 - SWL8',
                'TBC018 (WBCS-377m)',
                'Deep VWP',
                279645,
                6204509,
                307.7,
                307.7,
                -70.19999999999999,
                null,
                null
              ],
              [
                'TBC018 - SWL9',
                'TBC018 (BUSM/BUCO-404m)',
                'Deep VWP',
                279645,
                6204509,
                307.7,
                307.7,
                -96.90000000000003,
                null,
                null
              ],
              [
                'TBC018 - SWL10',
                'TBC018 (WWCO-426m)',
                'Deep VWP',
                279645,
                6204509,
                307.7,
                307.7,
                -118.80000000000001,
                null,
                null
              ],
              [
                'TBC018 - SWL11',
                'TBC018 (WWCO-432m)',
                'Deep VWP',
                279645,
                6204509,
                307.7,
                307.7,
                -124.80000000000001,
                null,
                null
              ],
              [
                'TBC020 - VWP1',
                'TBC020 (HBSS-70m)',
                'Shallow VWP',
                280909,
                6204059,
                297.7,
                297.7,
                227.7,
                null,
                null
              ],
              [
                'TBC020 - VWP2',
                'TBC020 (HBSS-105m)',
                'Shallow VWP',
                280909,
                6204059,
                297.7,
                297.7,
                192.2,
                null,
                null
              ],
              [
                'TBC020 - VWP3',
                'TBC020 (HBSS-141m)',
                'Shallow VWP',
                280909,
                6204059,
                297.7,
                297.7,
                156.7,
                null,
                null
              ],
              [
                'TBC020 - VWP4',
                'TBC020 (BHCS-194m)',
                'Shallow VWP',
                280909,
                6204059,
                297.7,
                297.7,
                103.19999999999999,
                null,
                null
              ],
              [
                'TBC020 - VWP5',
                'TBC020 (BGSS-211m)',
                'Deep VWP',
                280909,
                6204059,
                297.7,
                297.7,
                86.69999999999999,
                null,
                null
              ],
              [
                'TBC020 - VWP6',
                'TBC020 (BGSS-293m)',
                'Deep VWP',
                280909,
                6204059,
                297.7,
                297.7,
                4.699999999999989,
                null,
                null
              ],
              [
                'TBC020 - VWP7',
                'TBC020 (BGSS-375m)',
                'Deep VWP',
                280909,
                6204059,
                297.7,
                297.7,
                -77.30000000000001,
                null,
                null
              ],
              [
                'TBC020 - VWP8',
                'TBC020 (WBCS-397m)',
                'Deep VWP',
                280909,
                6204059,
                297.7,
                297.7,
                -99.85000000000002,
                null,
                'Constantly malfunctions at 1500 mAHD - data removed. Check being adjusted?'
              ],
              [
                'TBC020 - VWP9',
                'TBC020 (BGSS/BUSM-411m)',
                'Deep VWP',
                280909,
                6204059,
                297.7,
                297.7,
                -113.695,
                null,
                null
              ],
              [
                'TBC020 - VWP10',
                'TBC020 (WWCO-434m)',
                'Deep VWP',
                280909,
                6204059,
                297.7,
                297.7,
                -136.75,
                null,
                null
              ],
              [
                'TBC020 - VWP11',
                'TBC020 (WWCO-439m)',
                'Deep VWP',
                280909,
                6204059,
                297.7,
                297.7,
                -141.45,
                null,
                null
              ],
              [
                'TBC024 - SWL1',
                'TBC024 (HBSS-117m)',
                'Shallow VWP',
                274763,
                6204163,
                357,
                357,
                240,
                null,
                null
              ],
              [
                'TBC024 - SWL2',
                'TBC024 (HBSS-139m)',
                'Shallow VWP',
                274763,
                6204163,
                357,
                357,
                218,
                null,
                null
              ],
              [
                'TBC024 - SWL3',
                'TBC024 (BHCS-168m)',
                'Shallow VWP',
                274763,
                6204163,
                357,
                357,
                188.5,
                null,
                null
              ],
              [
                'TBC024 - SWL4',
                'TBC024 (BGSS-185m)',
                'Shallow VWP',
                274763,
                6204163,
                357,
                357,
                172,
                null,
                null
              ],
              [
                'TBC024 - SWL5',
                'TBC024 (BGSS-240m)',
                'Deep VWP',
                274763,
                6204163,
                357,
                357,
                116.9,
                null,
                null
              ],
              [
                'TBC024 - SWL6',
                'TBC024 (BGSS-295m)',
                'Deep VWP',
                274763,
                6204163,
                357,
                357,
                61.80000000000001,
                null,
                null
              ],
              [
                'TBC024 - SWL7',
                'TBC024 (WBCS-350m)',
                'Deep VWP',
                274763,
                6204163,
                357,
                357,
                6.699999999999989,
                null,
                null
              ],
              [
                'TBC024 - SWL8',
                'TBC024 (BUCO-371m)',
                'Deep VWP',
                274763,
                6204163,
                357,
                357,
                -14.5,
                null,
                null
              ],
              [
                'TBC024 - SWL9',
                'TBC024 (WWCO-384m)',
                'Deep VWP',
                274763,
                6204163,
                357,
                357,
                -27.100000000000023,
                null,
                null
              ],
              [
                'TBC024 - SWL10',
                'TBC024 (WWCO-391m)',
                'Deep VWP',
                274763,
                6204163,
                357,
                357,
                -34.60000000000002,
                null,
                null
              ],
              [
                'TBC026 - SWL1',
                'TBC026 (HBSS-95m)',
                'Shallow VWP',
                281603,
                6207068,
                236.8,
                236.8,
                141.8,
                null,
                null
              ],
              [
                'TBC026 - SWL2',
                'TBC026 (HBSS-135m)',
                'Shallow VWP',
                281603,
                6207068,
                236.8,
                236.8,
                101,
                null,
                null
              ],
              [
                'TBC026 - SWL3',
                'TBC026 (HBSS-176m)',
                'Shallow VWP',
                281603,
                6207068,
                236.8,
                236.8,
                60.20000000000002,
                null,
                null
              ],
              [
                'TBC026 - SWL4',
                'TBC026 (BHCS-191m)',
                'Shallow VWP',
                281603,
                6207068,
                236.8,
                236.8,
                45.20000000000002,
                null,
                null
              ],
              [
                'TBC026 - SWL5',
                'TBC026 (BGSS-211m)',
                'Deep VWP',
                281603,
                6207068,
                236.8,
                236.8,
                25.200000000000017,
                null,
                null
              ],
              [
                'TBC026 - SWL6',
                'TBC026 (BGSS-278m)',
                'Deep VWP',
                281603,
                6207068,
                236.8,
                236.8,
                -41.30000000000001,
                null,
                null
              ],
              [
                'TBC026 - SWL7',
                'TBC026 (BGSS-344m)',
                'Deep VWP',
                281603,
                6207068,
                236.8,
                236.8,
                -107.80000000000001,
                null,
                null
              ],
              [
                'TBC026 - SWL8',
                'TBC026 (WBCS-409m)',
                'Deep VWP',
                281603,
                6207068,
                236.8,
                236.8,
                -172.2,
                null,
                null
              ],
              [
                'TBC026 - SWL9',
                'TBC026 (BUSM-432m)',
                'Deep VWP',
                281603,
                6207068,
                236.8,
                236.8,
                -195.3,
                null,
                null
              ],
              [
                'TBC026 - SWL10',
                'TBC026 (WWCO-440m)',
                'Deep VWP',
                281603,
                6207068,
                236.8,
                236.8,
                -203.2,
                null,
                null
              ],
              [
                'TBC026 - SWL11',
                'TBC026 (LDSS-460m)',
                'Deep VWP',
                281603,
                6207068,
                236.8,
                236.8,
                -223.2,
                null,
                'Constantly malfunctions at 4500 mAHD - data removed. Check being adjusted?'
              ],
              [
                'TBC027 - SWL1',
                'TBC027 (HBSS-95m)',
                'Shallow VWP',
                275708,
                6202210,
                360.9,
                360.9,
                265.9,
                null,
                null
              ],
              [
                'TBC027 - SWL2',
                'TBC027 (HBSS-132m)',
                'Shallow VWP',
                275708,
                6202210,
                360.9,
                360.9,
                228.89999999999998,
                null,
                null
              ],
              [
                'TBC027 - SWL3',
                'TBC027 (HBSS-169m)',
                'Shallow VWP',
                275708,
                6202210,
                360.9,
                360.9,
                191.89999999999998,
                null,
                null
              ],
              [
                'TBC027 - SWL4',
                'TBC027 (BHCS-181m)',
                'Shallow VWP',
                275708,
                6202210,
                360.9,
                360.9,
                179.89999999999998,
                null,
                null
              ],
              [
                'TBC027 - SWL5',
                'TBC027 (BGSS-198m)',
                'Shallow VWP',
                275708,
                6202210,
                360.9,
                360.9,
                162.89999999999998,
                null,
                null
              ],
              [
                'TBC027 - SWL6',
                'TBC027 (BGSS-253m)',
                'Shallow VWP',
                275708,
                6202210,
                360.9,
                360.9,
                107.89999999999998,
                null,
                null
              ],
              [
                'TBC027 - SWL7',
                'TBC027 (BGSS-306m)',
                'Deep VWP',
                275708,
                6202210,
                360.9,
                360.9,
                54.89999999999998,
                null,
                null
              ],
              [
                'TBC027 - SWL8',
                'TBC027 (WBCS-362m)',
                'Deep VWP',
                275708,
                6202210,
                360.9,
                360.9,
                -2,
                null,
                null
              ],
              [
                'TBC027 - SWL9',
                'TBC027 (BUCO-384m)',
                'Deep VWP',
                275708,
                6202210,
                360.9,
                360.9,
                -23.80000000000001,
                null,
                null
              ],
              [
                'TBC027 - SWL10',
                'TBC027 (WWCO-396m)',
                'Deep VWP',
                275708,
                6202210,
                360.9,
                360.9,
                -35.10000000000002,
                null,
                null
              ],
              [
                'TBC027 - SWL11',
                'TBC027 (WWCO-400m)',
                'Deep VWP',
                275708,
                6202210,
                360.9,
                360.9,
                -40,
                null,
                null
              ],
              [
                'TBC032 - SWL1',
                'TBC032 (HBSS-95m)',
                'Shallow VWP',
                277244,
                6204725,
                312.3,
                312.3,
                217.3,
                null,
                null
              ],
              [
                'TBC032 - SWL2',
                'TBC032 (HBSS-131m)',
                'Shallow VWP',
                277244,
                6204725,
                312.3,
                312.3,
                180.8,
                null,
                null
              ],
              [
                'TBC032 - SWL3',
                'TBC032 (HBSS-168m)',
                'Shallow VWP',
                277244,
                6204725,
                312.3,
                312.3,
                144.3,
                null,
                null
              ],
              [
                'TBC032 - SWL4',
                'TBC032 (BHCS-181m)',
                'Shallow VWP',
                277244,
                6204725,
                312.3,
                312.3,
                130.8,
                null,
                null
              ],
              [
                'TBC032 - SWL5',
                'TBC032 (BGSS-200m)',
                'Shallow VWP',
                277244,
                6204725,
                312.3,
                312.3,
                112.30000000000001,
                null,
                null
              ],
              [
                'TBC032 - SWL6',
                'TBC032 (BGSS-237m)',
                'Deep VWP',
                277244,
                6204725,
                312.3,
                312.3,
                75.10000000000002,
                null,
                null
              ],
              [
                'TBC032 - SWL7',
                'TBC032 (BGSS-294m)',
                'Deep VWP',
                277244,
                6204725,
                312.3,
                312.3,
                17.900000000000034,
                null,
                null
              ],
              [
                'TBC032 - SWL8',
                'TBC032 (BGSS-372m)',
                'Deep VWP',
                277244,
                6204725,
                312.3,
                312.3,
                -59.30000000000001,
                null,
                null
              ],
              [
                'TBC032 - SWL9',
                'TBC032 (BUCO-398m)',
                'Deep VWP',
                277244,
                6204725,
                312.3,
                312.3,
                -85.5,
                null,
                'Malfuncitoning at start - seems to have stabilised'
              ],
              [
                'TBC032 - SWL10',
                'TBC032 (WWCO-437m)',
                'Deep VWP',
                277244,
                6204725,
                312.3,
                312.3,
                -124.69999999999999,
                null,
                null
              ],
              [
                'TBC034 - SWL1',
                'TBC034 (HBSS-65m)',
                'Shallow VWP',
                272956,
                6205076,
                405,
                405,
                366.4,
                null,
                'Inactive'
              ],
              [
                'TBC034 - SWL2',
                'TBC034 (HBSS-113m)',
                'Shallow VWP',
                272956,
                6205076,
                405,
                405,
                318.4,
                null,
                'Inactive - Malfunctions'
              ],
              [
                'TBC034 - SWL3',
                'TBC034 (HBSS-161m)',
                'Shallow VWP',
                272956,
                6205076,
                405,
                405,
                270.4,
                null,
                'Inactive'
              ],
              [
                'TBC034 - SWL4',
                'TBC034 (BHCS-176m)',
                'Shallow VWP',
                272956,
                6205076,
                405,
                405,
                255.39999999999998,
                null,
                null
              ],
              [
                'TBC034 - SWL5',
                'TBC034 (BGSS-196m)',
                'Shallow VWP',
                272956,
                6205076,
                405,
                405,
                235.39999999999998,
                null,
                null
              ],
              [
                'TBC034 - SWL6',
                'TBC034 (BGSS-245m)',
                'Deep VWP',
                272956,
                6205076,
                405,
                405,
                186.2,
                null,
                null
              ],
              [
                'TBC034 - SWL7',
                'TBC034 (BGSS-294m)',
                'Deep VWP',
                272956,
                6205076,
                405,
                405,
                137.09999999999997,
                null,
                null
              ],
              [
                'TBC034 - SWL8',
                'TBC034 (BGSS-343m)',
                'Deep VWP',
                272956,
                6205076,
                405,
                405,
                87.89999999999998,
                null,
                null
              ],
              [
                'TBC034 - SWL9',
                'TBC034 (BUCO-365m)',
                'Deep VWP',
                272956,
                6205076,
                405,
                405,
                66.5,
                null,
                null
              ],
              [
                'TBC034 - SWL10',
                'TBC034 (WWCO-382m)',
                'Deep VWP',
                272956,
                6205076,
                405,
                405,
                49.39999999999998,
                null,
                null
              ],
              [
                'TBC039 - VWP1',
                'TBC039 (HBSS-65m)',
                'Shallow VWP',
                273445,
                6207688,
                379.04,
                379.04,
                314.04,
                null,
                'Inactive'
              ],
              [
                'TBC039 - VWP2',
                'TBC039 (HBSS-106m)',
                'Shallow VWP',
                273445,
                6207688,
                379.04,
                379.04,
                272.74,
                null,
                null
              ],
              [
                'TBC039 - VWP3',
                'TBC039 (HBSS-147m)',
                'Shallow VWP',
                273445,
                6207688,
                379.04,
                379.04,
                231.34000000000003,
                null,
                null
              ],
              [
                'TBC039 - VWP4',
                'TBC039 (HBSS-172.5m)',
                'Shallow VWP',
                273445,
                6207688,
                379.04,
                379.04,
                206.54000000000002,
                null,
                null
              ],
              [
                'TBC039 - VWP5',
                'TBC039 (BGSS-188.6m)',
                'Shallow VWP',
                273445,
                6207688,
                379.04,
                379.04,
                190.44000000000003,
                null,
                'Malfunctioning in April 2023'
              ],
              [
                'TBC039 - VWP6',
                'TBC039 (BGSS-243m)',
                'Deep VWP',
                273445,
                6207688,
                379.04,
                379.04,
                135.24,
                null,
                null
              ],
              [
                'TBC039 - VWP7',
                'TBC039 (BGSS-299m)',
                'Deep VWP',
                273445,
                6207688,
                379.04,
                379.04,
                80.04000000000002,
                null,
                null
              ],
              [
                'TBC039 - VWP8',
                'TBC039 (SBSS-354m)',
                'Deep VWP',
                273445,
                6207688,
                379.04,
                379.04,
                24.74000000000001,
                null,
                'Malfuncitoning at start - seems to have stabilised'
              ],
              [
                'TBC039 - VWP9',
                'TBC039 (BUSM-375m)',
                'Deep VWP',
                273445,
                6207688,
                379.04,
                379.04,
                3.9399999999999977,
                null,
                null
              ],
              [
                'TBC039 - VWP10',
                'TBC039 (WWCO-402m)',
                'Deep VWP',
                273445,
                6207688,
                379.04,
                379.04,
                -23.859999999999957,
                null,
                null
              ],
              [
                'TCS01 - SWL1',
                'TCS-01 (HBSS-50m)',
                'Shallow VWP',
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'TCS01 - SWL2',
                'TCS-01 (HBSS-90m)',
                'Shallow VWP',
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'TCS01 - SWL3',
                'TCS-01 (HBSS-150m)',
                'Shallow VWP',
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'TCS01 - SWL4',
                'TCS-01 (BHCS-190m)',
                'Shallow VWP',
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'TCS01 - SWL5',
                'TCS-01 (BGSS-245m)',
                'Deep VWP',
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'TCS01 - SWL6',
                'TCS-01 (SBSS-370m)',
                'Deep VWP',
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'TCS01 - SWL7',
                'TCS-01 (WBCS-395m)',
                'Deep VWP',
                null,
                null,
                null,
                null,
                null,
                null,
                'Malfunctions'
              ],
              [
                'TCS01 - SWL8',
                'TCS-01 (BUCO-405m)',
                'Deep VWP',
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                'TBC001 - SWL1',
                'TBC001 (398m)',
                'Deep VWP',
                276749,
                6206665,
                305.34,
                305.34,
                -92.16000000000003,
                null,
                null
              ],
              [
                'TBC001 - SWL2',
                'TBC001 (429m)',
                'Deep VWP',
                276749,
                6206665,
                305.34,
                305.34,
                -123.66000000000003,
                null,
                null
              ],
              [
                'TBC033 - SWL1',
                'TBC033 (HBSS-65m)',
                'Shallow VWP',
                275194,
                6205395,
                344.9,
                344.9,
                279.9,
                null,
                null
              ],
              [
                'TBC033 - SWL2',
                'TBC033 (HBSS-113m)',
                'Shallow VWP',
                275194,
                6205395,
                344.9,
                344.9,
                231.89999999999998,
                null,
                null
              ],
              [
                'TBC033 - SWL3',
                'TBC033 (HBSS-161m)',
                'Shallow VWP',
                275194,
                6205395,
                344.9,
                344.9,
                183.89999999999998,
                null,
                null
              ],
              [
                'TBC033 - SWL4',
                'TBC033 (BHCS-173m)',
                'Shallow VWP',
                275194,
                6205395,
                344.9,
                344.9,
                171.89999999999998,
                null,
                null
              ],
              [
                'TBC033 - SWL5',
                'TBC033 (BGSS-190m)',
                'Shallow VWP',
                275194,
                6205395,
                344.9,
                344.9,
                154.89999999999998,
                null,
                null
              ],
              [
                'TBC033 - SWL6',
                'TBC033 (BGSS-247m)',
                'Deep VWP',
                275194,
                6205395,
                344.9,
                344.9,
                97.19999999999999,
                null,
                null
              ],
              [
                'TBC033 - SWL7',
                'TBC033 (BGSS-305m)',
                'Deep VWP',
                275194,
                6205395,
                344.9,
                344.9,
                38.89999999999998,
                null,
                null
              ],
              [
                'TBC033 - SWL8',
                'TBC033 (BGSS-363m)',
                'Deep VWP',
                275194,
                6205395,
                344.9,
                344.9,
                -18.30000000000001,
                null,
                null
              ],
              [
                'TBC033 - SWL9',
                'TBC033 (BUCO-384m)',
                'Deep VWP',
                275194,
                6205395,
                344.9,
                344.9,
                -39.400000000000034,
                null,
                null
              ],
              [
                'TBC033 - SWL10',
                'TBC033 (WWCO-408m)',
                'Deep VWP',
                275194,
                6205395,
                344.9,
                344.9,
                -63.80000000000001,
                null,
                null
              ],
              [
                null,
                'TBC032 (BGSS-257m)',
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                'Drawdown only, not logger'
              ],
              [
                null,
                'TBC032 (BGSS-314m)',
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                'Drawdown only, not logger'
              ],
              [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ],
              [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ]
            ]
          }
        ]
      }
    }
    