import React, { FC } from "react"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"

const ErrorBoundaryPage: FC = () => {

    const handleClickSignOut = () => {
        const win: Window = window;
        win.location = "/";
    }

    return(
        <div style={{width: "100%", height: "100vh", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "24px"}}>
            <Typography variant="f2b">Something went wrong!</Typography>
            <Typography variant="f8">Please sign out and sign in again</Typography>
            <Button onClick={handleClickSignOut} variant="contained" size="large">Refresh</Button>
        </div>
    )
}

export default ErrorBoundaryPage